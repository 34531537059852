import React, { useState } from "react";
import { stylesLayout } from "../../css";
import { Outlet, Navigate } from "react-router";
import { Container } from "react-bootstrap";
import { Footer, AuthNavbar } from "../../components";
import { decryptData } from "../encryption/cryption";
const AuthLayout = () => {
  const decrypt = decryptData();
  return !decrypt ? (
    <Container fluid className={stylesLayout.container}>
      <AuthNavbar />
      <Outlet />
      <Footer />
    </Container>
  ) : (
    <Navigate to="/events" />
  );
};

export default AuthLayout;
