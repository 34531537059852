import React from "react";
import { stylesAuth } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const UpdateEmail = () => {
  const navigation = useNavigate();
  const validationSchema = Yup.object().shape({
    currentEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    newEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    currentEmail: "",
    newEmail: "",
  };

  const handleSubmit = async (values) => {
    console.log(values);
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={6} md={8} sm={12}>
        <Card className={stylesAuth.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesAuth.heading}>Update Email</Card.Title>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <FormikForm className="w-100">
                <div className={stylesAuth.inputSec}>
                  <label htmlFor="currentEmail" className={stylesAuth.inputTitle}>
                    Current Email
                  </label>
                  <Field
                    type="text"
                    id="currentEmail"
                    name="currentEmail"
                    className={stylesAuth.input}
                  />
                  <ErrorMessage
                    name="currentEmail"
                    component="div"
                    className={stylesAuth.errorMessage}
                  />
                </div>
                <div className={stylesAuth.inputSec}>
                  <label htmlFor="newEmail" className={stylesAuth.inputTitle}>
                    New Email
                  </label>
                  <Field
                    type="text"
                    id="newEmail"
                    name="newEmail"
                    className={stylesAuth.input}
                  />
                  <ErrorMessage
                    name="newEmail"
                    component="div"
                    className={stylesAuth.errorMessage}
                  />
                </div>
                <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-between my-4 p-0 mx-0">
                  <Card.Text className={stylesAuth.title}>
                    An email with a verification code will send to your new email.
                  </Card.Text>
                </Card.Body>
                <div>
                  <CustomButton
                    type="submit"
                    title="Confirm"
                    showIcon={false}
                  />
                </div>
              </FormikForm>
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default UpdateEmail;