import React, { useState } from "react";
import { stylesGenearteCoupon } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { CustomButton, TextInput, CheckBox } from "../../components";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { decryptData } from "../../layouts/encryption/cryption";
import { URL } from "../../utils/ApisLink";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";

const GenerateCoupon = () => {
  const [type, setType] = useState();
  const decrypt = decryptData();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .transform((value) => value.toLowerCase())
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values) => {
    const email = values.email;
    try {
      const response = await fetch(URL + "/generate-coupon", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify({ email, type }),
      });
      if (response.ok) {
        showSuccessToast({ message: "Coupon has been sent to user" });
      } else {
        const errorData = await response.json();
        showErrorToast({
          message: errorData.message,
        });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesGenearteCoupon.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesGenearteCoupon.heading}>
              Generate Coupon
            </Card.Title>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <FormikForm className="w-100">
                <TextInput target="email" type="text" label="Email" placeholder="Email" />
                <div className={stylesGenearteCoupon.ckeckboxSec}>
                  <label className={stylesGenearteCoupon.ckeckboxTitle}>
                    Discount Type
                  </label>
                  <div className={stylesGenearteCoupon.ckeckboxRow}>
                    <CheckBox
                      label="20% Discount (1 year)"
                      isChecked={type === 1 ? true : false}
                      onPress={() => setType(1)}
                    />
                    <CheckBox
                      label="Free Sundscription (3 months)"
                      isChecked={type === 0 ? true : false}
                      onPress={() => setType(0)}
                    />
                  </div>
                </div>
                <div>
                  <CustomButton
                    type="submit"
                    title="Send Coupon"
                    styles={stylesGenearteCoupon.btn}                    
                  />
                </div>
              </FormikForm>
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default GenerateCoupon;
