import React, { useEffect, useState } from "react";
import { stylesTicket } from "../../css";
import { Container, Spinner } from "react-bootstrap";
import { CustomButton } from "..";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { convertDate, convertTime } from "../../layouts/encryption/cryption";

const Ticket = ({
  showTicket,
  title,
  address,
  state,
  city,
  guests,
  startingDate,
  startingTime,
  endingDate,
  endingTime,
  ticketFee,
  onReserve,
  reserve,
  completed,
  membership,
  ticketQuantity,
  subQuantity,
  addQuantity,
}) => {
  const [formatedST, setFormatedST] = useState();
  const [formatedET, setFormatedET] = useState();
  const [formatedSD, setFormatedSD] = useState();
  const [formatedED, setFormatedED] = useState();

  useEffect(() => {
    if (showTicket === "reserved") {
      const FST = convertTime(startingTime);
      setFormatedST(FST);
      const FET = convertTime(endingTime);
      setFormatedET(FET);
      const FSD = convertDate(startingDate);
      setFormatedSD(FSD);
      const FED = convertDate(endingDate);
      setFormatedED(FED);
    }
  }, []);

  return (
    <>
      {showTicket === "reserved" ? (
        <Container fluid className={stylesTicket.container}>
          <Container fluid className={stylesTicket.hiddenSec}>
            <h2 className={stylesTicket.rowText}>{title}</h2>
            <h2
              className={stylesTicket.addressText}
            >{`${address}${" "}${city}${" "}${state}`}</h2>
            <div className={stylesTicket.deviderSec}>
              <div className={stylesTicket.dividerLine}></div>
              <h2 className={stylesTicket.rowText}>Check In</h2>
              <div className={stylesTicket.dividerLine}></div>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Date:</h2>
              <h2 className={stylesTicket.rowText}>{formatedSD}</h2>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Time:</h2>
              <h2 className={stylesTicket.rowText}>{formatedST}</h2>
            </div>
            <div className={stylesTicket.deviderSec}>
              <div className={stylesTicket.dividerLine}></div>
              <h2 className={stylesTicket.rowText}>Check Out</h2>
              <div className={stylesTicket.dividerLine}></div>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Date:</h2>
              <h2 className={stylesTicket.rowText}>{formatedED}</h2>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Time:</h2>
              <h2 className={stylesTicket.rowText}>{formatedET}</h2>
            </div>
            <CustomButton
              title={completed ? "Completed" : "QR Code"}
              onPress={onReserve}
              disabled={completed}
              styles={stylesTicket.btn}
            />
          </Container>
        </Container>
      ) : (
        <Container fluid className={stylesTicket.container}>
          <Container fluid className={stylesTicket.hiddenSec}>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Total Guests</h2>
              <h2 className={stylesTicket.rowText}>{guests}</h2>
            </div>
            <div className={stylesTicket.deviderSec}>
              <div className={stylesTicket.dividerLine}></div>
              <h2 className={stylesTicket.rowText}>Check In</h2>
              <div className={stylesTicket.dividerLine}></div>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Date:</h2>
              <h2 className={stylesTicket.rowText}>{startingDate}</h2>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Time:</h2>
              <h2 className={stylesTicket.rowText}>{startingTime}</h2>
            </div>
            <div className={stylesTicket.deviderSec}>
              <div className={stylesTicket.dividerLine}></div>
              <h2 className={stylesTicket.rowText}>Check Out</h2>
              <div className={stylesTicket.dividerLine}></div>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Date:</h2>
              <h2 className={stylesTicket.rowText}>{endingDate}</h2>
            </div>
            <div className={stylesTicket.row}>
              <h2 className={stylesTicket.rowText}>Time:</h2>
              <h2 className={stylesTicket.rowText}>{endingTime}</h2>
            </div>
            {/* <div className={stylesTicket.counter}>
              <div className={stylesTicket.counterBtn} onClick={subQuantity}>
                <RemoveRounded
                  sx={{ fontSize: 28 }}
                  className={stylesTicket.counterIcon}
                />
              </div>
              <div className={stylesTicket.counterInput}>{ticketQuantity}</div>
              <div className={stylesTicket.counterBtn} onClick={addQuantity}>
                <AddRounded
                  sx={{ fontSize: 28 }}
                  className={stylesTicket.counterIcon}
                />
              </div>
            </div> */}
            <CustomButton
              title={
                reserve ? (
                  <Spinner animation="border" variant="dark" size="sm" />
                ) : (
                  "Reserve"
                )
              }
              onPress={onReserve}
              styles={stylesTicket.btn}
            />
            <Container fluid className={stylesTicket.paymentSec}>
              <div className={stylesTicket.row}>
                <h2 className={stylesTicket.rowText}>Event fee</h2>
                <h2 className={stylesTicket.rowText}>{ticketFee}$</h2>
              </div>
              {ticketFee === 0 ? (
                <div className={stylesTicket.row}>
                  <h2 className={stylesTicket.rowText}>Proyourev Fee</h2>
                  <h2 className={stylesTicket.rowText}>0</h2>
                </div>
              ) : (
                <div className={stylesTicket.row}>
                  <h2 className={stylesTicket.rowText}>Proyourev Fee</h2>
                  <h2 className={stylesTicket.rowText}>
                    {!membership ? "13%" : "5%"}
                  </h2>
                </div>
              )}
            </Container>
            {ticketFee === 0 ? (
              <div className={stylesTicket.billRow}>
                <h2 className={stylesTicket.billText}>Total Fee</h2>
                <h2 className={stylesTicket.billText}>
                  0$
                </h2>
              </div>
            ) : (
              <div className={stylesTicket.billRow}>
                <h2 className={stylesTicket.billText}>Total Fee</h2>
                <h2 className={stylesTicket.billText}>
                  {!membership
                    ? (ticketFee += ticketFee * 0.13)
                    : (ticketFee += ticketFee * 0.05)}
                  $
                </h2>
              </div>
            )}
          </Container>
        </Container>
      )}
    </>
  );
};

export default Ticket;
