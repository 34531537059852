import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { stylesFooter } from "../../css";
// Icons
import {
  FacebookRounded,
  Instagram,
  Twitter,
  KeyboardArrowUpRounded,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";

const Footer = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {show === true ? (
        <div className={stylesFooter.container} onClick={() => setShow(false)}>
          <KeyboardArrowDownRounded
              className={stylesFooter.arrow}
              onClick={() => setShow(false)}
            />
          <Row className={stylesFooter.row}>
            <Col lg={3} md={6} sm={12} className={stylesFooter.titleCol}>
              <h2 className={stylesFooter.mainTitle}>People Event.</h2>
            </Col>
            <Col lg={3} md={6} sm={12} className={stylesFooter.col}>
              <ul className={stylesFooter.list}>
                <li className={stylesFooter.item}>
                  <h2 className={stylesFooter.title}>Quick View</h2>
                </li>
                <li className={stylesFooter.item}>
                  <a href="/events" className={stylesFooter.link}>
                    Explore Events
                  </a>
                </li>
                <li className={stylesFooter.item}>
                  <a href="/host-event" className={stylesFooter.link}>
                    Host Events
                  </a>
                </li>
                <li className={stylesFooter.item}>
                  <a href="/my-tickets" className={stylesFooter.link}>
                    Reserved Tickets
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} sm={12} className={stylesFooter.col}>
              <ul className={stylesFooter.list}>
                <li className={stylesFooter.item}>
                  <h2 className={stylesFooter.title}>Help Center</h2>
                </li>
                <li className={stylesFooter.item}>
                  <a href="/contact-us" className={stylesFooter.link}>
                    Contact Us
                  </a>
                </li>
                <li className={stylesFooter.item}>
                  <a href="/terms-condition" className={stylesFooter.link}>
                    Terms & Conditions
                  </a>
                </li>
                <li className={stylesFooter.item}>
                  <a href="/privacy-policy" className={stylesFooter.link}>
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} sm={12} className={stylesFooter.col}>
              <ul className={stylesFooter.list}>
                <li className={stylesFooter.item}>
                  <h2 className={stylesFooter.title}>Social</h2>
                </li>
                <li className={stylesFooter.item}>
                  <FacebookRounded className={stylesFooter.icon} />
                  <a href="/events" className={stylesFooter.link}>
                    Facebook
                  </a>
                </li>
                <li className={stylesFooter.item}>
                  <Twitter className={stylesFooter.icon} />
                  <a href="/events" className={stylesFooter.link}>
                    Twitter
                  </a>
                </li>
                <li className={stylesFooter.item}>
                  <Instagram className={stylesFooter.icon} />
                  <a href="/events" className={stylesFooter.link}>
                    Instagram
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      ) : (
        <div className={stylesFooter.footerBottom}>
          <h2 className={stylesFooter.brandName}>
            &copy; {new Date().getFullYear()} Copyrights PeopleEvents.
          </h2>
          <KeyboardArrowUpRounded
            className={stylesFooter.arrow}
            onClick={() => setShow(true)}
          />
        </div>
      )}
    </>
  );
};

export default Footer;
