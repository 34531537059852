import React, { useEffect, useState, useRef } from "react";
import { stylesManageCategory } from "../../css";
import { Container, Col, Row, Button, Image, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FileUploadRounded, DeleteForeverRounded } from "@mui/icons-material";
import { CategoryItem, CustomButton, TextInput } from "../../components";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { URL as newURL } from "../../utils/ApisLink";
import { decryptData } from "../../layouts/encryption/cryption";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";

const AddCategory = () => {
  const [image, setImage] = useState([]);
  const [showImg, setShowImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const decrypt = decryptData();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter the category name."),
  });

  const initialValues = {
    name: "",
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    try {
      const response = await fetch(newURL + "/admin/categories", {
        method: "POST",
      });
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setCategories(data.data);
          console.log(data.data);
          setLoading(false);
        }
      } else {
        console.error("Error fetching categories:", response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(image);

  const handelInputClick = () => {
    inputRef.current.click();
  };

  const handelInputChange = (e) => {
    debugger;
    const file = e.target.files[0];
    setImage(file);
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowImg(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const SubmitCategory = async (value) => {
    try {
      debugger;
      setLoading(true);
      const formData = new FormData();
      formData.append("originalname", value.name);
      formData.append("files", image);
      const fileUploadResponse = await fetch(newURL + "/admin/upload-file", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: formData,
      });

      if (!fileUploadResponse.ok) {
        showErrorToast({ message: fileUploadResponse.message });
        return;
      }

      // Handle the response based on your server's structure
      const responseJson = await fileUploadResponse.json();

      if (responseJson.success) {
        // Successfully uploaded the image, get the file URL
        const fileUrl = responseJson.data.fileUrls[0];
        // Then, send the category data along with the image URL to the /admin/add-category endpoint
        const categoryData = {
          name: value.name,
          image: fileUrl, // Use the URL obtained from the file upload
          category_id: null,
        };

        const addCategoryResponse = await fetch(
          newURL + "/admin/add-category",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${decrypt.token}`,
            },
            body: JSON.stringify(categoryData),
          }
        );

        if (addCategoryResponse.ok) {
          fetchCategories();
          setShowImg(null);
          setLoading(false);
          showSuccessToast({ message: "Category added successfully!" });
        } else {
          showErrorToast({ message: addCategoryResponse.message });
        }
      }
    } catch (err) {
      // Error handling
      showErrorToast({ message: "Error:" + err });
    }
  };

  //delete category
  const handleDelete = async (categoryId, categoryName) => {
    const shouldDelete = window.confirm(
      "Are you sure to delete " + categoryName + " category"
    );
    if (!shouldDelete) {
      return; // If the user cancels the confirmation, do nothing
    }
    try {
      debugger;
      setLoading(true);
      const response = await fetch(newURL + `/admin/delete/category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify({ category_id: categoryId }),
      });

      if (response.ok) {
        // If the category is deleted successfully, fetch the updated list of categories
        fetchCategories();
        setLoading(false);
        showSuccessToast({ message: "Category deleted successfully!" });
      } else {
        showErrorToast({ message: response.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error:" + err });
    }
  };

  return (
    <Container fluid className={stylesManageCategory.container}>
      <div className={stylesManageCategory.header}>
        <h2 className={stylesManageCategory.title}>Manage Categories</h2>
        <CustomButton
          title={"Go Back"}
          onPress={() => navigation("/admin/dashboard")}
        />
      </div>
      <Row className={stylesManageCategory.catRow}>
        <Col lg={5} md={12} sm={12} className={stylesManageCategory.catCol}>
          <div className={stylesManageCategory.form}>
            <div
              className={stylesManageCategory.imgSec}
              onClick={handelInputClick}
            >
              {showImg === null ? (
                <>
                  <FileUploadRounded sx={{ fontSize: 38 }} />
                  <span className={stylesManageCategory.imageSecTitle}>
                    Tap to upload picture.
                  </span>
                </>
              ) : (
                // <AddAPhotoRounded sx={{ fontSize: 32 }} />
                <Image
                  src={showImg}
                  alt={`selected-img`}
                  className={stylesManageCategory.img}
                />
              )}
            </div>
            <input
              type="file"
              onChange={handelInputChange}
              ref={inputRef}
              style={{ display: "none" }}
              multiple
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={SubmitCategory}
              className="w-100"
            >
              <FormikForm className="w-100">
                <TextInput
                  target="name"
                  type="text"
                  label="Category Name"
                  placeholder="Name"
                />
                <CustomButton
                  type="submit"
                  title="Add Category"
                  styles={stylesManageCategory.catBtn}
                />
              </FormikForm>
            </Formik>
          </div>
        </Col>
        <Col lg={7} md={12} sm={12} className={stylesManageCategory.catCol}>
          {loading ? (
            <Spinner
              animation="border"
              variant="dark"
              role="status"
              className={stylesManageCategory.margin}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <div className={stylesManageCategory.catSec}>
              {categories.map((categories) => (
                <div className={stylesManageCategory.catCnt}>
                  <CategoryItem
                    key={categories._id}
                    Title={categories.name}
                    ImagePath={categories.image}
                    CheckId={null}
                  />
                  <CustomButton
                    title="Delete"
                    styles={stylesManageCategory.deleteBtn}
                    onPress={() =>
                      handleDelete(categories._id, categories.name)
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AddCategory;
