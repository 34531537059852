import React, { useEffect } from "react";
import MainRouter from "./router/mainRouter";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const clientId =
    "244105009701-poeq65r2ruej7hg8pnfjprsca2cqtrnp.apps.googleusercontent.com";
  // Your handleLogout function
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  // Your resetLogoutTimer function
  let logoutTimer;
  const resetLogoutTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      handleLogout();
    }, 900000); // 15 minutes in milliseconds
  };

  // Your handleUserActivity function
  const handleUserActivity = () => {
    resetLogoutTimer();
  };

  useEffect(() => {
    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    // Initial setup or when the component unmounts
    resetLogoutTimer();

    // Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <ToastContainer />
        <Router>
          <MainRouter />
        </Router>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
