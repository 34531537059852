import React, { useState } from "react";
import { stylesAuthNavbar } from "../../css";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  CloseRounded,
  HomeRounded,
  SortRounded,
  Groups3Rounded,
  AttachEmailRounded,
  GppMaybeRounded,
  ArticleRounded,
  PersonRounded,
  PersonAddRounded,
} from "@mui/icons-material";
import Logo from "../../assets/images/logo.png";

const AuthNavbar = () => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const handleHide = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={stylesAuthNavbar.navbar}
        >
          <Container fluid>
            <Image
              src={Logo}
              className={stylesAuthNavbar.brandImage}
              onClick={() => navigation("/home")}
            />
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className={stylesAuthNavbar.toggleButton}
              onClick={handleShow}
            >
              <SortRounded
                className={stylesAuthNavbar.toggleIcon}
                sx={{ fontSize: 28 }}
              />
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              scroll={true}
              backdrop={true}
              show={show}
              onHide={handleHide}
              className={stylesAuthNavbar.navbarMenu}
            >
              <Offcanvas.Header className={stylesAuthNavbar.menuHead}>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className={stylesAuthNavbar.userName}
                >
                  People Event.
                </Offcanvas.Title>
                <CloseRounded
                  sx={{ fontSize: 32 }}
                  className={stylesAuthNavbar.closeBtn}
                  onClick={handleHide}
                />
              </Offcanvas.Header>
              <Offcanvas.Body
                className={`w-100 ${stylesAuthNavbar.navScrollbar}`}
              >
                <Nav className="justify-content-center flex-grow-1 w-100">
                  <Nav.Link href="/home" className={stylesAuthNavbar.nav}>
                    Home
                    <HomeRounded className={stylesAuthNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/about" className={stylesAuthNavbar.nav}>
                    About Us
                    <Groups3Rounded className={stylesAuthNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/contact" className={stylesAuthNavbar.nav}>
                    Contact Us
                    <AttachEmailRounded className={stylesAuthNavbar.navIcon} />
                  </Nav.Link>
                  <div className={stylesAuthNavbar.divider}></div>
                  <Nav.Link href="/terms" className={stylesAuthNavbar.nav}>
                    Terms of Services
                    <ArticleRounded className={stylesAuthNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/policies" className={stylesAuthNavbar.nav}>
                    Privacy Policy
                    <GppMaybeRounded className={stylesAuthNavbar.navIcon} />
                  </Nav.Link>
                  <div className={stylesAuthNavbar.divider}></div>
                  <Nav.Link href="/login" className={stylesAuthNavbar.nav}>
                    Login
                    <PersonRounded className={stylesAuthNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/signup" className={stylesAuthNavbar.nav}>
                    Signup
                    <PersonAddRounded className={`${stylesAuthNavbar.navIcon} me-1`} />
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default AuthNavbar;
