import React from "react";
import { stylesAuth } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton, TextInput } from "../../components";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";

const EmailVerify = () => {
  const navigation = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    code: Yup.number()
      .integer("Code only contain integers")
      .required("Varification code is required"),
  });

  const initialValues = {
    email: "",
    code: "",
  };

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(URL + "/verify-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...values, email: values.email.toLowerCase() }),
      });
      if (response.ok) {
        showSuccessToast({ message: "Account has been verified" });
        navigation("/login");
      } else {
        const errorData = await response.json();
        showErrorToast({
          message: errorData.message,
        });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesAuth.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesAuth.heading}>OTP Code</Card.Title>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <FormikForm className="w-100">
                <TextInput target="email" type="text" label="Email" placeholder="Email" />
                <TextInput
                  target="code"
                  type="number"
                  label="Verification Code"
                  placeholder="Verification Code"
                />
                <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-between my-4 p-0 mx-0">
                  <Card.Text className={stylesAuth.title}>
                    An email have been already sent to your email for
                    verification.
                  </Card.Text>
                </Card.Body>
                <div className={stylesAuth.btnCnt}>
                  <CustomButton
                    type="submit"
                    title="Confirm"
                    styles={stylesAuth.authBtn}
                  />
                </div>
              </FormikForm>
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default EmailVerify;
