import React, { useEffect, useState } from "react";
import { stylesPremium } from "../../css";
import { WorkspacePremiumRounded } from "@mui/icons-material";
import { decryptData } from "../../layouts/encryption/cryption";

const Premium = ({ onPress }) => {
  const [plan, setPlan] = useState();
  const decrypt = decryptData();

  useEffect(() => {
    if (decrypt.subscription) {
      setPlan(true);
    } else {
      setPlan(false);
    }
  }, []);

  return (
    <div
      className={!plan ? stylesPremium.card : stylesPremium.activeCard}
      onClick={!plan ? onPress : null}
    >
      <div className={stylesPremium.content}>
        <div className={stylesPremium.headingCnt}>
          <p
            className={
              !plan ? stylesPremium.heading : stylesPremium.activeHeading
            }
          >
            {!plan ? "Get Subscription" : "Subscribed"}
          </p>
          <WorkspacePremiumRounded
            className={!plan ? stylesPremium.icon : stylesPremium.activeIcon}
          />
        </div>
        <p
          className={
            decrypt.subscription == null
              ? stylesPremium.para
              : stylesPremium.activePara
          }
        >
          {decrypt.subscription == null
            ? "Subscribe to our plans and get chance to sell tickets, avail discounts, and much more."
            : "Thank you to subscribe our plan. Hope you will enjoy our serices and give us some feedback."}
        </p>
      </div>
    </div>
  );
};

export default Premium;
