import React, { useEffect, useState } from "react";
import { stylesPremium } from "../../css";
import { CardGiftcardRounded } from "@mui/icons-material";
import { decryptData } from "../../layouts/encryption/cryption";

const PremiumCoupon = () => {
  const [coupon, setCoupon] = useState();
  const userData = decryptData().user;

  useEffect(() => {
    if (userData.has_coupon === true && userData.coupon_type === true) {
      setCoupon("discount");
    } else if (userData.has_coupon === true && userData.coupon_type == false) {
      setCoupon("free");
    } else {
      setCoupon("none");
    }
  }, []);

  return (
    <div className={stylesPremium.activeCard}>
      <div className={stylesPremium.content}>
        <div className={stylesPremium.headingCnt}>
          <p className={stylesPremium.activeHeading}>
            {coupon === "discount"
              ? "Congratulations you avail a 20% discount coupon for 1 year."
              : "Congratulations you avail a free membership coupon for 3 months."}
          </p>
          <CardGiftcardRounded className={stylesPremium.activeIcon} />
        </div>
      </div>
    </div>
  );
};

export default PremiumCoupon;
