import React, { useState } from "react";
import { stylesCategory } from "../../css";
import { Button } from "react-bootstrap";

const Category = ({ id, ImagePath, Title, OnPress, CheckId }) => {
  return (
    <Button
      key={id}
      variant="dark"
      className={CheckId === id ? stylesCategory.activeButton : stylesCategory.button}
      onClick={OnPress}
    >
      <img src={ImagePath} className={stylesCategory.buttonIcon} alt="" />
      <h2 className={stylesCategory.title}>{Title}</h2>
    </Button>
  );
};

export default Category;
