import CryptoJS from "crypto-js";
import { MONTHS_DATA } from "../../utils/data";

// Function to decrypt data
export function decryptData() {
  try {
    const ciphertext = sessionStorage.getItem("usrData");
    if (!ciphertext) {
      console.error("Decryption Error: No encrypted data found");
      return false;
    } else {
      const bytes = CryptoJS.AES.decrypt(ciphertext, "22f3b23");
      if (!bytes) {
        // Handle the case where decryption fails
        console.error("Decryption Error: Decryption failed");
        return false;
      } else {
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (!decryptedData || typeof decryptedData !== "object") {
          // Handle the case where decrypted data is not valid
          console.error("Decryption Error: Decrypted data is not valid JSON");
          return false;
        } else {
          return decryptedData;
        }
      }
    }
  } catch (error) {
    console.error("Decryption Error:", error);
    return null; // Return null on decryption error
  }
}

const Key = `${process.env.REACT_ENC_DEC_KEY}`;

const encryptId = (id) => {
  const encJson = CryptoJS.AES.encrypt(JSON.stringify(id), Key).toString();
  const encData = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encJson)
  );
  return encData;
};

const decryptId = (id) => {
  const decData = CryptoJS.enc.Base64.parse(id).toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, Key).toString(CryptoJS.enc.Utf8);
  return JSON.parse(bytes);
};

const convertTime = (time) => {
  const [hours, minutes] = time.split(":");
  const formattedTime = new Date(0, 0, 0, hours, minutes).toLocaleTimeString(
    [],
    {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
  );
  return formattedTime;
};

const convertDate = (value) => {
  const date = new Date(value);
  const day = date.getDate().toString().padStart(2, "0");
  const month = MONTHS_DATA[date.getMonth()];
  const year = date.getFullYear();

  const formatedDate = `${day} ${month} ${year}`;
  return formatedDate;
};

export { encryptId, decryptId, convertTime, convertDate };
