import React from "react";
import { stylesContact } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { CustomButton, TextInput, MessageInput } from "../../components";
import { showErrorToast, showSuccessToast } from "../../components/tost/tost";
import { decryptData } from "../../layouts/encryption/cryption";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { URL } from "../../utils/ApisLink";

const ContactUs = () => {
  const decrypt = decryptData();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    name: Yup.string()
      .min(3, "Full name is required")
      .required("Full name is required"),
    message: Yup.string()
      .max(499, "You can only write 500 words")
      .required("Please enter you query"),
  });

  const initialValues = {
    email: decrypt ? decrypt.user.email : "",
    name: "",
    message: "",
  };

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(URL + "/contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify({ ...values, email: values.email.toLowerCase() }),
      });
      if (response.ok) {
        showSuccessToast({ message: "Email has been sent!" });
      } else {
        const errorData = await response.json();
        showErrorToast({
          message: errorData.message,
        });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesContact.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesContact.heading}>
              Contact Us
            </Card.Title>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <FormikForm className="w-100">
                <TextInput target="name" type="text" label="Full Name" placeholder="Full Name" />
                <TextInput target="email" type="text" label="Email" placeholder="Email" />
                <MessageInput
                  target="message"
                  type="textarea"
                  label="Message"
                  placeholder="Message"
                />
                <div>
                  <CustomButton
                    type="submit"
                    title="Send Query"
                    styles={stylesContact.margin}
                  />
                </div>
              </FormikForm>
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ContactUs;
