import React, { useState, useEffect } from "react";
import { stylesMyEvents } from "../../css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EventItem, CustomButton } from "../../components";
import { showErrorToast } from "../../components/tost/tost";
import { decryptData, encryptId } from "../../layouts/encryption/cryption";
import { URL } from "../../utils/ApisLink";
import NotFoundImg from "../../assets/images/folder.png";

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const decrypt = decryptData();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      await fetch(URL + "/get-events",{
        method: "GET",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        }
      }).then((result) => {
        result.json().then((resp) => {
          const data = resp.data;
          const filteredEvents = data.filter(
            (item) => item.user_id._id === decrypt.user._id
          );
          if (filteredEvents.length > 0) {
            setEvents(filteredEvents);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      });
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleSubmit = (value) => {
    const encryptedId = encryptId(value);
    if (encryptedId) {
      sessionStorage.setItem("encryptedId", encryptedId);
      navigation("/event-details");
    }
  };

  return (
    <Container fluid className={stylesMyEvents.container}>
      {!loading ? (
        <Row className={stylesMyEvents.eventSec}>
          {events.length === 0 ? (
            <div className={stylesMyEvents.oopsCnt}>
              <img className={stylesMyEvents.oopsImg} src={NotFoundImg} alt="img-not-found" />
              <h2 className={stylesMyEvents.oopsText}>No Hosted Events</h2>
              <CustomButton
                title="Host Event"
                onPress={() => navigation("/host-event")}
              />
            </div>
          ) : (
            events.map((item) => (
              <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                <EventItem
                  id={item?._id}
                  media={item?.image}
                  city={item?.city}
                  state={item?.state}
                  location={item?.address}
                  startingDate={item?.start_date}
                  startingTime={item?.start_time}
                  isFree={item?.is_membership}
                  price={item?.ticket_price}
                  onPress={() => handleSubmit(item?._id)}
                />
              </Col>
            ))
          )}
        </Row>
      ) : (
        <Spinner animation="border" variant="dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </Container>
  );
};

export default MyEvents;
