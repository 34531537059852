import React from "react";
import { stylesTermsConditions } from "../../css";
import { Row, Col } from "react-bootstrap";
import { URL } from "../../utils/ApisLink"; 

const TermsConditionsPage = () => {
  return (
    <Row className="d-flex align-items-center justify-content-center w-100 mb-5 px-3">
      <Col
        lg={8}
        md={10}
        sm={12}
        className="d-flex flex-column align-items-start justify-content-center"
      >
        <div className={stylesTermsConditions.header}>
          <div className={stylesTermsConditions.headerImage}>
          <h2 className={stylesTermsConditions.heading}>Terms of Services</h2>
          </div>
        </div>
        <p className={stylesTermsConditions.paragraph}>
          These Terms and Conditions of Use (the "Agreement" or "Terms") contain
          the terms and conditions under which you may make use of the ProYourEv
          Site at{" "}
          <a
            href={URL}
            className={stylesTermsConditions.link}
          >
            www.proyourev.com
          </a>{" "}
          and /or the services that we make available to you.
          These terms constitute a legal agreement between you ("you" or "user")
          and The People Events (incorporated in the State of New York and
          operates the ProYourEv Site, hereinafter referred to as "ProYourEv,"
          "we" or "us"), and, they govern your access and use of the content,
          products, and services (the "Services"), made available to you.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Please read these Terms and any additional terms applicable to your
          use of the Site or the Services before using it. By accessing or using
          the Services either as a registered user or not, you confirm your
          agreement to be bound by these Terms. These Terms expressly supersede
          prior agreements or arrangements with you regarding the use of the
          Services.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          <b>IMPORTANT ARBITRATION NOTICE:</b> THESE TERMS CONTAIN AN
          ARBITRATION CLAUSE AFFECTING YOUR LEGAL RIGHTS. PLEASE READ THE
          ARBITRATION CLAUSE CAREFULLY, AS IT REQUIRES YOU TO ARBITRATE DISPUTES
          WITH PROYOUREV ON AN INDIVIDUAL BASIS, LIMITS YOUR ABILITY TO SEEK
          RELIEF IN A COURT OF LAW, AND WAIVES YOUR RIGHT TO PARTICIPATE IN
          CLASS ACTIONS OR CLASS ARBITRATIONS.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          1. Changes to this Agreement
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          We may modify these Terms at any time, at our sole discretion, as they
          are subject to change. If we do so, we will let you know either by
          posting the modified Terms on the Site or through other
          communications, such as the email address registered in your account.
          It's important that you review the Terms whenever we modify them
          because if you continue to use the Services after we have posted
          modified Terms on the site, you are indicating to us that you agree to
          be bound by the modified Terms. Supplemental terms and conditions or
          documents that may be posted on the Site from time to time are hereby
          expressly incorporated herein by reference.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          The contents of this site may include technical inaccuracies or
          typographical errors. We may publish changes to the contents at any
          time without prior notice to you. We may also make improvements and/or
          changes in the products and services and/or the features described
          herein at any time without prior notice to you. We shall have the
          right to revise and edit these contents at any time. By linking,
          accessing, browsing, and/or using this site, you agree to be bound by
          any such revisions.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Because our Services are evolving over time, we may change or
          discontinue all or any part of the Services at any time and without
          notice, at our sole discretion.
        </p>
        <b className="align-self-center mt-4 mb-4">
          PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
          SERVICES.
        </b>
        <h2 className={stylesTermsConditions.subHeading}>
          2. The ProYourEv Services
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv provides an online platform that allows event organizers and
          promoters ("Event Organizers") to create event listings and ticket
          sales pages to advertise their events to attendees ("Attendees"). We
          also provide tools for Event Organizers to manage ticket sales,
          collect payments, and otherwise organize and promote their events.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Our services help Event Organizers reach more potential Attendees and
          sell more tickets by giving them an online platform to list details
          about their events, sell both free and paid tickets, collect payments
          securely, communicate with prospective Attendees, and track interest
          and engagement.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          For Attendees, our Platform provides a place to discover interesting
          local events that Event Organizers have chosen to list, purchase
          tickets, manage their reservations, receive updates from Event
          Organizers, leave feedback and reviews, refer events to others, and
          otherwise engage with events they want to participate in.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          We aim to connect Event Organizers and Attendees to help more local
          events successfully reach the right audiences. Our services provide
          the online infrastructure to make event listings, event management,
          event marketing, and ticket sales easier so Event Organizers can focus
          on planning great events. However, we may take actions such as
          limiting the visibility of your account, event listing or suspending
          your account if we determine that you are acting against the policies
          herein this agreement and other ProYourEv agreements incorporated
          herein by reference, including all the policies and terms on our site.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Users understand that ProYourEv only provides the Services as a venue
          to connect people.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          We also do not guarantee, nor do we warrant or represent, that the
          information displayed on the Service by any user about an event or
          listing is accurate or genuine.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          3. ProYourEv accounts: registration and acceptance
        </h2>
        <ul>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              <u>
                <b>User Account:</b>
              </u>{" "}
              To access certain features (like creating an event listing or
              making a ticket purchase), you may need to register and maintain
              an active account ("User Account"), and this may require both a
              username and a password. You may also have to provide certain
              registration information, all of which must be accurate and
              updated as they change.
            </p>
            <p className={stylesTermsConditions.paragraph}>
              We reserve the right to revoke the privileges of the account or
              access to or use of the Services, and those of any and all linked
              accounts without warning if, in our sole discretion, false or
              misleading information has been provided in creating, marketing,
              or maintaining your Profile or account.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              <u>
                <b>Eligibility:</b>
              </u>{" "}
              To register for an Account or use the Services, you must, and
              hereby, represent that you will use the Services as intended only.
              You also represent that you are either a legal entity or an
              individual who is 18 years or older (or has otherwise reached the
              age of majority in the jurisdiction in which you conduct business)
              in each case who can form legally binding contracts.
            </p>
          </li>
        </ul>
        <p className={stylesTermsConditions.paragraph}>
          You are not allowed to share or give your user account details to
          anyone else. You are responsible for maintaining the confidentiality
          of Your password, and you agree not to use another person's user
          account details or select a user account detail:
        </p>
        <ul>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              with the intent to impersonate another person;
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              subject to the rights of another person without authorization;
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              in violation of the intellectual property rights of another person
              or
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              that ProYourEv, in its sole discretion, deems inappropriate or
              offensive or discriminates on the basis of race, color, sex,
              language, religion, political or other opinion, national or social
              origin, property, birth, or other status.
            </p>
          </li>
        </ul>
        <p className={stylesTermsConditions.paragraph}>
          You further acknowledge and agree that you shall have no ownership or
          other property interest in Your account and/or user name. Failure to
          comply with the foregoing shall constitute a breach of these Terms,
          which may result in immediate suspension or termination of your user
          account. ProYourEv will not be liable for any loss or damage arising
          from your failure to comply with these obligations. ProYourEv reserves
          the right to delete or change user accounts (with or without notice)
          at any time and for any reason.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          4. Relationship with ProYourEv
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          You acknowledge, agree, and understand that ProYourEv is not a party
          to the relationship or any dealings between users of the Services. We
          do not organize events or event services, and we are not the employer
          of any user of the services. ProYourEv is not responsible for any
          non-performance or breach of any contract entered into between users
          of our Services.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Although ProYourEv will make necessary efforts to ensure that a user
          will perform its expected duties, we cannot and do not guarantee that
          the concerned user(s) will perform any transaction concluded through
          the Services. Users are responsible for evaluating and determining the
          suitability of any recommended event or service on their own. It is
          also your responsibility to check and comply with all applicable laws
          (federal, state, local, and foreign laws, including minimum age
          requirements) pertaining to your use, possession, and purchase of any
          product and/or services through the ProYourEv Services.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          5. Intellectual property rights, license, and restrictions
        </h2>
        <ul>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              <u>
                <b>Intellectual property rights:</b>
              </u>{" "}
              This Service is controlled and operated by ProYourEv, and all
              material on the Services, including but not limited to software,
              images, illustrations, user interface, text, scripts, graphics,
              audio clips, video clips, and other interactive features
              (collectively the "ProYourEv materials"), made available to you on
              the Services. Logos, trademarks, and service marks contained
              herein, are owned by ProYourEv. Other service marks, logos, and
              names on this Site are the property of their respective owners.
              Any use of the Services or the contents made available to you on
              the Services other than as specifically authorized herein, without
              our prior written permission is strictly prohibited and will
              terminate the license granted herein. Such unauthorized use may
              also violate applicable laws including without limitation
              copyright and trademark laws and applicable communications
              regulations and statutes. Unless explicitly stated herein, nothing
              in these Terms shall be construed as conferring any license to
              intellectual property rights, whether by estoppel, implication, or
              otherwise. This license is revocable by ProYourEv at any time
              without notice and with or without cause.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              <u>
                <b>License and restrictions:</b>
              </u>{" "}
              Subject to your compliance with these Terms, we grant you a
              limited, non-exclusive, non-sublicensable, revocable,
              non-transferable license to access and use the Site subject to the
              Terms of this Agreement. Any rights not expressly granted herein
              are reserved by us.
            </p>
            <p className={stylesTermsConditions.paragraph}>
              You may not: (i) remove any copyright, trademark, or other
              proprietary notices from any portion of the Site; (ii) reproduce,
              modify, prepare derivative works based upon, distribute, license,
              lease, sell, resell, transfer, publicly display, publicly perform,
              transmit, stream, broadcast or otherwise exploit the Site except
              as expressly permitted by us; (iii) decompile, reverse engineer or
              disassemble the Site except as may be permitted by applicable law;
              (iv) link to, mirror or frame any portion of the Site; or (v)
              cause or launch any programs or scripts for the purpose of
              scraping, indexing, surveying, or otherwise data mining any
              portion of the Site or unduly burdening or hindering the operation
              and/or functionality of any aspect of the Site.
            </p>
          </li>
        </ul>
        <h2 className={stylesTermsConditions.subHeading}>
          6. Events listings, descriptions and tickets
        </h2>
        <u>
          <b>Events listings and descriptions</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          While we make reasonable attempts to ensure events and event
          descriptions are as accurate as possible, you understand that
          ProYourEv does not list, promote, or evaluate events listed or
          promoted through our Services. We also make no representation and give
          no warranties or undertakings of any kind, express or implied, with
          regard to the accuracy, reliability, timeliness, or completeness of
          any such information. We do not accept liability for any loss of
          whatsoever nature or howsoever caused, arising directly or indirectly
          from the use of or reliance upon this site or any of the information
          it contains. Any legal claim that is related to the product or service
          you accessed or purchased through the ProYourEv Site must be brought
          against the user who made such service available to you.
        </p>
        <u>
          <b>Tickets</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          For Event Organizers: Event Organizers may use our Services to list
          ticket sales for their events. This allows them to advertise event
          details, ticket prices and levels, ticket availability,
          starting/ending sales dates, and other relevant ticket details.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Event Organizers warrant that all ticket information is accurate and
          that they will honor tickets sold via our Services. Event Organizers
          may establish ticket limits, set variable or dynamic pricing, specify
          restrictions, offer promotional codes or discounts, and configure
          other ticket settings through their event management dashboard.
        </p>
        <u>
          <b>For Attendees</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          When purchasing tickets, Attendees agree to pay the advertised ticket
          prices. Attendees must abide by any ticket restrictions specified by
          Event Organizers such as entry requirements, behavior rules, age
          limits, transfers/exchanges policies, or health and safety protocols.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Our Services facilitate ticket sales by allowing Attendees to select,
          purchase, receive confirmation of, and securely access tickets for
          events they wish to participate in.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          When you purchase tickets as an Attendee, you agree to the ticket
          details provided, including prices, fees, dates, transfers, exchanges,
          refunds, and other related rules and restrictions. All ticket sales
          are final unless an event is formally cancelled or rescheduled. The
          Event Organizer's refund/exchange policy will apply in such cases.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          You are solely liable for safeguarding tickets issued to you. Lost,
          stolen, damaged, or destroyed tickets may not be reissued or refunded.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          7. Paid services, and payment terms
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv does not require you to pay before you can access and make
          use of the ProYourEv site or the Services in general unless you want
          to promote multiple event locations as an event organizer.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          <u>
            <b>Paid Subscription Plans:</b>
          </u>{" "}
          We offer paid Subscription plans ("Subscriptions") that provide
          additional and premium features and enhanced resources. Paid plans
          require a one-time fee or a recurring payment of fees monthly or
          annually. These payments may require you to provide a valid method of
          payment, such as a credit card or debit card (a "Payment Method"). By
          providing a payment method, you (i) represent that you are authorized
          to use the payment method and that any payment information you provide
          is true and accurate and (ii) authorize us to charge you for the
          services or products using your payment method. You are responsible
          for all charges incurred in connection with your payment, and you
          agree to pay the fees and charges for any purchased feature.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          At our sole discretion, we may offer a free trial or discounted
          subscription offers. By selecting a Subscription, you agree to pay a
          subscription fee on a designated cycle for the Services that we make
          available to you (the "Subscription Fee"). Upon the expiration of a
          free trial or discounted subscription offer (where applicable), you
          will be charged the applicable subscription fees for your use of the
          Services.
        </p>
        <u>
          <b>Billing cycle</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          The Subscription Fee will be billed at the time you establish your
          Subscription and on an ongoing, regular basis unless you cancel your
          Subscription. Note that the timing of your billing may change (i.e. in
          the event of a problem with your Payment Method, such as an expired
          credit card).
        </p>
        <u>
          <b>Subscription plan/subscription fee changes</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv reserves the right to change the price for a subscription to
          the Service at our sole discretion, and such subscription fee change
          will become effective at the end of the then-current Billing Cycle. We
          can also make changes to our subscription plans. We will provide you
          with reasonable prior notice of any change in our subscription plans
          and subscription fee to give you the opportunity to evaluate your
          current Subscription and make any changes, up to and including the
          option to terminate your Subscription before such change becomes
          effective.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Unless subscription fees have been locked in under contract with
          ProYourEv for a specific agreed period, your continuing use of the
          Service after the subscription fee adjustment takes effect constitutes
          your consent to pay the updated subscription fee amount.
        </p>
        <u>
          <b>Cancellation</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          You can cancel your Subscription or auto-renewals at any time in your
          account settings. Please note that you must cancel your Subscription
          before it renews for a subsequent term in order to avoid being charged
          for the next term's subscription Fee. If you cancel your Subscription,
          the cancellation will become effective at the end of the then-current
          term.
        </p>
        <u>
          <b>No refunds</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          Refunds will not be provided for any subscription except if such
          subscription is cancelled within 24 hours of payment. If you have
          commenced a subscription period and you then decide to cancel your
          Subscription after 24 hours, you will not be entitled to receive a
          refund for the fees you already paid for such subscription period.
          However, you will continue to be able to use the service for which you
          subscribed until the end of such subscription period. You understand
          and agree that we will not provide refunds or prorated billing for
          cancelled mid-term subscriptions.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          <u>
            <b>Cancellation and changes:</b>
          </u>{" "}
          For monthly and annual subscriptions, you may cancel or downgrade your
          plan at any time, and cancellation/changes will take effect at the end
          of your current billing period.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Upon any cancellation or downgrade, you lose access to aspects of our
          Services associated with the higher plan levels.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          <u>
            <b>Fee changes:</b>
          </u>{" "}
          ProYourEv, at its sole discretion and at any time, may modify the
          subscription fees for the subscriptions. Any Subscription fee change
          will become effective at the end of the then-current Billing Cycle.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv will provide you with reasonable prior notice of any change
          in Subscription fees to give you an opportunity to terminate your
          Subscription before such change becomes effective.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Your continued use of the Service after the Subscription fee change
          comes into effect constitutes your agreement to pay the modified
          Subscription fee amount.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>8. User content</h2>
        <p className={stylesTermsConditions.paragraph}>
          We may allow Users to submit, upload, publish or otherwise make
          available text reviews, ratings, writeups, comments, posts, graphics,
          images, tags and other content ("User Content") on the site. By thus
          sharing any User Content, you grant ProYourEv unconditional consent to
          access, monitor, retain, copy, host, and process your User Content
          solely for the purpose of displaying the same on the site.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          You affirm and covenant that any User Content you submit complies with
          provisions in these Terms and does not infringe any third party's
          proprietary rights or privacy rights. You shall not share content that
          is unlawful, abusive, harmful, obscene, racist, sexist or ethically
          objectionable. We reserve the sole discretion to remove any User
          Content from the site without liability to provide reasons or
          justification at any time without notice.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          You also grant ProYourEv a non-exclusive, royalty-free and fully paid
          license to use, reproduce, modify, publish, and distribute your User
          Content (except any personal identification information) for the
          purpose of displaying the same on the site. This license shall survive
          any termination of your access to the site.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          9. Rights that you grant us over your content
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          By sharing, posting or uploading content that you own on ProYourEv,
          you grant us a non-exclusive, transferable, sub-licensable,
          royalty-free, and worldwide license to host, distribute, modify, run,
          copy, publicly perform or display, translate, and create derivative
          works of your content.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          This license allows us to retain and continue using your content even
          if you stop using ProYourEv. We may also be required to retain,
          disclose or delete your content to comply with laws, regulations,
          court orders or other legal processes, to enforce these Terms, or to
          protect any rights, property or safety of ours, our affiliates or
          other ProYourEv users.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          You remain responsible for the content you provide through the
          Service.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          We may also publicly display your picture, username, and information
          about your actions or relationships on ProYourEv alongside sponsored
          content and accounts. We respect your ad settings where applicable.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          10. User representation and acceptable use
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          You may not access or use the site for any purpose other than that for
          which we make the service available. The service may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          As a user of the site, you agree not to:
        </p>
        <ul>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Systematically retrieve data or other content from the site to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Circumvent, disable, or otherwise interfere with security-related
              features of the site, including features that prevent or restrict
              the use or copying of any content or enforce limitations on the
              use of the site and/or the Content contained therein.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the site.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Use any information obtained from the site in order to harass,
              abuse, or harm another person.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Use the site in a manner inconsistent with any applicable laws or
              regulations.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party's uninterrupted use and
              enjoyment of the site or modifies, impairs, disrupts, alters, or
              interferes with the use, features, functions, operation, or
              maintenance of the site.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages or using any data mining, robots, or
              similar data gathering and extraction tools.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Attempt to impersonate another user or person or use the username
              of another user.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats ("gifs"), 1×1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to as
              "spyware" or "passive collection mechanisms" or "pcms").
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Interfere with, disrupt, or create an undue burden on the site or
              the networks or services connected to the site.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the site to you.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Attempt to bypass any measures of the site designed to prevent or
              restrict access to the site or any portion of the site.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Decipher, decompile, disassemble, or reverse engineer any of the
              software comprising or in any way making up a part of the site.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Except as may be the result of a standard search engine or
              Internet browser usage, use, launch, develop, or distribute any
              automated system, including, without limitation, any spider,
              robot, cheat utility, scraper, or offline reader that accesses the
              site, or using or launching any unauthorized script or other
              software.
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Make any unauthorized use of the site, including collecting
              usernames and/or email addresses of users by electronic or other
              means to send unsolicited emails or create user accounts by
              automated means or under false pretenses.
            </p>
          </li>
        </ul>
        <p className={stylesTermsConditions.paragraph}>
          <i>
            <b>The content you access:</b>
          </i>{" "}
          It is possible that other users (including unauthorized users or
          "hackers") may post or transmit offensive or obscene materials on the
          Services and that you may be involuntarily exposed to such offensive
          and obscene materials. It also is possible for others to obtain
          personal information about you due to your use of the Services, and
          the recipient may use such information to harass or injure you. We do
          not approve of such unauthorized uses, but by using the Services, you
          acknowledge and agree that we are not responsible for the use of any
          personal information that you publicly disclose or share with others
          on the Services. Please carefully select the type of information that
          you publicly disclose or share with others on the Services.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          11. Your safety; interaction with other users
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          The Services allow you to interact with other users, either in person
          or online. Although ProYourEv strives to encourage a respectful member
          experience, ProYourEv is not responsible for the conduct of any member
          on or off of the Services that we provide. You agree to use caution in
          all interactions with other members, particularly if you decide to
          communicate off the Service or meet in person.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          By making use of the Services, you understand and agree that we have
          the right to monitor your access and use of the Services for the
          purpose of operating the Services, to ensure compliance with these
          Terms, and to comply with applicable law or other legal requirements.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          12. Non-discrimination policy
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv prohibits discrimination against any User or other third
          party based on race, religion, national origin, disability, sexual
          orientation, sex, marital status, gender identity, age, or any other
          characteristic protected under applicable law. ProYourEv wants all of
          our users to feel safe and welcome, and we do not tolerate any conduct
          that we determine to be discriminatory.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          13. Consent to electronic transactions, communication, and disclosures
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          To the fullest extent permitted by law, these Terms and any other
          agreements, notices or other communications from ProYourEv to you
          regarding our services being offered ("Communications") may be
          provided to you electronically, and you consent and agree to receive
          Communications in an electronic form. Electronic Communications may be
          posted on the pages within the ProYourEv site and/or delivered to your
          email address.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Also, you expressly consent to be contacted by us, our agents,
          representatives, affiliates, or anyone calling on our behalf for any
          and all purposes at any telephone number, physical or electronic
          address you provide, or any other place where you may be reached. You
          agree we may contact you in any way, including SMS messages (including
          text messages), calls using prerecorded messages or artificial voice,
          and calls and messages delivered using an auto telephone dialing
          system or an automatic texting system. Automated messages may be
          played when the telephone is answered, whether by you or someone else.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          14. Copyright infringement and DMCA notification
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv respects the intellectual property of others and asks its
          users of the Services to do the same. ProYourEv's Service may not be
          used to transmit, retransmit, distribute, post, or store any material
          that violates any rights of any person, including rights protected by
          copyright, trade secret, patent, or other intellectual property or
          similar laws or regulations.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv has established procedures for enforcing this statement
          which is designed to be in accord with the Digital Millennium
          Copyright Act of 1998 (the "DMCA"). If you believe that your
          copyrighted work has been copied and is accessible on any of
          ProYourEv's Services in a way that constitutes copyright infringement,
          please notify us by sending us an email at{" "}
          <a
            href="https://mail.google.com/mail/#compose"
            className={stylesTermsConditions.link}
          >
            info@proyourev.com.
          </a>
        </p>
        <p className={stylesTermsConditions.paragraph}>
          In order to be valid, the notification must be in writing and must
          contain the following information and be submitted to us:
        </p>
        <ul>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              A description of the copyrighted content or other intellectual
              property that you claim has been infringed;
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              A statement by you that you have a good faith belief that the use
              of this content or intellectual property is not authorized and for
              which reason;
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              A detailed description of where this content or intellectual
              property is located on the Service, for us to find it;
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              Your address, telephone number, and email address;
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              A signature of the person authorized to act on behalf of the owner
              of the copyright or other intellectual property interest;
            </p>
          </li>
          <li>
            <p className={stylesTermsConditions.paragraph}>
              A statement by you, made under penalty of perjury, that the above
              information provided by you in the notification is accurate and
              that you are the copyright or intellectual property owner or
              authorized to act on behalf of the copyright or intellectual
              property owner.
            </p>
          </li>
        </ul>
        <p className={stylesTermsConditions.paragraph}>
          Please note that any person who knowingly misrepresents to ProYourEv
          that material is infringing shall be liable to ProYourEv and/or the
          alleged infringer for any damages (including costs and attorneys'
          fees) suffered or incurred by ProYourEv and/or the alleged infringer
          as a result of ProYourEv's reliance on such misrepresentation and
          removing or disabling access to such material. ii) Similarly, any
          person who knowingly misrepresents to ProYourEv that the material was
          removed or access blocked by mistake or misidentification shall be
          liable to ProYourEv and/or the copyright owner or its authorized
          licensee for any damages (including costs and attorneys' fees)
          suffered or incurred by ProYourEv and/or the copyright owner or its
          authorized licensee as a result of ProYourEv relying on such
          misrepresentation and replacing such removed or blocked material.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          15. Violations and reporting violations
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          Users of the Service may receive a warning to their account for
          violations of any of these terms or for any user misconduct reported
          to us. A warning may be sent to the email address attached to the user
          account. Warnings do not limit account activity but can lead to the
          user's account being suspended or becoming permanently disabled based
          on the severity of the violation.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          If you come across any content that may be in violation of these
          Terms, you should report it to us immediately so that we can take
          appropriate actions. Our team reviews all cases. To protect individual
          privacy, the results of the review may not be shared, and you
          understand and accept that except as required by law, we are not
          obligated to take action in response to any report. You can review our
          Privacy Policy for more information on our disclosure practices.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          16. Third-party links and services
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          ProYourEv may provide links, at its sole discretion, to other sites
          and services. ProYourEv is not responsible for the availability of
          these outside resources or their contents. ProYourEv expressly
          disclaims any responsibility for the content, materials, accuracy of
          information and/or the quality of the products or services provided
          by, available through, or advertised on these third-party sites or the
          products or services provided by any third party.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>17. Release</h2>
        <p className={stylesTermsConditions.paragraph}>
          In addition to the recognition that ProYourEv is not a party to any
          contract between Users, you hereby release ProYourEv, our Affiliates,
          and our respective officers, directors, agents, subsidiaries, joint
          ventures, and employees from claims, demands, and damages (actual and
          consequential) of every kind and nature, known and unknown, arising
          out of or in any way connected with any dispute you have with another
          User, whether it be at law or in equity that exists as of the time you
          enter into this agreement.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          This release includes, for example, and without limitation, any
          disputes regarding the performance, functions, and quality of the
          Services provided by a user and requests for refunds based upon
          disputes.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>18. Privacy Policy</h2>
        <p className={stylesTermsConditions.paragraph}>
          When accessing and/or using the site and the Services we offer, we may
          obtain certain information about you, or you may be required to
          provide certain information to us. All uses of your information will
          be treated in accordance with our Privacy Policy, which herein forms
          an integral part of these Terms. Please refer to our Privacy Policy
          for information on how we collect, use, and disclose information from
          our users.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          19. Liability Waiver
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          We aim to provide helpful Site tools and resources but cannot control
          or guarantee their accuracy, completeness, or reliability. YOU
          EXPRESSLY AGREE THAT THE USE OF THE SITE AND SERVICE IS AT YOUR SOLE
          RISK, AND YOU ASSUME ALL RISK. THE SITE AND ITS INFORMATION, CONTENT,
          MATERIALS, SERVICES, AND USER CONTENT IS PROVIDED ON AN "AS AVAILABLE"
          "AS IS" BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, PROYOUREV AND
          ITS LICENSORS DISCLAIM ALL WARRANTIES WITH RESPECT TO THE SITE AND
          SERVICES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          NON-INFRINGEMENT, TITLE, MERCHANTABILITY, QUIET ENJOYMENT, QUALITY OF
          INFORMATION, FITNESS FOR A PARTICULAR PURPOSE, AND RESULTS THAT MAY BE
          OBTAINED FROM USE OF THE SITE. PROYOUREV DOES NOT ENDORSE, RECOMMEND,
          OR MAKE ANY WARRANTIES AS TO THE CONTENT, INFORMATION, MATERIALS, USER
          MATERIALS, FEATURES, SERVICES, OPINIONS, OR STATEMENTS AVAILABLE ON OR
          THROUGH THE SITE OR THROUGH LINKS ON THE SITE. PROYOUREV DOES NOT
          WARRANT THAT THE SITE AND SERVICES WILL MEET YOUR REQUIREMENTS, THAT
          THE OPERATION OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT
          DEFECTS IN THE SITE WILL BE CORRECTED. YOU SPECIFICALLY ACKNOWLEDGE
          THAT PROYOUREV IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL
          CONDUCT OF OTHER USERS OR THIRD-PARTIES AND THAT THE RISK OF INJURY
          FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          20. Limitation of Liability
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL PROYOUREV OR
          ITS SUPPLIERS/LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
          DIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR INDIRECT
          DAMAGES (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
          OTHERWISE), WHICH INCLUDE, WITHOUT LIMITATION, DAMAGES FOR PERSONAL
          INJURY, LOST PROFITS, LOST DATA AND BUSINESS INTERRUPTION, ARISING OUT
          OF THE USE OF, OR INABILITY TO USE, THE SITE, ITS CONTENT OR SERVICES,
          OR ANY OF ITS THIRD PARTY CONTENT OR SERVICES AVAILABLE ON OR THROUGH
          THE SITE, EVEN IF PROYOUREV HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. IN ANY CASE, THE ENTIRE LIABILITY OF PROYOUREV AND ITS
          SUPPLIERS/LICENSORS UNDER THESE TERMS FOR ALL DAMAGES, LOSSES, AND
          CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
          OTHERWISE) IS LIMITED TO THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESS TO
          THE SITE.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Some states do not allow the exclusion of incidental or consequential
          damages or the limitation on how long an implied warranty lasts, so
          some of the above may not apply to you.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          21. Indemnification
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          You agree to defend and indemnify ProYourEv from any claims, damages,
          losses or expenses related to: (a) your use or misuse of the site; (b)
          any user content or information you provide or make accessible through
          the site; or (c) your violation of any policies, terms or applicable
          laws. We reserve the right to handle claims however we see fit. This
          indemnity applies to any affiliates, contractors, officers or
          directors arising from the matters indemnified.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          22. Agreement term and termination
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          This Agreement is effective until terminated by either you or
          ProYourEv. We reserve the right to suspend or terminate your access to
          our Services if you materially breach these Terms, misuse our
          Services, or violate applicable law. You can terminate your account at
          any time.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>23. Governing law</h2>
        <p className={stylesTermsConditions.paragraph}>
          These Terms and your use of the Services shall be governed by and
          construed in accordance with the U.S. Federal Arbitration Act, federal
          arbitration law, and laws of the State of New York applicable to
          agreements made and to be entirely performed within the State of New
          York, without regard to its conflict of law principles.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          24. Dispute resolution; arbitration
        </h2>
        <u>
          <b>Dispute with other users of ProYourEv</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          In the event that you find yourself in dispute with other users of
          ProYourEv Services or a third party, we advise you to contact such a
          party and try to resolve the dispute amicably. You further release
          ProYourEv or any of its employees, directors, officers, agents, or
          suppliers from any claims, damages, and demands that may arise out of
          disputes with other parties or users of the ProYourEv Services.
        </p>
        <u>
          <b>Dispute with ProYourEv</b>
        </u>
        <p className={stylesTermsConditions.paragraph}>
          To expedite dispute resolution and control the cost of any dispute,
          controversy or claim related to this Agreement (or the breach,
          termination, enforcement, interpretation or validity thereof)
          ("Dispute"), you, as a user of ProYourEv's site, agree to first
          attempt to negotiate any Dispute (except those Disputes expressly
          provided below) informally for at least thirty (30) days before
          initiating any arbitration. Such informal negotiations commence upon
          written notice from one person to the other. We are available by email
          at{" "}
          <a
            href="https://mail.google.com/mail/#compose"
            className={stylesTermsConditions.link}
          >
            info@proyourev.com
          </a>{" "}
          to address any concerns or questions that you may have regarding your
          use of ProYourEv's site and services.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          In the event that a dispute is not settled through the informal
          negotiation, any controversy or claim arising out of or relating to
          these Terms shall be settled by binding arbitration administered by
          the American Arbitration Association (AAA) in accordance with its
          comprehensive arbitration rules and procedures by a sole arbitrator.
          (The AAA Rules are available at{" "}
          <a
            href="https://www.adr.org/arb_med"
            className={stylesTermsConditions.link}
          >
            www.adr.org/arb_med
          </a>{" "}
          or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act
          will govern the interpretation and enforcement of this Section.
          Judgment on the arbitration award may be entered in any court having
          competent jurisdiction. Unless otherwise expressly required by
          applicable law, each party shall bear their own costs of legal and
          expert fees incurred in the arbitration, irrespective of the outcome.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          Notwithstanding the above, we reserve the right to pursue injunction
          proceedings before any court of competent jurisdiction to protect or
          enforce our intellectual property rights arising from these Terms.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          You and ProYourEv agree, to the fullest extent permitted by applicable
          law, that each may bring claims against the other only in your or its
          individual capacity and not as a plaintiff or class member in any
          purported class or representative proceeding. Further, if the parties'
          dispute is resolved through arbitration, the arbitrator may not
          consolidate another person's claims with your claims, and may not
          otherwise preside over any form of a representative or class
          proceeding.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          25. General provisions
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          <u>
            <b>Assignment:</b>
          </u>{" "}
          Users may not transfer or assign any rights under these Terms. We
          reserve the right to transfer or assign our rights and obligations
          under these Terms in connection with a merger, acquisition or sale of
          assets, by operation of law or otherwise.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          <u>
            <b>Severability:</b>
          </u>{" "}
          If any provision of these Terms is found unenforceable, only that
          provision will be removed, and the remaining terms will stay
          effective.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          <u>
            <b>Waiver:</b>
          </u>{" "}
          No waiver of any of the provisions of this Agreement will constitute a
          continuing waiver unless otherwise expressly so provided in writing.
          The failure of either Party to enforce any of the provisions of this
          Agreement at any time, will in no way be construed to be a present or
          future waiver of such provisions. We do not guarantee that we will
          will pursue legal actions against all breaches of this Agreement.
        </p>
        <p className={stylesTermsConditions.paragraph}>
          <u>
            <b>Force Majeure:</b>
          </u>{" "}
          Notwithstanding the other provisions of this Agreement, if ProYourEv
          is in good faith prevented from performing its obligations under this
          Agreement because of an event beyond its control, including without
          limitation, any act or omission of our third-party partners, war
          (declared or undeclared), terrorism, explosions, pandemics, and acts
          of God like an earthquake, flood, hurricanes, tornadoes, and other
          natural calamities, ProYourEv will be relieved from performing its
          obligations provided that, it will take all reasonable steps to
          promptly remedy the cause of such delay or failure if it is in its
          power to do so.
        </p>
        <h2 className={stylesTermsConditions.subHeading}>
          26. Questions or complaints?
        </h2>
        <p className={stylesTermsConditions.paragraph}>
          Feedback, comments, requests for technical support, and other
          communications relating to the Services should be directed to us.
          Please contact us at{" "}
          <a
            href="https://mail.google.com/mail/#compose"
            className={stylesTermsConditions.link}
          >
            info@proyourev.com.
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default TermsConditionsPage;
