import React, { useEffect } from "react";
import { stylesSplash } from "../../css";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { CustomButton, Loader } from "../../components";

const Splash = () => {
  const navigation = useNavigate();
  setTimeout(() => {
    navigation("/home");
  }, 2500);

  return (
    <Row className={stylesSplash.container}>
      <Col lg={5} md={7} sm={10} className={stylesSplash.section}>
        <h2 className={stylesSplash.title}>Welcome to Pepople Event</h2>
        <h2 className={stylesSplash.subtitle}>
          Here you can create events to make the day special.
        </h2>
          <Spinner animation="border" variant="dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
      </Col>
    </Row>
  );
};

export default Splash;
