import React, { useState } from "react";
import { stylesStripeCard } from "../../css";
import { CustomButton } from "..";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../tost/tost";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { decryptData } from "../../layouts/encryption/cryption";
import { URL } from "../../utils/ApisLink";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

function StripeCard({
  planId,
  onCancel,
  type,
  eventId,
  heading,
  ticketPrice,
  planPrice,
}) {
  const [count, setCount] = useState(1);
  const [name, setName] = useState();
  const [code, setCode] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const decrypt = decryptData();
  const userId = decrypt.user._id;
  const navigation = useNavigate();

  const handelToken = async (e) => {
    e.preventDefault();
    if (name && code) {
      if (!stripe || !elements) {
        return;
      } else {
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        const { token, error } = await stripe.createToken(cardNumberElement);

        if (error) {
          showErrorToast({ message: error.message });
        } else {
          if (type === "subscription") {
            subscribeToPlan(token.id);
          } else {
            handleReservation(token.id);
          }
        }
      }
    } else showErrorToast({ message: "Please enter the complete details." });
  };

  const subscribeToPlan = async (token) => {
    const subscriptionParams = {
      plan_id: planId,
      card_token: token,
      email: decrypt.user.email,
      userId: decrypt.user._id,
    };

    const response = await fetch(URL + "/subscribe-plan", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${decrypt.token}`,
      },
      body: JSON.stringify(subscriptionParams),
    });

    if (response.ok) {
      showSuccessToast({
        message: "Subscribed Successfully. Please login again!",
      });
      navigation("/login");
      sessionStorage.clear();
    } else {
      console.error("Subscription failed");
    }
  };

  const handleReservation = async (value) => {
    const ticketData = {
      event_id: eventId,
      token: value,
      user_id: userId,
      quantity: count,
    };
    try {
      const response = await fetch(URL + "/create-ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ticketData),
      });
      if (response.ok) {
        navigation("/confirmation");
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const decCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incCount = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };

  return (
    <form
      onSubmit={handelToken}
      id="payment-form"
      className={stylesStripeCard.formSec}
    >
      <span className={stylesStripeCard.formHeading}>{heading}</span>
      <div className={stylesStripeCard.formGroup}>
        <label htmlFor="name" className={stylesStripeCard.formLabel}>
          Owner Name
        </label>
        <input
          id="name"
          type="text"
          placeholder="Owner Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={stylesStripeCard.formTextInput}
        />
      </div>
      <div className={stylesStripeCard.formGroup}>
        <label htmlFor="card-number" className={stylesStripeCard.formLabel}>
          Card Number
        </label>
        <CardNumberElement
          id="card-number"
          className={stylesStripeCard.formInput}
        />
      </div>
      <div className={stylesStripeCard.formRow}>
        <div className={stylesStripeCard.formGroup}>
          <label htmlFor="card-expiry" className={stylesStripeCard.formLabel}>
            Card Expiry
          </label>
          <CardExpiryElement
            id="card-expiry"
            className={stylesStripeCard.formInput}
          />
        </div>
        <div className={stylesStripeCard.formGroup}>
          <label htmlFor="card-cvc" className={stylesStripeCard.formLabel}>
            CVC
          </label>
          <CardCvcElement
            id="card-cvc"
            className={stylesStripeCard.formInput}
          />
        </div>
      </div>
      <div className={stylesStripeCard.formGroup}>
        <label htmlFor="postal_code" className={stylesStripeCard.formLabel}>
          Postal Code
        </label>
        <input
          id="postal_code"
          type="number"
          placeholder="Postal Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className={stylesStripeCard.formTextInput}
        />
      </div>
      {type === "reservation" ? (
        <>
          <div className={stylesStripeCard.counterSec}>
            <label className={stylesStripeCard.counterLabel}>
              Number of Guests
            </label>
            <div className={stylesStripeCard.counter}>
              <div className={stylesStripeCard.counterInput}>
                {count === 1 ? count + " " + "Guest" : count + " " + "Guests"}
              </div>
              <div className={stylesStripeCard.counterBtn} onClick={decCount}>
                <RemoveRounded
                  sx={{ fontSize: 28 }}
                  className={stylesStripeCard.counterIcon}
                />
              </div>
              <div className={stylesStripeCard.counterBtn} onClick={incCount}>
                <AddRounded
                  sx={{ fontSize: 28 }}
                  className={stylesStripeCard.counterIcon}
                />
              </div>
            </div>
          </div>
          <div className={stylesStripeCard.priceGroup}>
            <h2 className={stylesStripeCard.priceText}>Ticket Price</h2>
            <h2 className={stylesStripeCard.priceText}>
              {ticketPrice * count + "$"}
            </h2>
          </div>
        </>
      ) : (
        <div className={stylesStripeCard.priceGroup}>
          <h2 className={stylesStripeCard.priceText}>Plan Price</h2>
          <h2 className={stylesStripeCard.priceText}>{planPrice + "$"}</h2>
        </div>
      )}
      <div className={stylesStripeCard.formBtnGroup}>
        <CustomButton
          type="submit"
          title="Pay"
          styles={stylesStripeCard.stripeBtn}
        />
        <CustomButton
          title="Cancel"
          styles={`${stylesStripeCard.stripeBtn} mt-3`}
          onPress={onCancel}
        />
      </div>
      <span className={stylesStripeCard.formFooter}>
        Powered by{" "}
        <a href="https://stripe.com/" className={stylesStripeCard.link}>
          Stripe
        </a>
      </span>
    </form>
  );
}

export default StripeCard;
