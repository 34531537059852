import React from "react";
import { stylesDashboard } from "../../css";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  LocalActivityTwoTone,
  WidgetsTwoTone,
  Groups2TwoTone,
  StyleTwoTone,
} from "@mui/icons-material";
import { CustomButton } from "../../components";

const AdminDashboard = () => {
  const navigation = useNavigate();
  return (
    <Container fluid className={stylesDashboard.container}>
      <div className={stylesDashboard.header}>
        <h2 className={stylesDashboard.title}>Dashboard</h2>
        <CustomButton
          title={"Logout"}
          onPress={() => navigation("/admin/logout")}
        />
      </div>
      <Row className={stylesDashboard.eventSec}>
        <Col xl={3} lg={3} md={6} sm={12} xs={12}>
          <div className={stylesDashboard.card}>
            <Groups2TwoTone htmlColor="#222222" sx={{ fontSize: 75 }} />
            <h2 className={stylesDashboard.cardText}>Users</h2>
            <CustomButton
              styles={stylesDashboard.btn}
              title={"Manage"}
              onPress={() => navigation("/admin/manage-user")}
            />
          </div>
        </Col>
        <Col xl={3} lg={3} md={6} sm={12} xs={12}>
          <div className={stylesDashboard.card}>
            <StyleTwoTone htmlColor="#222222" sx={{ fontSize: 75 }} />
            <h2 className={stylesDashboard.cardText}>Events</h2>
            <CustomButton
              styles={stylesDashboard.btn}
              title={"Manage"}
              onPress={() => navigation("/admin/manage-events")}
            />
          </div>
        </Col>
        <Col xl={3} lg={3} md={6} sm={12} xs={12}>
          <div className={stylesDashboard.card}>
            <LocalActivityTwoTone htmlColor="#222222" sx={{ fontSize: 75 }} />
            <h2 className={stylesDashboard.cardText}>Tickets</h2>
            <CustomButton
              styles={stylesDashboard.btn}
              title={"Manage"}
              onPress={() => navigation("/admin/manage-tickets")}
            />
          </div>
        </Col>
        <Col xl={3} lg={3} md={6} sm={12} xs={12}>
          <div className={stylesDashboard.card}>
            <WidgetsTwoTone htmlColor="#222222" sx={{ fontSize: 75 }} />
            <h2 className={stylesDashboard.cardText}>Categories</h2>
            <CustomButton
              styles={stylesDashboard.btn}
              title={"Manage"}
              onPress={() => navigation("/admin/manage-categories")}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
