import React, { useState, useRef } from "react";
import { stylesProfile } from "../../css";
import { Row, Col, Image, Card } from "react-bootstrap";
import { FaceRounded } from "@mui/icons-material";
import { CustomButton } from "../../components";
import { showErrorToast, showSuccessToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { decryptData, convertDate } from "../../layouts/encryption/cryption";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CryptoJS from "crypto-js";

const Profile = () => {
  const [image, setImage] = useState([]);
  const [showImage, setShowImage] = useState(null);
  const [submit, setSubmit] = useState(null);
  const inputRef = useRef(null);
  const decrypt = decryptData();
  const formatedDob = convertDate(decrypt.user.dob);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Full name is required")
      .required("Full name is required"),
    passport: Yup.string()
      .matches(/^\d+$/, "Passport/ID must contain only numbers")
      .required("Passport/ID is required"),
    location: Yup.string()
      .min(5, "Location is required")
      .required("Location is required"),
    dob: Yup.string().required("Date of birth is required"),
    email: Yup.string().required("Email is required"),
  });

  const initialValues = {
    email: decrypt.user.email,
    username: decrypt.user.username,
    passport: decrypt.user.passport,
    location: decrypt.user.location,
    dob: formatedDob,
  };

  const handelInputClick = () => {
    inputRef.current.click();
  };

  const handelInputChange = (e) => {
    debugger;
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadFileToServer = async () => {
    try {
      if (image.length === 0) {
        showErrorToast({ message: "Please upload the picture!" });
      } else {
        const formData = new FormData();
        formData.append("files", image);

        const fileUploadResponse = await fetch(URL + "/admin/upload-file", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${decrypt.token}`,
          },
          body: formData,
        });

        if (!fileUploadResponse.ok) {
          showErrorToast({ message: fileUploadResponse.message });
          return;
        } else {
          const responseJson = await fileUploadResponse.json();
          if (responseJson.success) {
            const fileUrl = responseJson.data.fileUrls[0];
            setImage([]);
            const urlObject = {
              profile_img: fileUrl,
            };
            console.log(urlObject);
            handleSubmit(urlObject);
            showErrorToast({ message: "URL has been Generated!" });
          } else {
            showErrorToast({ message: "URL is not Generating!" });
          }
        }
      }
    } catch (err) {
      showErrorToast({ message: err });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(URL + "/update-profile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        showSuccessToast({ message: "Profile successfully update!" });
        setShowImage(null);
        decrypt.user.username = values.username;
        decrypt.user.location = values.location;
        const ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(decrypt),
          "22f3b23"
        ).toString();
        sessionStorage.setItem("usrData", ciphertext);
      } else {
        const errorData = await response.json();
        showErrorToast({
          message: errorData.message,
        });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={7} md={10} sm={12}>
        <Card className={stylesProfile.container}>
          <Card.Title className={stylesProfile.heading}>
            Manage Profile
          </Card.Title>
          <Row className="w-100">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submit === null ? null : handleSubmit}
              className="w-100"
            >
              <FormikForm className="d-flex align-items-center justify-content-evenly w-100">
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <div className={stylesProfile.inputSec}>
                    <label
                      htmlFor="username"
                      className={stylesProfile.inputTitle}
                    >
                      Full Name
                    </label>
                    <Field
                      type="text"
                      id="username"
                      name="username"
                      disabled={false}
                      className={stylesProfile.input}
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className={stylesProfile.errorMessage}
                    />
                  </div>
                  <div className={stylesProfile.inputSec}>
                    <label
                      className={stylesProfile.inputTitle}
                      htmlFor="location"
                    >
                      Location
                    </label>
                    <Field
                      type="text"
                      id="location"
                      name="location"
                      disabled={false}
                      className={stylesProfile.input}
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className={stylesProfile.errorMessage}
                    />
                  </div>
                  <div className={stylesProfile.inputSec}>
                    <label htmlFor="email" className={stylesProfile.inputTitle}>
                      Email
                    </label>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      disabled={true}
                      className={stylesProfile.input}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={stylesProfile.errorMessage}
                    />
                  </div>
                  <div className={stylesProfile.inputSec}>
                    <label
                      className={stylesProfile.inputTitle}
                      htmlFor="passport"
                    >
                      Passport/ID
                    </label>
                    <Field
                      type="text"
                      id="passport"
                      name="passport"
                      disabled={true}
                      className={stylesProfile.input}
                    />
                    <ErrorMessage
                      name="passport"
                      component="div"
                      className={stylesProfile.errorMessage}
                    />
                  </div>
                  <div className={stylesProfile.inputSec}>
                    <label className={stylesProfile.inputTitle} htmlFor="dob">
                      Date of Birth
                    </label>
                    <Field
                      type="text"
                      id="dob"
                      name="dob"
                      disabled={true}
                      className={stylesProfile.input}
                    />
                    <ErrorMessage
                      name="dob"
                      component="div"
                      className={stylesProfile.errorMessage}
                      placeholder="MM/DD/YYYY"
                      maxLength="10"
                    />
                  </div>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <div className={stylesProfile.imgSec}>
                    {showImage === null ? (
                      <FaceRounded />
                    ) : (
                      <Image
                        src={showImage}
                        alt={`selected-img`}
                        className={stylesProfile.img}
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    onChange={handelInputChange}
                    ref={inputRef}
                    style={{ display: "none" }}
                  />
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <CustomButton
                      title={"Upload Picture"}
                      onPress={handelInputClick}
                      styles={stylesProfile.profileBtn}
                    />
                    <CustomButton
                      title={"Update Picture"}
                      onPress={uploadFileToServer}
                      styles={stylesProfile.profileBtn}
                    />
                    <CustomButton
                      type={"submit"}
                      title="Save Changes"
                      styles={stylesProfile.profileBtn}
                      onPress={() => setSubmit("submit")}
                    />
                  </div>
                </Col>
              </FormikForm>
            </Formik>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
