import React from "react";
import { stylesUser } from "../../css";
import { Card } from "react-bootstrap";

const UserCard = ({
  id,
  fullName,
  email,
  location,
  dateOfBirth,
  member,
}) => {
  const apiDate = dateOfBirth;
  const timestamp = new Date(apiDate).getTime();
  const day = new Date(timestamp).getDay();
  const month = new Date(timestamp).getMonth() + 1;
  const year = new Date(timestamp).getFullYear();
  const formatedDate = `${day} / ${month} / ${year}`;

  return (
    <Card className={stylesUser.card} key={id}>
      <Card.Body className={stylesUser.body}>
        <div className={stylesUser.section}>
          <h2 className={stylesUser.heading}>Name:</h2>
          <h2 className={stylesUser.text}>{fullName}</h2>
        </div>
        <div className={stylesUser.section}>
          <h2 className={stylesUser.heading}>Email:</h2>
          <h2 className={stylesUser.text}>{email}</h2>
        </div>
        <div className={stylesUser.section}>
          <h2 className={stylesUser.heading}>Location:</h2>
          <h2 className={stylesUser.text}>{location}</h2>
        </div>
        <div className={stylesUser.section}>
          <h2 className={stylesUser.heading}>DOB:</h2>
          <h2 className={stylesUser.text}>{formatedDate}</h2>
        </div>
        <div className={stylesUser.section}>
          <h2 className={stylesUser.heading}>Account:</h2>
          <h2 className={stylesUser.text}>{member? "verified" : "unvarified"}</h2>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UserCard;