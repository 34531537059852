import React, { useState } from "react";
import { stylesAuth } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton, TextInput, CheckBox } from "../../components";
import { showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import CryptoJS from "crypto-js";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigation = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    debugger;
    try {
      const response = await fetch(URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...values, email: values.email.toLowerCase() }),
      });
      if (response.ok) {
        const responseData = await response.json();
        const data = responseData.data;
        console.log(data);
        if (data.user.user_type === "user") {
          const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            "22f3b23"
          ).toString();
          sessionStorage.setItem("usrData", ciphertext);
          navigation("/events");
        } else {
          const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            "22f3b23"
          ).toString();
          sessionStorage.setItem("usrData", ciphertext);
          navigation("/admin/dashboard");
        }
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesAuth.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesAuth.heading}>Login</Card.Title>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <FormikForm className="w-100">
                <TextInput
                  target="email"
                  type="text"
                  label="Email"
                  placeholder="Email"
                />
                <TextInput
                  target="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  placeholder="Password"
                />
                <CheckBox
                  label="Show Password"
                  onPress={() => setShowPassword(!showPassword)}
                />
                <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-between my-4 p-0 mx-0">
                  <Card.Text
                    className={stylesAuth.linkTitle}
                    onClick={() => navigation("/signup")}
                  >
                    Create an account
                  </Card.Text>
                  <Card.Text
                    className={stylesAuth.linkTitle}
                    onClick={() => navigation("/forgot-password")}
                  >
                    Forgot Password
                  </Card.Text>
                </Card.Body>
                <div className={stylesAuth.btnCnt}>
                  <CustomButton
                    type="submit"
                    title="Login"
                    styles={stylesAuth.authBtn}
                  />
                  <CustomButton
                    title="Login with Google"
                    styles={stylesAuth.authBtn}
                    onPress={() => navigation("/google-login")}
                  />
                  <CustomButton
                    title="Login with Facebook"
                    styles={stylesAuth.authBtn}
                  />
                </div>
              </FormikForm>
            </Formik>
            <Card.Text
              className={`text-center ${stylesAuth.linkTitle}`}
              onClick={() => navigation("/verify-email")}
            >
              Want to verify your account?
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
