import React, { useEffect, useState } from "react";
import { stylesManageTickets } from "../../css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TicketItem, CustomButton } from "../../components";
import { decryptData } from "../../layouts/encryption/cryption";
import { showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import NotFoundImg from "../../assets/images/folder.png";

const ManageTickets = () => {
  const navigation = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const decrypt = decryptData();

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      // debugger;
      const response = await fetch(URL + "/get-tickets", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const ticketsData = data.data;
        if (ticketsData.length > 0) {
          setTickets(ticketsData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        const error = await response.json();
        showErrorToast({ message: error.message });
        // Handle the error response
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Container fluid className={stylesManageTickets.container}>
      <div className={stylesManageTickets.header}>
        <h2 className={stylesManageTickets.title}>Manage Tickets</h2>
        <CustomButton
          title={"Go Back"}
          onPress={() => navigation("/admin/dashboard")}
        />
      </div>
      {loading ? (
        <Spinner
          animation="border"
          variant="dark"
          role="status"
          className={stylesManageTickets.margin}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : tickets.length > 0 ? (
        <Row className={stylesManageTickets.eventSec}>
          {tickets.map((item) => (
            <Col xl={3} lg={4} md={6} sm={12} xs={12}>
              <TicketItem
                showTicket="reserved"
                address={item.event_id.address}
                city={item.event_id.city}
                state={item.event_id.state}
                startingDate={item.event_id.start_date}
                startingTime={item.event_id.start_time}
                endingDate={item.event_id.end_date}
                endingTime={item.event_id.end_time}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div className={stylesManageTickets.oopsCnt}>
          <img
            className={stylesManageTickets.oopsImg}
            src={NotFoundImg}
            alt="img-not-found"
          />
          <h2 className={stylesManageTickets.oopsText}>No Reserved Tickets</h2>
        </div>
      )}
    </Container>
  );
};

export default ManageTickets;
