import React, { useState, useEffect } from "react";
import { stylesUseTicket } from "../../css";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { CustomButton } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { decryptData } from "../../layouts/encryption/cryption";

const UseTicketPage = () => {
  const [next, setNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState();
  const decrypt = decryptData();
  const navigation = useNavigate();
  const { ticket_id } = useParams();

  useEffect(() => {
    if (ticket_id) {
      fetchTickets(ticket_id);
    }
  }, []);

  const fetchTickets = async (id) => {
    try {
      const response = await fetch(URL + "/get-tickets", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const ticketsData = data.data;
        const filteredTicket = ticketsData.filter((item) => item._id === id);
        if (filteredTicket) {
          setExpired(filteredTicket[0].is_expired);
          setLoading(true);
        }
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleContinue = async () => {
    const shouldDelete = window.confirm(
      "Are you sure you want to use your ticket?"
    );
    if (!shouldDelete) {
      navigation(-1);
    } else {
      try {
        const response = await fetch(URL + "/use-ticket", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${decrypt.token}`,
          },
          body: JSON.stringify({ ticket_id }),
        });
        if (response.ok) {
          setNext(false);
        } else {
          const errorData = await response.json();
          showErrorToast({ message: errorData.message });
        }
      } catch (err) {
        showErrorToast({ message: "Error: " + err });
      }
    }
  };

  const handleCancel = () => {
    navigation(-1);
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      {loading ? (
        <Col lg={4} md={6} sm={12}>
          <Card className={stylesUseTicket.container}>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
              <Card.Title className={stylesUseTicket.heading}>
                Use Ticket
              </Card.Title>
              {expired ? (
                <>
                  <h2 className={stylesUseTicket.subHeading}>
                    Oops, your ticket is already expired.
                  </h2>
                  <CustomButton
                    styles={stylesUseTicket.btn}
                    title={"Finish"}
                    onPress={() => navigation("/events")}
                  />
                </>
              ) : next ? (
                <>
                  <h2 className={stylesUseTicket.subHeading}>
                    Please confrim do you want to use your ticket.
                  </h2>
                  <CustomButton
                    styles={stylesUseTicket.btn}
                    title={"Confirm"}
                    onPress={handleContinue}
                  />
                  <CustomButton
                    styles={stylesUseTicket.btn}
                    title={"Cancel"}
                    onPress={handleCancel}
                  />
                  <h2 className={stylesUseTicket.alertText}>
                    Note: When you click on the button. Your ticket will be
                    expired.
                  </h2>
                </>
              ) : (
                <>
                  <h2 className={stylesUseTicket.subHeading}>
                    Thank you for using our services. You may now enjoy your
                    event.
                  </h2>
                  <CustomButton
                    styles={stylesUseTicket.btn}
                    title={"Finish"}
                    onPress={() => navigation("/events")}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      ) : (
        <Spinner animation="border" variant="dark" />
      )}
    </Row>
  );
};

export default UseTicketPage;
