import React, { useState, useEffect } from "react";
import { stylesEvent } from "../../css";
import { convertDate, convertTime } from "../../layouts/encryption/cryption";
import CarouselItem from "./CarouselItem";

const CustomCard = ({
  id,
  media,
  state,
  city,
  location,
  startingDate,
  startingTime,
  isFree,
  price,
  onPress,
}) => {
  const [formatedST, setFormatedST] = useState();
  const [formatedSD, setFormatedSD] = useState();

  useEffect(() => {
    if (startingDate || startingTime) {
      const FST = convertTime(startingTime);
      setFormatedST(FST);
      const FSD = convertDate(startingDate);
      setFormatedSD(FSD);
    }
  }, []);

  return (
    <div className={stylesEvent.cardSec} key={id}>
      <CarouselItem type="single" array={media} onPressPicture={onPress} extraStyle={stylesEvent.carousel} />
      <div className={stylesEvent.cardBody} onClick={onPress}>
        <h2 className={stylesEvent.boldText}>
          {city}, {state}
        </h2>
        <h2 className={stylesEvent.lightText}>{location}</h2>
        <h2 className={stylesEvent.lightText}>
          {formatedSD}, {formatedST}
        </h2>
        <div className={stylesEvent.cardHead}>
          <h2 className={stylesEvent.boldText}>
            {price === 0 ? "Promotion" : "$" + price}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
