import React, { useState, useEffect } from "react";
import { stylesManageEvents } from "../../css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EventItem, CustomButton } from "../../components";
import { encryptId } from "../../layouts/encryption/cryption";
import { showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import NotFoundImg from "../../assets/images/folder.png";

const ManageEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      await fetch(URL + "/get-events").then((result) => {
        result.json().then((resp) => {
          const data = resp.data;
          if (data) {
            setEvents(data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      });
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleSubmit = (value) => {
    const encryptedId = encryptId(value);
    console.log(encryptedId);
    if (encryptedId) {
      sessionStorage.setItem("encryptedId", encryptedId);
      navigation("/admin/event-details");
    }
  };

  return (
    <Container fluid className={stylesManageEvents.container}>
      <div className={stylesManageEvents.header}>
        <h2 className={stylesManageEvents.title}>Manage Events</h2>
        <CustomButton
          title={"Go Back"}
          onPress={() => navigation("/admin/dashboard")}
        />
      </div>
      {loading ? (
        <Spinner
          animation="border"
          variant="dark"
          role="status"
          className={stylesManageEvents.margin}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : events.length > 0 ? (
        <Row className={stylesManageEvents.eventSec}>
          {events.map((item) => (
            <Col xl={3} lg={4} md={6} sm={12} xs={12}>
              <EventItem
                id={item?._id}
                media={item?.image}
                city={item?.city}
                state={item?.state}
                location={item?.address}
                startingDate={item?.start_date}
                startingTime={item?.start_time}
                isFree={item?.is_membership}
                price={item?.ticket_price}
                onPress={() => handleSubmit(item?._id)}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div className={stylesManageEvents.oopsCnt}>
          <img
            className={stylesManageEvents.oopsImg}
            src={NotFoundImg}
            alt="img-not-found"
          />
          <h2 className={stylesManageEvents.oopsText}>No Reserved Events</h2>
        </div>
      )}
    </Container>
  );
};

export default ManageEvents;
