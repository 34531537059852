import React, { useEffect, useState } from "react";
import { stylesTicketDetails } from "../../css";
import { useSearchParams } from "react-router-dom";
import { CustomButton } from "../../components";
import copy from "copy-to-clipboard";
import { Container, Image, Spinner } from "react-bootstrap";
import { showErrorToast, showSuccessToast } from "../../components/tost/tost";
import { DoneRounded } from "@mui/icons-material";
import {
  convertDate,
  convertTime,
  decryptData,
  decryptId,
} from "../../layouts/encryption/cryption";

import { URL } from "../../utils/ApisLink";

const TicketDetails = () => {
  const [formatedST, setFormatedST] = useState();
  const [formatedET, setFormatedET] = useState();
  const [formatedSD, setFormatedSD] = useState();
  const [formatedED, setFormatedED] = useState();
  const [eventDetails, setEventDetails] = useState({});
  const [ticketNumber, setTicketNumber] = useState();
  const [expired, setExpired] = useState();
  const [qrCode, setqrCode] = useState();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get("ticket_id");
  const decrypt = decryptData();

  useEffect(() => {
    const ticket_id = decryptId(searchId);
    if (ticket_id) {
      fetchTickets(ticket_id);
    }
  }, []);

  const fetchTickets = async (id) => {
    try {
      const response = await fetch(URL + "/get-tickets", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const ticketsData = data.data;
        const filteredTicket = ticketsData.filter((item) => item._id === id);
        if (filteredTicket) {
          setExpired(filteredTicket[0].is_expired);
          setTicketNumber(filteredTicket[0]._id);
          setqrCode(filteredTicket[0].qr_code);
          setEventDetails(filteredTicket[0].event_id);
          formateDateAndTime(filteredTicket[0].event_id);
          setLoading(false);
        }
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const formateDateAndTime = (data) => {
    const FST = convertTime(data.start_time);
    setFormatedST(FST);
    const FET = convertTime(data.end_time);
    setFormatedET(FET);
    const FSD = convertDate(data.start_date);
    setFormatedSD(FSD);
    const FED = convertDate(data.end_date);
    setFormatedED(FED);
  };

  const handleCopyURL = () => {
    const url = window.location.href;
    const copyURL = copy(url);
    if (copyURL) {
      showSuccessToast({ message: "URL copied successfully!" });
    }
  };

  return (
    <Container fluid className={stylesTicketDetails.section}>
      {!loading ? (
        <Container fluid className={stylesTicketDetails.container}>
          <Container fluid className={stylesTicketDetails.ImageSec}>
            <div className={stylesTicketDetails.QRSec}>
              <h2 className={stylesTicketDetails.ticketId}>
                {eventDetails.title}
              </h2>
              <div
                className={
                  expired
                    ? stylesTicketDetails.QRDone
                    : stylesTicketDetails.QRBackground
                }
              >
                {expired ? (
                  <>
                    <DoneRounded
                      className={stylesTicketDetails.doneIcon}
                      sx={{ fontSize: 50 }}
                    />
                    <h2 className={stylesTicketDetails.done}>Completed</h2>
                  </>
                ) : (
                  <Image
                    src={qrCode}
                    className={stylesTicketDetails.QRCode}
                    alt="qr-code"
                  />
                )}
              </div>
              <h2
                className={stylesTicketDetails.ticketId}
              >{`#${ticketNumber}`}</h2>
            </div>
          </Container>
          <Container fluid className={stylesTicketDetails.hiddenSec}>
            <h2 className={stylesTicketDetails.addressText}>{`${
              eventDetails.address
            }${" "}${eventDetails.city}${" "}${eventDetails.state}`}</h2>
            <div className={stylesTicketDetails.deviderSec}>
              <div className={stylesTicketDetails.dividerLine}></div>
              <h2 className={stylesTicketDetails.rowText}>Check In</h2>
              <div className={stylesTicketDetails.dividerLine}></div>
            </div>
            <div className={stylesTicketDetails.row}>
              <h2 className={stylesTicketDetails.rowText}>Date:</h2>
              <h2 className={stylesTicketDetails.rowText}>{formatedSD}</h2>
            </div>
            <div className={stylesTicketDetails.row}>
              <h2 className={stylesTicketDetails.rowText}>Time:</h2>
              <h2 className={stylesTicketDetails.rowText}>{formatedST}</h2>
            </div>
            <div className={stylesTicketDetails.deviderSec}>
              <div className={stylesTicketDetails.dividerLine}></div>
              <h2 className={stylesTicketDetails.rowText}>Check Out</h2>
              <div className={stylesTicketDetails.dividerLine}></div>
            </div>
            <div className={stylesTicketDetails.row}>
              <h2 className={stylesTicketDetails.rowText}>Date:</h2>
              <h2 className={stylesTicketDetails.rowText}>{formatedED}</h2>
            </div>
            <div className={stylesTicketDetails.row}>
              <h2 className={stylesTicketDetails.rowText}>Time:</h2>
              <h2 className={stylesTicketDetails.rowText}>{formatedET}</h2>
            </div>
            {expired ? null : (
              <CustomButton
                title="Copy Link"
                styles={stylesTicketDetails.btn}
                onPress={handleCopyURL}
              />
            )}
          </Container>
        </Container>
      ) : (
        <Spinner animation="border" variant="dark" />
      )}
    </Container>
  );
};

export default TicketDetails;
