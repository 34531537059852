import React, { useState } from "react";
import { stylesAddSubscription } from "../../css";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton, TextInput } from "../../components";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { decryptData } from "../../layouts/encryption/cryption";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddSubscription = () => {
  const navigation = useNavigate();
  const decrypt = decryptData();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Plan name is required"),
    amount: Yup.string().required("Plan price is required"),
    duration: Yup.string().required("Plan duration is required"),
  });

  const initialValues = {
    name: "",
    amount: "",
    duration: "",
  };

  const handleSubmit = async (values) => {
    try {
      // debugger;
      const response = await fetch(URL + "/create-plan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        showSuccessToast({ message: "The plan has been generated!" });
      } else {
        const errorData = await response.json();
        showErrorToast({
          message: errorData.message,
        });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
    showSuccessToast({ message: values });
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesAddSubscription.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesAddSubscription.heading}>
              Add Subscription Plan
            </Card.Title>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <FormikForm className="w-100">
                <TextInput
                  target="name"
                  type="text"
                  label="Plan Name"
                  placeholder="Plan Name"
                />
                <TextInput
                  target="amount"
                  type="number"
                  label="Price"
                  placeholder="Price"
                />
                <TextInput
                  target="duration"
                  type="text"
                  label="Duration"
                  placeholder="Duration"
                />
                <CustomButton
                  styles={stylesAddSubscription.btn}
                  type="submit"
                  title="Send"
                />
              </FormikForm>
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AddSubscription;
