import { Navigate } from "react-router-dom";
import {
  HomePage,
  EventDetailsPage,
  ProfilePage,
  MyTicketsPage,
  MyEventsPage,
  HostEventPage,
  AdminDashboardPage,
  ManageCategoryPage,
  ManageUsersPage,
  LogoutPage,
  LoginPage,
  GoogleLoginPage,
  SignupPage,
  EmailVerifyPage,
  ForgotPasswordPage,
  UpdateEmailPage,
  ManageEventsPage,
  ManageTicketsPage,
  MembershipPage,
  GenearteCouponPage,
  ApplyCouponPage,
  AddSubscriptionPage,
  ReservationPage,
  ContactUsPage,
  AboutUsPage,
  // FAQPage,
  ConfirmationPage,
  PrivacyPolicyPage,
  TermsConditionPage,
  UseTicketPage,
  TicketDetails,
} from "./pages";

export const authRoutes = [
  {
    name: "Home",
    path: "home",
    component: <HomePage />,
  },
  {
    name: "Event Details",
    path: "details",
    component: <EventDetailsPage />,
  },
  {
    name: "Contact Us",
    path: "contact",
    component: <ContactUsPage />,
  },
  {
    name: "About Us",
    path: "about",
    component: <AboutUsPage />,
  },
  {
    name: "Login",
    path: "login",
    component: <LoginPage />,
  },
  {
    name: "Google Login",
    path: "google-login",
    component: <GoogleLoginPage />,
  },
  {
    name: "Signup",
    path: "signup",
    component: <SignupPage />,
  },
  {
    name: "Email Verification",
    path: "verify-email",
    component: <EmailVerifyPage />,
  },
  {
    name: "Forgot Password",
    path: "forgot-password",
    component: <ForgotPasswordPage />,
  },
  {
    name: "Update Email",
    path: "update-email",
    component: <UpdateEmailPage />,
  },
  {
    name: "Privacy Policy",
    path: "policies",
    component: <PrivacyPolicyPage />,
  },
  {
    name: "Terms Conditions",
    path: "terms",
    component: <TermsConditionPage />,
  },
  {
    name: "Unavailable",
    path: "*",
    component: <Navigate to="/login" />,
  }
]

export const userRoutes = [
  {
    name: "Events",
    path: "events",
    component: <HomePage />,
  },
  {
    name: "Event Details",
    path: "event-details",
    component: <EventDetailsPage />,
  },
  {
    name: "Profile",
    path: "profile",
    component: <ProfilePage />,
  },
  {
    name: "My Tickets",
    path: "my-tickets",
    component: <MyTicketsPage />,
  },
  {
    name: "My Events",
    path: "my-events",
    component: <MyEventsPage />,
  },
  {
    name: "Host Event",
    path: "host-event",
    component: <HostEventPage />,
  },
  {
    name: "Apply Coupon",
    path: "avail-coupon",
    component: <ApplyCouponPage />,
  },
  {
    name: "Reservation",
    path: "reservation",
    component: <ReservationPage />,
  },
  {
    name: "Membership",
    path: "subscriptions",
    component: <MembershipPage />,
  },
  {
    name: "Contact Us",
    path: "contact-us",
    component: <ContactUsPage />,
  },
  {
    name: "About Us",
    path: "about-us",
    component: <AboutUsPage />,
  },
  // {
  //   name: "FAQ Page",
  //   path: "help-support",
  //   component: <FAQPage />,
  // },
  {
    name: "Privacy Policy",
    path: "privacy-policy",
    component: <PrivacyPolicyPage />,
  },
  {
    name: "Terms Conditions",
    path: "terms-condition",
    component: <TermsConditionPage />,
  },
  {
    name: "Confirmation Page",
    path: "confirmation",
    component: <ConfirmationPage />,
  },
  {
    name: "Use Ticket Page",
    path: "ticket/:ticket_id",
    component: <UseTicketPage />,
  },
  {
    name: "Ticket Details",
    path: "ticket-details",
    component: <TicketDetails />,
  },
  {
    name: "Logout",
    path: "logout",
    component: <LogoutPage />,
  },
  {
    name: "Unavailable",
    path: "*",
    component: <Navigate to="/events" />,
  }
]

export const adminRoutes = [
  {
    name: "Admin Dashboard",
    path: "dashboard",
    component: <AdminDashboardPage />,
  },
  {
    name: "Manage Categories",
    path: "manage-categories",
    component: <ManageCategoryPage />,
  },
  {
    name: "Manage Users",
    path: "manage-user",
    component: <ManageUsersPage />,
  },
  {
    name: "Manage Events",
    path: "manage-events",
    component: <ManageEventsPage />,
  },
  {
    name: "Event Details",
    path: "event-details",
    component: <EventDetailsPage />,
  },
  {
    name: "Manage Tickets",
    path: "manage-tickets",
    component: <ManageTicketsPage />,
  },
  {
    name: "Generate Coupon",
    path: "manage-coupon",
    component: <GenearteCouponPage />,
  },
  {
    name: "Add Sunbscription",
    path: "add-sunbscription",
    component: <AddSubscriptionPage />,
  },
  {
    name: "Logout",
    path: "logout",
    component: <LogoutPage />,
  },
  {
    name: "Unavailable",
    path: "*",
    component: <Navigate to="/admin/dashboard" />,
  }
]