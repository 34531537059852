import React from "react";
import { stylesCustomButton } from "../../css";
import { Button } from "react-bootstrap";

const CustomButton = ({ id, onPress, pressed, title, type, disabled, styles }) => {
  return (
    <Button
      key={id}
      type={type}
      disabled={disabled}
      variant="dark"
      onClick={onPress}
      className={pressed ? `${stylesCustomButton.activeBtn} ${styles}` : `${stylesCustomButton.btn} ${styles}`}
    >
      <h2 className={stylesCustomButton.label}>{title}</h2>
    </Button>
  );
};

export default CustomButton;