import React from "react";
import { stylesInput } from "../../css";
import { Field, ErrorMessage } from "formik";

const MessageInput = ({ target, type, label, placeholder, maxLength }) => {
  return (
    <div className={stylesInput.section}>
      <label htmlFor={target} className={stylesInput.title}>
        {label}
      </label>
      <Field
        as={type}
        id={target}
        name={target}
        placeholder={placeholder}
        maxLength={maxLength}
        multiple
        className={`${stylesInput.input} ${stylesInput.msgInput}`}
      />
      <ErrorMessage
        name={target}
        component="div"
        className={stylesInput.errMsg}
      />
    </div>
  );
};

export default MessageInput;
