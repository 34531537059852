import React, { useState } from "react";
import { stylesLayout } from "../../css";
import { Container } from "react-bootstrap";
import { Outlet, Navigate } from "react-router";
import { Footer, AdminNavbar, GotoButton } from "../../components";
import { decryptData } from "../encryption/cryption";

const AdminLayout = () => {
  const decrypt = decryptData();
  if (!decrypt) {
    return <Navigate to="/login" />;
  } else if (decrypt.user.user_type === "admin") {
    return (
      <Container fluid className={stylesLayout.container}>
        <AdminNavbar />
        <Outlet />
        <Footer />
        <GotoButton />
      </Container>
    );
  } else {
    return <Navigate to="/events" />;
  }
};

export default AdminLayout;
