import React, { useEffect, useState } from "react";
import { stylesGoto } from "../../css";
import { ArrowUpward, ArrowUpwardRounded } from "@mui/icons-material";

const GotoTop = () => {
  const [isVissible, setIsVissible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setIsVissible(true);
      } else {
        setIsVissible(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return isVissible ? (
    <div className={stylesGoto.button} onClick={scrollUp}>
      <ArrowUpward htmlColor="#ffffff" fontSize="small" />
    </div>
  ) : null;
};

export default GotoTop;