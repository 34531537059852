import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthLayout, AdminLayout, UserLayout } from "../layouts/index";
import { authRoutes, adminRoutes, userRoutes } from "../routes";
import { SplashScreen } from "../pages";

const MainRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<SplashScreen />} />
      <Route element={<AuthLayout />}>
        {authRoutes.map((route, key) => {
          return (
            <Route
              exact
              path={"/" + route.path}
              element={route.component}
              key={key}
            />
          );
        })}
      </Route>
      <Route element={<AdminLayout />}>
        {adminRoutes.map((route, key) => {
          return (
            <Route
              exact
              path={"/admin/" + route.path}
              element={route.component}
              key={key}
            />
          );
        })}
      </Route>
      <Route element={<UserLayout />}>
        {userRoutes.map((route, key) => {
          return (
            <Route
              exact
              path={"/" + route.path}
              element={route.component}
              key={key}
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default MainRouter;
