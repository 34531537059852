import React, { useState, useEffect } from "react";
import { stylesManageUsers } from "../../css";
import { Container, Col, Row, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DeleteForeverRounded } from "@mui/icons-material";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { UserItem, CustomButton } from "../../components";
import { decryptData } from "../../layouts/encryption/cryption";

const ManageUsers = () => {
  const navigation = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const decrypt = decryptData();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      await fetch(URL + "/all-users", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
      }).then((result) => {
        result.json().then((resp) => {
          setUsers(resp.data);
          setLoading(false);
        });
      });
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  //delete user
  const handleDelete = async (id, username) => {
    debugger;
    // Ask for user confirmation
    const shouldDelete = window.confirm(
      "Are you sure you want to delete " + username
    );
    if (!shouldDelete) {
      return; // If the user cancels the confirmation, do nothing
    }
    try {
      setLoading(true);
      const response = await fetch(URL + `/delete-user`, {
        method: "POST", // Use the DELETE method
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify({ userId: id }),
      });
      if (response.ok) {
        fetchUsers();
        showSuccessToast({ message: "User deleted successfully!" });
        // After successful deletion, you may want to update the user list by calling fetchUsers again
      } else {
        showErrorToast({ message: response.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Container fluid className={stylesManageUsers.container}>
      <div className={stylesManageUsers.header}>
        <h2 className={stylesManageUsers.title}>Manage Users</h2>
        <CustomButton
          title={"Go Back"}
          onPress={() => navigation("/admin/dashboard")}
        />
      </div>
      {loading ? (
        <Spinner
          animation="border"
          variant="dark"
          role="status"
          className={stylesManageUsers.margin}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Row className={stylesManageUsers.eventSec}>
          {users.map((user) => (
            <Col xl={3} lg={4} md={6} sm={12}>
              <UserItem
                id={user._id}
                fullName={user.username}
                email={user.email}
                location={user.location}
                dateOfBirth={user.dob}
                member={user.is_complete}
              />
              <CustomButton
                styles={stylesManageUsers.deleteBtn}
                title={"Delete"}
                onPress={() => handleDelete(user._id, user.username)}
              />
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default ManageUsers;
