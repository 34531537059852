import React from "react";
import { stylesInput } from "../../css";
import { Form } from "react-bootstrap";

const CkeckBox = ({ label, onPress, isChecked }) => {
  return (
    <div className={stylesInput.checkSection}>
      <Form.Check
        className={stylesInput.check}
        onClick={onPress}
        checked={isChecked}
      />
      <label className={stylesInput.checkTitle}>{label}</label>
    </div>
  );
};

export default CkeckBox;
