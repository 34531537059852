import React, { useState } from "react";
import { stylesAdminNavbar } from "../../css";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  CloseRounded,
  LocalActivityRounded,
  LogoutRounded,
  SortRounded,
  StyleRounded,
  Groups2Rounded,
  DashboardRounded,
  CardGiftcardRounded,
  WidgetsRounded,
  WorkspacePremiumRounded,
} from "@mui/icons-material";
import Logo from "../../assets/images/logo.png";

const AdminNavbar = () => {
  const [show, setShow] = useState(false);
  const navigation = useNavigate();

  const handleHide = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={stylesAdminNavbar.navbar}
        >
          <Container fluid>
            <Image
              src={Logo}
              className={stylesAdminNavbar.brandImage}
              onClick={() => navigation("/admin/dashboard")}
            />
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className={stylesAdminNavbar.toggleButton}
              onClick={handleShow}
            >
              <SortRounded
                className={stylesAdminNavbar.toggleIcon}
                sx={{ fontSize: 28 }}
              />
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              scroll={true}
              backdrop={true}
              show={show}
              onHide={handleHide}
              className={stylesAdminNavbar.navbarMenu}
            >
              <Offcanvas.Header className={stylesAdminNavbar.menuHead}>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className={stylesAdminNavbar.userName}
                >
                  Dashboard
                </Offcanvas.Title>
                <CloseRounded
                  sx={{ fontSize: 32 }}
                  className={stylesAdminNavbar.closeBtn}
                  onClick={handleHide}
                />
              </Offcanvas.Header>
              <Offcanvas.Body
                className={`w-100 ${stylesAdminNavbar.navScrollbar}`}
              >
                <Nav className="justify-content-center flex-grow-1 w-100">
                  <Nav.Link
                    href="/admin/dashboard"
                    className={stylesAdminNavbar.nav}
                  >
                    Dashboard
                    <DashboardRounded className={stylesAdminNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link
                    href="/admin/manage-user"
                    className={stylesAdminNavbar.nav}
                  >
                    Manage Users
                    <Groups2Rounded className={stylesAdminNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link
                    href="/admin/manage-tickets"
                    className={stylesAdminNavbar.nav}
                  >
                    Manage Tickets
                    <LocalActivityRounded
                      className={stylesAdminNavbar.navIcon}
                    />
                  </Nav.Link>
                  <Nav.Link
                    href="/admin/manage-events"
                    className={stylesAdminNavbar.nav}
                  >
                    Manage Events
                    <StyleRounded className={stylesAdminNavbar.navIcon} />
                  </Nav.Link>
                  <div className={stylesAdminNavbar.divider}></div>
                  <Nav.Link
                    href="/admin/manage-coupon"
                    className={stylesAdminNavbar.nav}
                  >
                    Genearte Coupon
                    <CardGiftcardRounded
                      className={stylesAdminNavbar.navIcon}
                    />
                  </Nav.Link>
                  <Nav.Link
                    href="/admin/manage-categories"
                    className={stylesAdminNavbar.nav}
                  >
                    Manage Category
                    <WidgetsRounded className={stylesAdminNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link
                    href="/admin/add-sunbscription"
                    className={stylesAdminNavbar.nav}
                  >
                    Add Plan
                    <WorkspacePremiumRounded
                      className={stylesAdminNavbar.navIcon}
                    />
                  </Nav.Link>
                  <div className={stylesAdminNavbar.divider}></div>
                  <Nav.Link
                    href="/admin/logout"
                    className={stylesAdminNavbar.logoutBtn}
                  >
                    Logout
                    <LogoutRounded className={stylesAdminNavbar.logoutIcon} />
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default AdminNavbar;
