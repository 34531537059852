import React, { useState, useEffect } from "react";
import { stylesAuth } from "../../css";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components";
import { useGoogleLogin } from "@react-oauth/google";
import { showErrorToast, showSuccessToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CryptoJS from "crypto-js";

const GoogleLogin = () => {
  const [userEmail, setUserEmail] = useState();
  const [userName, setUserName] = useState();
  const [socialId, setSocialId] = useState();
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();

  const calculateAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const validationSchema = Yup.object().shape({
    passport: Yup.string()
      .matches(/^\d+$/, "Passport/ID must contain only numbers")
      .min(3, "Passport/ID is required")
      .required("Passport/ID is required"),
    location: Yup.string()
      .min(5, "Location is required")
      .required("Location is required"),
    dob: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
        "Date of birth must be in MM/DD/YYYY format"
      )
      .test("age", "Must be at least 21 years old", function (dob) {
        const age = calculateAge(dob);
        return age >= 21;
      })
      .required("Date of birth is required"),
  });

  const initialValues = {
    passport: "",
    location: "",
    dob: "",
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetchCredentials(codeResponse.access_token);
    },
    onError: () => {
      console.error("Google login failed");
    },
  });

  const fetchCredentials = async (token) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        handleCheckEmail(data);
      } else {
        showErrorToast({ message: "HTTP error! Status:" + response.status });
        return response.json();
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleCheckEmail = async (values) => {
    try {
      const response = await fetch(URL + "/check-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values.email.toLowerCase() }),
      });
      if (response.ok) {
        setUserName(values.name);
        setUserEmail(values.email);
        setSocialId(values.id);
        setLoading(false);
      } else {
        handleSocialLogin(values);
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleSocialLogin = async (values) => {
    try {
      const loginData = {
        username: values.name,
        email: values.email,
        social_id: values.id,
        social_type: "googlee",
      };
      console.log(loginData);
      const response = await fetch(URL + "/social-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });
      if (response.ok) {
        const responseData = await response.json();
        const data = responseData.data;
        const ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(data),
          "22f3b23"
        ).toString();
        sessionStorage.setItem("usrData", ciphertext);
        showSuccessToast({ message: "User already Resgistered!" });
        if (data.subscription === null) {
          navigation("/subscriptions");
        } else {
          navigation("/events");
        }
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleSocialSignup = async (values) => {
    try {
      const signupData = {
        username: userName,
        email: userEmail,
        social_id: socialId,
        social_type: "googlee",
        passport: values.passport,
        location: values.location,
        dob: values.dob,
      };
      const response = await fetch(URL + "/social-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signupData),
      });
      if (response.ok) {
        const responseData = await response.json();
        const data = responseData.data;
        const ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(data),
          "22f3b23"
        ).toString();
        sessionStorage.setItem("usrData", ciphertext);
        navigation("/subscriptions");
        showSuccessToast({ message: "User Successfully Registered!" });
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesAuth.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesAuth.heading}>
              Login with Google
            </Card.Title>
            {!loading ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSocialSignup}
                className="w-100"
              >
                <FormikForm className="w-100">
                  <div className={stylesAuth.inputSec}>
                    <label className={stylesAuth.inputTitle} htmlFor="passport">
                      Passport/ID
                    </label>
                    <Field
                      type="text"
                      id="passport"
                      name="passport"
                      className={stylesAuth.input}
                    />
                    <ErrorMessage
                      name="passport"
                      component="div"
                      className={stylesAuth.errorMessage}
                    />
                  </div>
                  <div className={stylesAuth.inputSec}>
                    <label className={stylesAuth.inputTitle} htmlFor="location">
                      Location
                    </label>
                    <Field
                      type="text"
                      id="location"
                      name="location"
                      className={stylesAuth.input}
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className={stylesAuth.errorMessage}
                    />
                  </div>
                  <div className={stylesAuth.inputSec}>
                    <label className={stylesAuth.inputTitle} htmlFor="dob">
                      Date of Birth
                    </label>
                    <Field
                      type="text"
                      id="dob"
                      name="dob"
                      placeholder="MM/DD/YYYY"
                      maxLength="10"
                      className={stylesAuth.input}
                    />
                    <ErrorMessage
                      name="dob"
                      component="div"
                      className={stylesAuth.errorMessage}
                    />
                  </div>
                  <div className={stylesAuth.btnCnt}>
                    <CustomButton
                      type="submit"
                      title={"Continue"}
                      styles={stylesAuth.authBtn}
                    />
                  </div>
                </FormikForm>
              </Formik>
            ) : (
              <Spinner animation="border" variant="dark" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default GoogleLogin;
