import React, { useState, useEffect } from "react";
import { stylesMyTickets } from "../../css";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { TicketItem, CustomButton } from "../../components";
import { showErrorToast } from "../../components/tost/tost";
import { decryptData, encryptId } from "../../layouts/encryption/cryption";
import { URL } from "../../utils/ApisLink";
import NotFoundImg from "../../assets/images/folder.png";

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const decrypt = decryptData();
  const userId = decrypt.user._id;

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      // debugger;
      const response = await fetch(URL + "/get-tickets", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const ticketsData = data.data;
        const filteredTickets = ticketsData.filter(
          (item) => item.paid_by._id === userId
        );
        if (filteredTickets.length > 0) {
          filteredTickets.forEach((item) => {
            const id = encryptId(item._id);
            item._id = id;
          });
          setTickets(filteredTickets);
        }
        setLoading(false);
      } else {
        const error = await response.json();
        showErrorToast({ message: error.message });
        // Handle the error response
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const onClick = (value) => {
    if (value) {
      navigation({
        pathname: "/ticket-details",
        search: `?ticket_id=${value}`,
      });
    }
  };

  return (
    <Container fluid className={stylesMyTickets.container}>
      {!loading ? (
        <Row className={`${stylesMyTickets.eventSec} mb-4`}>
          {tickets.length === 0 ? (
            <div className={stylesMyTickets.oopsCnt}>
              <img
                className={stylesMyTickets.oopsImg}
                src={NotFoundImg}
                alt="img-not-found"
              />
              <h2 className={stylesMyTickets.oopsText}>No Reserved Tickets</h2>
              <CustomButton
                title="Explore Events"
                onPress={() => navigation("/events")}
              />
            </div>
          ) : (
            tickets.map((item) => (
              <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                <TicketItem
                  showTicket="reserved"
                  codeImg={
                    item.is_expired
                      ? require("../../assets/images/checked-code.png")
                      : item.qr_code
                  }
                  address={item.event_id.address}
                  city={item.event_id.city}
                  state={item.event_id.state}
                  startingDate={item.event_id.start_date}
                  startingTime={item.event_id.start_time}
                  endingDate={item.event_id.end_date}
                  endingTime={item.event_id.end_time}
                  title={item.event_id.title}
                  completed={item.is_expired}
                  onReserve={() => onClick(item._id)}
                />
              </Col>
            ))
          )}
        </Row>
      ) : (
        <Spinner animation="border" variant="dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </Container>
  );
};

export default MyTickets;
