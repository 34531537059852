import Image1 from "../assets/images/1.png";
import Image2 from "../assets/images/2.png";
import Image3 from "../assets/images/3.png";
import Image4 from "../assets/images/4.png";
import Image5 from "../assets/images/5.png";
import Image6 from "../assets/images/6.png";

export const AGE_DATA = [
  {
    id: 1,
    age: "All",
  },
  {
    id: 2,
    age: "21",
  },
  {
    id: 3,
    age: "30",
  },
  {
    id: 5,
    age: "40",
  },
  {
    id: 6,
    age: "50",
  },
];

export const GENDER_DATA = [
  {
    id: 1,
    gender: "All",
  },
  {
    id: 2,
    gender: "Male",
  },
  {
    id: 3,
    gender: "Female",
  },
  {
    id: 4,
    gender: "Transgender",
  },
];

export const STATES_DATA = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const CITIES_DATA = [
  "New York",
  "Los Angeles",
  "Chicago",
  "Houston",
  "Phoenix",
  "Philadelphia",
  "San Antonio",
  "San Diego",
  "Dallas",
  "San Jose",
  "Austin",
  "Jacksonville",
  "Fort Worth",
  "Columbus",
  "Charlotte",
  "San Francisco",
  "Indianapolis",
  "Seattle",
  "Denver",
  "Washington",
  "Boston",
  "El Paso",
  "Nashville",
  "Detroit",
  "Oklahoma City",
  "Portland",
  "Las Vegas",
  "Memphis",
  "Louisville",
  "Baltimore",
  "Milwaukee",
  "Albuquerque",
  "Tucson",
  "Fresno",
  "Sacramento",
  "Mesa",
  "Kansas City",
  "Atlanta",
  "Long Beach",
  "Omaha",
  "Raleigh",
  "Colorado Springs",
  "Miami",
  "Virginia Beach",
  "Oakland",
  "Minneapolis",
  "Tulsa",
  "Arlington",
  "New Orleans",
];
export const MONTHS_DATA = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const HOST_EVENT_STEPS = [
  {
    image: Image1,
    text: "Select Event or Ticket Type.",
  },
  {
    image: Image2,
    text: "Choose Category, Age, and Gender.",
  },
  {
    image: Image3,
    text: "Enter Your Complete Address.",
  },
  {
    image: Image4,
    text: "Setup Check In-n-Out, Ticket Price, and Audiance.",
  },
  {
    image: Image5,
    text: "Enter Title and Description",
  },
  {
    image: Image6,
    text: "Choose Pictures",
  },
];