import React from "react";
import { stylesConfirmation } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { CustomButton } from "../../components";
import { useNavigate } from "react-router-dom";

const ConfirmationPage = () => {
  const navigation = useNavigate();

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesConfirmation.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesConfirmation.heading}>
              Confirmation
            </Card.Title>
            <h2 className={stylesConfirmation.subHeading}>
              Thank You for your reservation and your ticket details has been
              successfully updated to your ticket screen.
            </h2>
            <CustomButton styles={stylesConfirmation.btn} title={"Continue"} onPress={() => navigation('/my-tickets')} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ConfirmationPage;
