import React from "react";
import { stylesLayout } from "../../css";
import { Container } from "react-bootstrap";
import { Outlet, Navigate } from "react-router";
import { Footer, UserNavbar, GotoButton } from "../../components";
import { decryptData } from "../encryption/cryption";

const UserLayout = () => {
  const decrypt = decryptData();
  if (!decrypt) {
    return <Navigate to="/login" />;
  } else if (decrypt.user.user_type === "user") {
    return (
      <Container fluid className={stylesLayout.container}>
        <UserNavbar />
        <Outlet />
        <Footer />
        <GotoButton />
      </Container>
    );
  } else {
    return <Navigate to="/admin/dashboard" />;
  }
};

export default UserLayout;
