import React from "react";
import { stylesImageModal } from "../../css";
import { Button, Carousel } from "react-bootstrap";
import {
  KeyboardArrowRightRounded,
  KeyboardArrowLeftRounded,
  ClearRounded,
} from "@mui/icons-material";

const ImageModal = ({ array, modalClose }) => {

    const handleClose = () => {
        modalClose(false);
    }

  return (
    <>
      <Carousel
        nextIcon={
          <KeyboardArrowRightRounded className={stylesImageModal.arrow} />
        }
        prevIcon={
          <KeyboardArrowLeftRounded className={stylesImageModal.arrow} />
        }
        indicators={false}
        interval={null}
        wrap={false}
        className={stylesImageModal.carouselCnt}
      >
        {array.map((item, key) => (
          <Carousel.Item className={stylesImageModal.carouselSec} key={key}>
            <img
              className={stylesImageModal.carouselImg}
              src={item}
              alt="First slide"
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <Button
        variant="dark"
        className={stylesImageModal.optionBtn}
        onClick={handleClose}
      >
        <ClearRounded
          className={stylesImageModal.optionIcon}
          sx={{ fontSize: 26 }}
        />
      </Button>
    </>
  );
};

export default ImageModal;
