import React from "react";
import { stylesCoupon } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton, TextInput } from "../../components";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { decryptData } from "../../layouts/encryption/cryption";
import { URL } from "../../utils/ApisLink";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";

const ApplyCoupon = () => {
  const decrypt = decryptData();
  const navigation = useNavigate();
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required("Coupon code is required"),
  });

  const initialValues = {
    code: "",
  };

  const handleSubmit = async (values) => {
    try {
      debugger;
      const response = await fetch(URL + "/use-coupon", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        showSuccessToast({ message: "Coupon has been applied successfuly. Please login again!" });
        sessionStorage.clear();
        navigation("/");
      } else {
        const errorData = await response.json();
        showErrorToast({
          message: errorData.message,
        });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesCoupon.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesCoupon.heading}>
              Apply Coupon
            </Card.Title>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <FormikForm className="w-100">
              <TextInput target="code" type="text" label="Coupon Code" placeholder="Code" />
                <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-between my-4 p-0 mx-0">
                  <Card.Text className={stylesCoupon.title}>
                    The admin will send a coupon on your eamil for the discount.
                  </Card.Text>
                </Card.Body>
                <div>
                  <CustomButton type="submit" title="Apply" showIcon={false} styles={stylesCoupon.btn}/>
                </div>
              </FormikForm>
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ApplyCoupon;