import React from "react";
import { stylesAge } from "../../css";
import { Button } from "react-bootstrap";

const AgeItem = ({ id, title, onPress, pressed, styles }) => {
  return (
    <Button
      key={id}
      variant="dark"
      onClick={onPress}
      className={
        pressed === title
          ? `${stylesAge.activeBtn} ${styles}`
          : `${stylesAge.btn} ${styles}`
      }
    >
      <h2
        className={
          pressed === title ? `${stylesAge.activeLabel}` : `${stylesAge.label}`
        }
      >
        {title}
      </h2>
    </Button>
  );
};

export default AgeItem;
