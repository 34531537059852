import React, { useState } from "react";
import { stylesAuth } from "../../css";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton, TextInput, CheckBox } from "../../components";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";

const Login = () => {
  const [show, setShow] = useState("forgot");
  const [storedEmail, setStoredEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigation = useNavigate();

  const eamilValidation = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const codeValidation = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    otp: Yup.number()
      .integer("Code only contain integers")
      .required("Varification code is required"),
  });
  const passwordValidation = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "At least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[@$!#%*?&])[A-Za-z\d@$!%*?&]+$/,
        "At least one uppercase and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const initialEmail = {
    email: "",
  };
  const initialCode = {
    email: storedEmail,
    otp: "",
  };
  const initialPasswaord = {
    email: storedEmail,
    passwaord: "",
    confirmPassword: "",
  };

  const handleEmail = async (values) => {
    try {
      const response = await fetch(URL + "/forget-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values.email.toLowerCase() }),
      });
      if (response.ok) {
        showSuccessToast({ message: "Code has been sent to your email." });
        setShow("verify");
        setStoredEmail(values.email.toLowerCase());
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleCode = async (values) => {
    try {
      console.log(values);
      const response = await fetch(URL + "/verify-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        showSuccessToast({ message: "Thank you for the verification!" });
        setShow("forget");
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handlePasswaord = async (values) => {
    try {
      // debugger;
      console.log(values);
      const response = await fetch(URL + "/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
        }),
      });
      if (response.ok) {
        // debugger;
        showSuccessToast({ message: "Your passwaord has been updated!" });
        navigation("/login");
      } else {
        const errorData = await response.json(values);
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesAuth.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesAuth.heading}>
              Forgot Password
            </Card.Title>
            {show === "forgot" ? (
              <Formik
                initialValues={initialEmail}
                validationSchema={eamilValidation}
                onSubmit={handleEmail}
                className="w-100"
              >
                <FormikForm className="w-100">
                  <TextInput target="email" type="text" label="Email" placeholder="Email" />
                  <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-between my-4 p-0 mx-0">
                    <Card.Text className={stylesAuth.title}>
                      Please enter the eamil of your account.
                    </Card.Text>
                  </Card.Body>
                  <div className={stylesAuth.btnCnt}>
                    <CustomButton
                      type="submit"
                      title="Send"
                      styles={stylesAuth.authBtn}
                    />
                  </div>
                </FormikForm>
              </Formik>
            ) : show === "verify" ? (
              <Formik
                initialValues={initialCode}
                validationSchema={codeValidation}
                onSubmit={handleCode}
                className="w-100"
              >
                <FormikForm className="w-100">
                  <TextInput target="otp" type="number" label="OTP Code" placeholder="OTP Code" />
                  <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-between my-4 p-0 mx-0">
                    <Card.Text className={stylesAuth.title}>
                      Please enter the verification code sent on your eamil.
                    </Card.Text>
                  </Card.Body>
                  <div className={stylesAuth.btnCnt}>
                    <CustomButton
                      type="submit"
                      title="Confirm"
                      styles={stylesAuth.authBtn}
                    />
                  </div>
                </FormikForm>
              </Formik>
            ) : (
              <Formik
                initialValues={initialPasswaord}
                validationSchema={passwordValidation}
                onSubmit={handlePasswaord}
                className="w-100"
              >
                <FormikForm className="w-100">
                  <TextInput
                    target="password"
                    type={showPassword ? "text" : "password"}
                    label="New Password"
                    placeholder="New Password"
                  />
                  <TextInput
                    target="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                  />
                  <CheckBox
                    label="Show Password"
                    onPress={() => setShowPassword(!showPassword)}
                  />
                  <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-between my-4 p-0 mx-0">
                    <Card.Text className={stylesAuth.title}>
                      Please enter the password you want to update.
                    </Card.Text>
                  </Card.Body>
                  <div className={stylesAuth.btnCnt}>
                    <CustomButton
                      type="submit"
                      title="Update"
                      styles={stylesAuth.authBtn}
                    />
                  </div>
                </FormikForm>
              </Formik>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
