import React from "react";
import { stylesLogout } from "../../css";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { CustomButton } from "../../components";
const Logout = () => {
  const navigation = useNavigate();
  const handleClose = () => {
    navigation(-1);
  };
  const handleLogout = () => {
    navigation("/");
    sessionStorage.clear();
  };
  return (
      <Row className={stylesLogout.container}>
        <Col lg={4} md={6} sm={8} className={stylesLogout.section}>
          <h2 className={stylesLogout.label}>Are you sure you want to logout?</h2>
          <CustomButton title="Logout" onPress={handleLogout} styles={stylesLogout.logoutBtn}/>
          <CustomButton title="Cancel" onPress={handleClose} styles={stylesLogout.logoutBtn}/>
        </Col>
      </Row>
  );
};

export default Logout;
