import React from "react";
import { stylesPrivacyPolicy } from "../../css";
import { Row, Col } from "react-bootstrap";

const PrivacyPolicyPage = () => {
  return (
    <Row className="d-flex align-items-center justify-content-center w-100 mb-5 px-3">
      <Col
        lg={8}
        md={10}
        sm={12}
        className="d-flex flex-column align-items-start justify-content-center"
      >
        <div className={stylesPrivacyPolicy.header}>
          <div className={stylesPrivacyPolicy.headerImage}>
            <h2 className={stylesPrivacyPolicy.heading}>Privacy Policy</h2>
          </div>
        </div>
        <p className={stylesPrivacyPolicy.paragraph}>
          We at The People Events, owners of the website at{" "}
          <a href={URL} className={stylesPrivacyPolicy.link}>
            www.proyourev.com
          </a>{" "}
          ("ProYourEv," "our," "we," or "us") value our users' privacy as we
          know that you care about how your personal information is collected,
          used, safeguarded, shared, and we take that seriously. Kindly read the
          following terms to learn more about your privacy, as this Privacy
          Policy ("Policy") will help you understand how we collect and use
          personal information from those who use access and make use of our
          Site and the services that we offer. This Privacy Policy also informs
          California residents of their rights under the California Consumer
          Privacy Act of 2018 ("CCPA").
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          Kindly read the following terms to learn more about your privacy, as
          this Privacy Policy will help you understand how we collect and use
          personal information from those who access and use our site and our
          services.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          By accessing or using the Service in any manner, you acknowledge that
          you accept the practices and policies outlined in this Privacy Policy,
          and you hereby consent that we will collect, use, and share your
          information in the following ways.
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          1. Changes to this Privacy Policy
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          We reserve the right to revise this Privacy Policy from time to time
          in our sole discretion. If there are any material changes to this
          Privacy Policy, we will notify you as required by applicable law. You
          understand and agree that you will be deemed to have accepted the
          updated Privacy Policy if you continue to use the site or services
          after the new Privacy Policy takes effect.
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          2. What does this privacy policy cover?
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          This Policy applies to ProYourEv, and it governs any and all data
          collection and usage by us in compliance with applicable regulations.
          Through the use of these Services, you are consenting to the data
          collection procedures expressed in this Policy.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          We gather various types of information from our users, as explained in
          more detail below, and we use this information internally in
          connection with our Services, including to personalize, provide, and
          improve our Services, to allow you to make payments, to communicate
          with you, and to analyze how you use the Services. In certain cases,
          we may also share some information with third parties, but only as
          described below.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          Please note that this Policy does not govern the collection and use of
          information by companies that ProYourEv does not control, nor by
          individuals not employed or managed by us. If you visit a website that
          is made available via the service or link, be sure to review its
          privacy policy before providing the site with information.
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          3. What information do we collect?
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          When you make use of our Services, we may collect and process a
          variety of personal information about you from various sources, which
          may include but are not limited to the following as described below:
        </p>
        <ul>
          <li>
            <span className={stylesPrivacyPolicy.subHeading}>
              Information you provide:
            </span>
            <p className={stylesPrivacyPolicy.paragraph}>
              <b>"Account profile and Personal Information"</b> means any
              information which, either alone or in combination with other
              information we hold about you, identifies you as an individual or
              as a business, including, for example, your contact information
              such as your name, email address, telephone, location information,
              demographic information, as well as any other non-public
              information about you that is associated with or linked to any of
              the foregoing data which you may be required to provide when using
              the Service. We collect this information when you sign up for the
              Service, engage our interactive services, provide feedback and
              survey responses, request customer support, or communicate with
              us.
            </p>
            <p className={stylesPrivacyPolicy.paragraph}>
              <b>"Anonymous/Aggregated Data"</b> means data that is not
              associated with or linked to your Personal Information; Anonymous
              Data does not, by itself, permit the identification of individual
              persons. We collect, use, and share Anonymous Data, such as
              statistical or demographic data, for any purpose. Anonymous Data
              may be derived from your personal information but is not
              considered personal information in law as such data does not
              directly or indirectly reveal your identity. For instance, we may
              aggregate or anonymize your usage data to calculate the percentage
              of users accessing a specific Service feature. However, suppose we
              combine or connect Aggregated Data with your personal information
              so that it can directly or indirectly identify you; we will treat
              the combined data as personal information, which will be used in
              accordance with this privacy policy.
            </p>
            <p className={stylesPrivacyPolicy.paragraph}>
              Note that we do not collect any special category of information
              about you such as your race, ethnicity, philosophical or religious
              beliefs, sexual orientation, political opinions, information about
              your health, and genetic and biometric data).
            </p>
            <p className={stylesPrivacyPolicy.paragraph}>
              Please note that you can choose not to provide us with certain
              information, but this may limit the features of the Services you
              can use.
            </p>
          </li>
          <li>
            <span className={stylesPrivacyPolicy.subHeading}>
              Information we collect automatically:
            </span>
            <p className={stylesPrivacyPolicy.paragraph}>
              We may collect certain information (which does not identify you as
              an individual) about your use of the Service through the use of
              tracking technologies or by other passive means and other relevant
              statistics, including the following:
            </p>
            <ul>
              <li>
                <p className={stylesPrivacyPolicy.paragraph}>
                  <u>
                    <b>Usage Data:</b>
                  </u>{" "}
                  When you access and/or make use of our Services, we collect
                  details of your visit such as traffic data, date and time of
                  your visit to the Service, logs, location, error tracking
                  information, and other usage data and resources that you
                  access and use on or in the Services.
                </p>
              </li>
              <li>
                <p className={stylesPrivacyPolicy.paragraph}>
                  <u>
                    <b>Device and location information:</b>
                  </u>{" "}
                  We may also collect information about your mobile, internet
                  connection, and computer device, such as your operating
                  system, IP address, and browser type. For example, we make use
                  of Google Analytics to track and collect these kinds of
                  information. We may also collect location information through
                  the use of geolocation technology.
                </p>
              </li>
              <li>
                <p className={stylesPrivacyPolicy.paragraph}>
                  <u>
                    <b>Cookies:</b>
                  </u>{" "}
                  When you access and/or make use of our Services, we may send
                  one or more cookies (small text files containing a string of
                  characters) to your device that uniquely identifies your
                  browser. We use cookies to improve the quality of the software
                  and service by storing user preferences and tracking user
                  trends. You can refuse to accept browser cookies on your
                  computer by activating the relevant browser setting, and you
                  may have the same capabilities on your mobile device under the
                  choices for your operating system or browser. If you choose
                  this option, you may be unable to access or utilize certain
                  features of our Services. Unless you have configured your
                  browser or operating system to reject cookies, our system will
                  send cookies when you visit our website.
                </p>
              </li>
              <li>
                <p className={stylesPrivacyPolicy.paragraph}>
                  <u>
                    <b>Links:</b>
                  </u>{" "}
                  The Service or contents made available on the Service may
                  include links in a format that enables us to track whether IP
                  addresses have followed these links. We use this information
                  to improve the quality of our services and design.
                </p>
              </li>
              <li>
                <p className={stylesPrivacyPolicy.paragraph}>
                  <u>
                    <b>Beacons:</b>
                  </u>{" "}
                  Beacons (also known as "pixel tags" or "clear GIFs") are 1×1
                  single-pixel graphics that allow us to count the number of
                  users who have visited or accessed the Service and to
                  recognize users by accessing our cookies. We may employ
                  beacons to facilitate service administration and navigation,
                  track the actions of users of the service, compile aggregated
                  statistics about service usage and response rates, and provide
                  an enhanced online experience for visitors to the Service.
                </p>
              </li>
              <li>
                <p className={stylesPrivacyPolicy.paragraph}>
                  <u>
                    <b>Analytics services:</b>
                  </u>{" "}
                  We may use third-party analytics services ("Analytics
                  Services"), such as Google Analytics, to help monitor,
                  visualize, report, integrate and analyze how users use our
                  Services. Google Analytics also make use of cookies, which are
                  text files placed on your computer, to help our Services
                  analyze how users use the Services. You can find out more
                  about how Google uses data when you visit our Services by
                  visiting "How Google uses information from sites or apps that
                  use our services" (located at{" "}
                  <a
                    href="https://policies.google.com/technologies/partner-sites"
                    className={stylesPrivacyPolicy.link}
                  >
                    https://policies.google.com
                  </a>{" "}
                  ). Furthermore, the information generated through cookies or
                  other technologies about your use of the ProYourEv Service
                  (the "Analytics Information") may be transmitted to the
                  Analytics Services. The Analytics Services use Analytics
                  Information to compile reports on user activity. The Analytics
                  Services may also transfer the Analytics Information to third
                  parties where required to do so by law, or where such third
                  parties process Analytics Information on their behalf. Each
                  Analytics Service's ability to use and share Analytics
                  Information is restricted by such Analytics Service's terms of
                  use and privacy policy.
                </p>
              </li>
              <li>
                <p className={stylesPrivacyPolicy.paragraph}>
                  <u>
                    <b>Payment transaction information:</b>
                  </u>{" "}
                  Through the use of third-party payment processors, certain
                  financial information you used for payment transactions on our
                  Services may be collected by such third-party payment
                  processors. Payment amount, date and time of the transaction,
                  payment instrument expiration date and billing postcode,
                  user's address and other transaction details may also be
                  collected.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <span className={stylesPrivacyPolicy.subHeading}>Event data:</span>
            <p className={stylesPrivacyPolicy.paragraph}>
              If you are an event organizer, we collect data related to any
              events hosted through our Services, including event details,
              ticket sales data, attendance tracking and metrics, sales
              transactions, billing details and payouts to your account.
            </p>
          </li>
          <li>
            <span className={stylesPrivacyPolicy.subHeading}>
              Information automatically collected by third parties about you:
            </span>
            <p className={stylesPrivacyPolicy.paragraph}>
              Some embedded content on the Service may be served by third-party
              service providers. Please note that these companies may also use
              cookies or other similar technologies to collect data about you,
              both on the ProYourEv Services and throughout the Internet. Data
              collection and use by those third parties is subject to the
              privacy policies of those third-parties 
            </p>
          </li>
        </ul>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          4. How do we use your information?
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          ProYourEv uses the information collected from you to provide, improve,
          and develop the services we provide to you, serve you adverts that we
          think might be of interest to you, communicate with you, offer you a
          better service, and protect us and our users. When you interact with
          or make use of the ProYourEv service, we use a variety of technologies
          to collect information about you for various reasons.
        </p>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Provide, manage and improve the site</b>
        </u>
        <ul>
          <li>
            <p>
              Provide you with the products and services you purchase through
              the site.
            </p>
          </li>
          <li>
            <p>Process orders and transactions.</p>
          </li>
          <li>
            <p>Communicate with you about your orders and transactions.</p>
          </li>
          <li>
            <p>Provide you with support and respond to your inquiries.</p>
          </li>
          <li>
            <p>Identify and implement service improvements.</p>
          </li>
          <li>
            <p>Improve the site to ensure content is relevant.</p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Create and manage your account</b>
        </u>
        <ul>
          <li>
            <p>Create and manage user accounts.</p>
          </li>
          <li>
            <p>
              Provide you with account settings and tools for accessing,
              correcting, deleting, or modifying account information.
            </p>
          </li>
          <li>
            <p>
              Provide you with notices about material changes to our services
              and policies.
            </p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Marketing and advertising</b>
        </u>
        <ul>
          <li>
            <p>Send you information about new products or services.</p>
          </li>
          <li>
            <p>
              Administer contests, sweepstakes, promotions, and marketing
              campaigns.
            </p>
          </li>
          <li>
            <p>
              Provide personalized content and advertising tailored to your
              interests.
            </p>
          </li>
          <li>
            <p>
              Communicate with you about our brands, products, and services.
            </p>
          </li>
          <li>
            <p>Analyze advertising efficiency and customize user experience.</p>
          </li>
        </ul>
        <p className={stylesPrivacyPolicy.paragraph}>
          We implement the Facebook pixel to aid with digital advertising and
          conversion tracking, as detailed further below.
        </p>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Site analytics</b>
        </u>
        <ul>
          <li>
            <p>
              Analyze site usage metrics such as total visitors, traffic
              sources, pages visited etc., to improve site quality and services.
            </p>
          </li>
          <li>
            <p>
              Correlate site analytics information with data collected through
              the Facebook Pixel for ad targeting and analytics
            </p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Legal compliance and security</b>
        </u>
        <ul>
          <li>
            <p>Comply with legal requirements under applicable law.</p>
          </li>
          <li>
            <p>
              Detect and protect against misuse, fraud or security incidents.
            </p>
          </li>
          <li>
            <p>Protect user safety and rights.</p>
          </li>
        </ul>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          5. Personal Information that we share
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          Except as described below or otherwise stated in this Privacy Policy,
          we do not share your Personal Information with third parties. We may
          disclose your non-Personal Information to third parties in order to
          provide the services you have requested.
        </p>
        <ul>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>With other ProYourEv users:</b>
              </u>{" "}
              We may share with other users such as event organizers certain
              information about you, such as your name, email, and other
              information that you make available on your profile publicly.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>Service providers:</b>
              </u>{" "}
              We may provide collected data to certain service providers such as
              our payment processor/partners that we may make use of from time
              to time for payment processing. They may use your data to perform
              services for us and may have access to, store, and process your
              personal data to provide services on our behalf. Such services may
              also include verifying your identity, preventing, detecting and
              protecting against fraudulent activity or abuse of our services,
              conducting internal research and analytical assessments,
              processing your transactions, maintaining your account(s) and
              providing you with customer support services. We may share
              personal information or any information collected through the
              Service with these third parties for the purpose of enabling them
              to provide these services and they may have controlled and limited
              access to the database of user information.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>Subsidiaries, business partners and affiliates:</b>
              </u>{" "}
              We may share personal information or any information collected
              through your use of the Service with our subsidiaries and
              affiliates for the purposes for which you provided the information
              or as reasonably necessary for our internal administrative and
              business purposes. We may share your Personal Information with our
              business partners. Some of these business partners may use your
              personal information to facilitate the offering of services or
              products that may be of interest to you. We may also share your
              Personal Information as otherwise described to you at the time of
              collection.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>Analytics Partners:</b>
              </u>{" "}
              When you provide your personal information to us, we may make
              certain personal information available to certain third parties
              for analytics purposes, including but not limited to leveraging
              third-party tools and services to host, track, integrate, manage
              user's interests, usage pattern, and/or functionality available
              through our Services. We will only share your personal information
              with analytics partners for the improvement of our own service
              and/or to deliver the ProYourEv services to you. We do not in any
              way sell your personal information to advertisers.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>Merger and acquisition:</b>
              </u>{" "}
              We may disclose or transfer personal information or any
              information collected through your usage of our Service to third
              parties who acquire all or a portion of ProYourEv's business,
              whether such acquisition is by way of merger, consolidation, or
              purchase of all or a portion of our assets, or in connection with
              any bankruptcy or reorganization proceeding brought by or against
              us.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>Compliance with laws:</b>
              </u>{" "}
              We may disclose personal information or any information collected
              through your usage of the ProYourEv Service if we are required to
              do so by law or pursuant to legal process, in response to a
              request from government officials, law enforcement authorities or
              a valid subpoena, or as necessary or appropriate in connection
              with an investigation of illegal activity, including without
              limitation to prevent, investigate, detect or prosecute any
              criminal offense or attack.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>Consent:</b>
              </u>{" "}
              We may share personal information in accordance with any consent
              you provide.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <u>
                <b>Aggregate Information:</b>
              </u>{" "}
              We share aggregate statistical data for the improvement of our
              Services. We may also share aggregated or de-identified
              information with third parties at our discretion.
            </p>
          </li>
        </ul>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          6. How long do we retain your information?
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          We keep your personal information only as long as necessary to provide
          you with the ProYourEv Service and for legitimate and essential
          business purposes, such as maintaining the performance of the
          ProYourEv Service, making data-driven business decisions about new
          features and offerings, complying with our legal obligations, and
          resolving disputes. We keep some of your personal information for as
          long as you are a user of the ProYourEv Service.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          If you request, we will delete or anonymize your personal information
          so that it no longer identifies you, unless we are legally allowed or
          required to maintain certain personal information, including
          situations such as the following:
        </p>
        <ul>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              If there is an unresolved issue relating to your use of the
              service, such as an unresolved claim or dispute, we will retain
              the necessary personal information until the issue is resolved;
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              Where we need to retain the personal information for our legal,
              tax, audit, and accounting obligations, we will retain the
              necessary personal information for the period required by
              applicable law and/or,
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              Where necessary for our legitimate business interests, such as
              fraud prevention or maintaining the security of our users and the
              platform.
            </p>
          </li>
        </ul>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          7. Your privacy rights
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          General Data Protection Regulations grant you certain rights
          ('information rights') which provide you with:
        </p>
        <ul>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <b>Right of rectification or erasure</b> - if you feel that any
              data that we hold about you is inaccurate you have the right to
              ask us to correct or rectify it. You also have a right to ask us
              to erase information about you where you can demonstrate that we
              no longer need the data we hold if you withdraw the consent upon
              which our processing is based, or if you feel that we are
              unlawfully processing your data. Your right of rectification and
              erasure extends to anyone we have disclosed your personal
              information to and we will/shall take all reasonable steps to
              inform those with whom we have shared your data about your request
              for erasure. Note that we may have a legal obligation to retain
              some or even all personal information about you under relevant
              laws. We may also have the legal right to retain the information
              you have requested to be erased.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <b>Right to restriction of processing</b> - you have a right to
              request that we refrain from processing your data where you
              contest its accuracy, or the processing is unlawful and you have
              opposed its erasure, or where we don't need to hold your data
              anymore but you need us to in order to establish, exercise or
              defend any legal claims, or we are in dispute about the legality
              of our processing your personal information.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <b>Right to object</b> - you have a right to object to our
              processing of your personal information where the basis of the
              processing is our legitimate interests including but not limited
              to direct marketing and profiling.
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <b>Right to withdraw consent</b> - you have the right to withdraw
              your consent for the processing of your personal information where
              the processing is based on consent. To withdraw consent, please
              specify what you wish to withdraw consent for, by emailing us at{" "}
              <a
                href="https://mail.google.com/mail/#compose"
                className={stylesPrivacyPolicy.link}
              >
                info@proyourev.com.
              </a>
            </p>
          </li>
          <li>
            <p className={stylesPrivacyPolicy.paragraph}>
              <b>Right of complaint</b> - if you are unhappy with the way we
              have used or are handling your personal information you have the
              right to lodge a complaint with a supervisory authority for data
              protection issues. We would, however, appreciate the chance to
              deal with your concerns before you approach such a Supervisory
              Authority. So, please contact us in the first instance by emailing
              us at{" "}
              <a
                href="https://mail.google.com/mail/#compose"
                className={stylesPrivacyPolicy.link}
              >
                info@proyourev.com.
              </a>
            </p>
          </li>
        </ul>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          8. Keeping your data safe
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          As a matter of policy, we are committed to protecting your data and as
          such, ProYourEv takes precautions to protect your information. We
          adopt commercially reasonable measures to secure Personal Information
          by using physical and electronic measures designed to ensure the
          integrity and security of the Personal Information we hold. When you
          submit sensitive information via the Service, your information is
          protected. Any payment transactions will be encrypted using SSL
          technology. Where you have chosen a password which enables you to
          access certain parts of our site, you are responsible for keeping this
          password confidential. We ask you not to share a password with anyone.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          However, please note that no method of transmission over the Internet,
          or method of electronic storage, is 100% secure. Although we will do
          our best to protect the personal information that we gather about you,
          the transmission of information via the internet is not completely
          secure and as such, we cannot guarantee the absolute security of your
          data transmitted to the Service; any transmission is at your own risk.
          We have implemented various policies including encryption, access, and
          retention policies to guard against unauthorized access and
          unnecessary retention of personal information in our systems.
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          9. Additional privacy rights for California residents
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          If you are a resident of California, this section provides additional
          information about specific rights regarding your personal information
          in accordance with the California Consumer Privacy Act ("CCPA").
        </p>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Personal Information we collect</b>
        </u>
        <p className={stylesPrivacyPolicy.paragraph}>
          The following is a list of personal information that we collect or
          have collected from California residents over the past twelve months.
          We will update this disclosure from time to time as appropriate.
        </p>
        <ul>
          <li>
            <p>
              Unique identifiers such as your first name, last name, gender, or
              similar identifier, address, or IP address;
            </p>
          </li>
          <li>
            <p>
              Internet or other electronic network activity information,
              including content interaction information, such as content
              downloads, search history, transaction and interaction data on
              your use of our Service and from links in our emails sent to you;
            </p>
          </li>
          <li>
            <p>
              geolocation data, such as the location of your device or computer;
            </p>
          </li>
          <li>
            <p>
              Photo, video or visual information, such as your profile photo;
            </p>
          </li>
          <li>
            <p>
              inference data, such as information about your purchase
              preferences, interest, feedback, and survey responses;
            </p>
          </li>
          <li>
            <p>
              Other information that identifies or can be reasonably associated
              with you (e.g., the information you voluntarily provide as you use
              the ProYourEv services, such as additional profile information or
              user-generated content).
            </p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>How we collect personal information</b>
        </u>
        <p className={stylesPrivacyPolicy.paragraph}>
          We obtain the categories of personal information listed above from the
          following sources:
        </p>
        <ul>
          <li>
            <p>
              Directly from our Services users when they provide the information
              to us.
            </p>
          </li>
          <li>
            <p>
              Indirectly from you as you navigate through the Services.
              Information collected automatically may include usage details, IP
              addresses, and information collected through cookies, web beacons,
              and other tracking technologies.
            </p>
          </li>
          <li>
            <p>From third-party service providers.</p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>
            Categories of personal information disclosed for our business
            purpose
          </b>
        </u>
        <p className={stylesPrivacyPolicy.paragraph}>
          The personal information that we disclosed about consumers for a
          business purpose over the past (12) twelve months falls into the
          following categories as established by the California Consumer Privacy
          Act:
        </p>
        <ul>
          <li>
            <p>
              Identifiers such as your name, email address, billing address, or
              IP address;
            </p>
          </li>
          <li>
            <p>
              Personal information, such as a credit card number, for example,
              if we use a third-party payment processor;
            </p>
          </li>
          <li>
            <p>
              Your age, age range, gender, or other protected classifications,
              for example, if you choose to participate in a survey distributed
              by us or fill the User Content form;
            </p>
          </li>
          <li>
            <p>Geolocation data from your IP address</p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Access request rights</b>
        </u>
        <p className={stylesPrivacyPolicy.paragraph}>
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information over the
          past twelve (12) months. But only you, or someone legally authorized
          to act on your behalf, may make a verifiable consumer request related
          to your personal information. Designated agents must submit proof that
          the consumer has authorized them to act on their behalf. Making a
          verifiable consumer request does not require you to create an account
          with us. We will only use personal information provided in a
          verifiable consumer request to verify the requestor's identity or
          authority to make the request. If you wish to do any of these things,
          please contact us. Once we receive and verify your request, we will
          disclose it to you. You may make up to two (2) requests, no more than
          twice in a twelve-month period for information as to:
        </p>
        <ul>
          <li>
            <p>
              The categories of personal information we collected about you or
              shared with our service providers for business purposes.
            </p>
          </li>
          <li>
            <p>
              The categories of sources for the personal information we
              collected about you.
            </p>
          </li>
          <li>
            <p>
              Our business or commercial purpose for collecting that personal
              information.
            </p>
          </li>
          <li>
            <p>
              The specific pieces of personal information we collected about
              you.
            </p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>Right to deletion of personal information</b>
        </u>
        <p className={stylesPrivacyPolicy.paragraph}>
          You have the right under the California Consumer Privacy Act to
          request the deletion of your personal information. If you wish to do
          this, please contact us. Depending on your data choices and subject to
          applicable regulations, certain services may be limited or
          unavailable.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          We may, however, deny your deletion request if retaining the
          information is necessary for us or our service providers(s) to:
        </p>
        <ul>
          <li>
            <p>
              Complete the purpose for which we collected the personal
              information, provide a service that you requested, take actions
              reasonably anticipated within the context of our ongoing business
              relationship with you, or otherwise perform our contract with you.
            </p>
          </li>
          <li>
            <p>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </p>
          </li>
          <li>
            <p>
              Exercise free speech; ensure another consumer's right to exercise
              their free speech rights or exercise another right provided for by
              law.
            </p>
          </li>
          <li>
            <p>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq. or other legal obligation.
            </p>
          </li>
          <li>
            <p>
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </p>
          </li>
        </ul>
        <u className={stylesPrivacyPolicy.paragraph}>
          <b>No discrimination</b>
        </u>
        <p className={stylesPrivacyPolicy.paragraph}>
          You have the right not to receive discriminatory treatment for the
          exercise of your rights under the CCPA, and ProYourEv will not
          discriminate against any consumer for exercising their rights under
          the California Consumer Privacy Act.
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          10. Children under the age of 18/Age restriction
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          ProYourEv's Services are not directed to and do not knowingly collect
          personally identifiable information from, children under the age of
          eighteen (18). If it is determined that such information has been
          inadvertently collected on anyone under the age of eighteen (18), we
          shall immediately take the necessary steps to ensure that such
          information is deleted from our system's database.
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          11. Your choices: unsubscribe or opt-out
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          <u>
            <b>Account information:</b>
          </u>{" "}
          You may update certain information you provide to us (such as your
          password, name and email address) by logging into your ProYourEv
          account or contacting us through our support channels. Also, if you
          decide to delete your ProYourEv account, please note that we may
          retain certain information where required or permitted by law. We may
          also retain cached or archived copies of certain information about you
          and your usage of the Services for a certain period of time.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          <u>
            <b>Interest-based advertising:</b>
          </u>{" "}
          We may also make use of the information we gather about you to provide
          you with advertisements and offers that are more relevant to your
          interest. These advertisements may be based on the information we
          collect through cookies and other tracking technologies that we use on
          our site, emails, and through other third-party websites you visit.
        </p>
        <p className={stylesPrivacyPolicy.paragraph}>
          <u>
            <b>Do not track settings:</b>
          </u>{" "}
          Some browsers have "Do Not Track" features that let you send a request
          to sites to not track your online activities. We, like many other
          sites, do not honor those requests.
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          12. Links to other sites or resources
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          ProYourEv does not claim nor accept responsibility for any privacy
          policies, practices and/or procedures of other third-party sites or
          resources. Therefore, we encourage all users and visitors to be aware
          when they leave our Site and to read the privacy statements of every
          website that collects personally identifiable information. This
          Privacy Policy Agreement applies only and solely to the information
          collected by our Site. 
        </p>
        <h2 className={stylesPrivacyPolicy.subHeading}>
          13. How to contact us
        </h2>
        <p className={stylesPrivacyPolicy.paragraph}>
          Feedback, comments, requests for technical support, and other
          communications relating to the Site and the services should be
          directed to us at{" "}
          <a
            href="https://mail.google.com/mail/#compose"
            className={stylesPrivacyPolicy.link}
          >
            info@proyourev.com.
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default PrivacyPolicyPage;
