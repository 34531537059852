import React, { useState } from "react";
import { stylesAuth } from "../../css";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  TextInput,
  CheckBox,
  GoogleLoation,
} from "../../components";
import Autocomplete from "react-google-autocomplete";
import { showSuccessToast, showErrorToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Signup = () => {
  const [userEmail, setUserEamil] = useState();
  const [userName, setUserName] = useState();
  const [userPassword, setUserPassword] = useState();
  const [userConfirmPassword, setUserConfirmPassword] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [termsPolicy, setTermsPolicy] = useState(false);
  const navigation = useNavigate();

  const calculateAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const primaryValidations = Yup.object().shape({
    username: Yup.string()
      .min(3, "Full name is required")
      .required("Full name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "At least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "At least one uppercase and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const secondaryValidations = Yup.object().shape({
    passport: Yup.string()
      .matches(/^\d+$/, "Passport/ID must contain only numbers")
      .min(3, "Passport/ID is required")
      .required("Passport/ID is required"),
    dob: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
        "Date of birth must be in MM/DD/YYYY format"
      )
      .test("age", "Must be at least 21 years old", function (dob) {
        const age = calculateAge(dob);
        return age >= 21;
      })
      .required("Date of birth is required"),
  });

  const primaryValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const secondaryValues = {
    passport: "",
    dob: "",
  };

  const handlePrimary = async (values) => {
    try {
      const response = await fetch(URL + "/check-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values.email.toLowerCase() }),
      });
      if (response.ok) {
        setUserEamil(values.email.toLowerCase());
        setUserName(values.username);
        setUserPassword(values.password);
        setUserConfirmPassword(values.confirmPassword);
        setShow(true);
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleSecondary = async (values) => {
    if (selectedAddress) {
      if (termsPolicy) {
        const userData = {
          email: userEmail,
          username: userName,
          password: userPassword,
          confirmPassword: userConfirmPassword,
          passport: values.passport,
          location: selectedAddress,
          dob: values.dob,
        };
        handleSubmit(userData);
      } else {
        showErrorToast({
          message: "Please agree with our terms and privacy policies.",
        });
      }
    } else {
      showErrorToast({
        message: "Please select the addess.",
      });
    }
  };

  const handleSubmit = async (data) => {
    try {
      const response = await fetch(URL + "/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        showSuccessToast({
          message: "Varification code sent successfully",
        });
        navigation("/verify-email");
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handlePlaceSelected = (place) => {
    if (place && place.geometry && place.geometry.location) {
      setSelectedAddress(place.formatted_address);
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center w-100">
      <Col lg={4} md={6} sm={12}>
        <Card className={stylesAuth.container}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center w-100">
            <Card.Title className={stylesAuth.heading}>Signup</Card.Title>
            <Formik
              initialValues={!show ? primaryValues : secondaryValues}
              validationSchema={
                !show ? primaryValidations : secondaryValidations
              }
              onSubmit={!show ? handlePrimary : handleSecondary}
              className="w-100"
            >
              <FormikForm className="w-100">
                {!show ? (
                  <>
                    <TextInput
                      target="username"
                      type="text"
                      label="Full Name"
                      placeholder="Full Name"
                    />
                    <TextInput target="email" type="text" label="Email" placeholder="Email" />
                    <TextInput
                      target="password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      placeholder="Password"
                    />
                    <TextInput
                      target="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                    />
                    <CheckBox
                      label="Show Password"
                      onPress={() => setShowPassword(!showPassword)}
                    />
                    <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-center my-4 p-0 mx-0">
                      <Card.Text
                        className={stylesAuth.linkTitle}
                        onClick={() => navigation("/login")}
                      >
                        Already have an account?
                      </Card.Text>
                    </Card.Body>
                  </>
                ) : (
                  <>
                    <TextInput
                      target="passport"
                      type="text"
                      label="Passport/ID"
                      placeholder="Passport/ID"
                    />
                    <GoogleLoation
                      label="Location"
                      value={selectedAddress}
                      onChange={handlePlaceSelected}
                    />
                    <TextInput target="dob" type="text" label="Date of Birth" placeholder="MM/DD/YYYY" />
                    <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-start my-4 p-0 mx-0">
                      <CheckBox
                        onPress={() => setTermsPolicy(true)}
                        label="Click here to indicate that you have read and agreed to
                        our privacy policy and terms of services."
                      />
                      <Card.Text className={stylesAuth.title}></Card.Text>
                    </Card.Body>
                  </>
                )}
                <div className={stylesAuth.btnCnt}>
                  <CustomButton
                    type="submit"
                    title={!show ? "Continue" : "Signup"}
                    styles={stylesAuth.authBtn}
                  />
                </div>
                <Card.Body className="d-flex flex-md-row flex-column align-items-start justify-content-evenly p-0 m-0">
                  <Card.Text
                    className={stylesAuth.linkTitle}
                    onClick={() => navigation("/policies")}
                  >
                    Privacy Policy
                  </Card.Text>
                  <Card.Text
                    className={stylesAuth.linkTitle}
                    onClick={() => navigation("/terms")}
                  >
                    Terms of Sevices
                  </Card.Text>
                </Card.Body>
              </FormikForm>
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Signup;
