import React from "react";
import { stylesCarousel } from "../../css";
import { Carousel, Container } from "react-bootstrap";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  ViewCarouselRounded,
} from "@mui/icons-material";

const CarouselItem = ({ array, onPressPicture, extraStyle, type }) => {
  return (
    <>
      {type === "single" ? (
        <Carousel
          nextIcon={
            <KeyboardArrowRightRounded
              className={stylesCarousel.arrow}
              sx={{ fontSize: 26 }}
            />
          }
          prevIcon={
            <KeyboardArrowLeftRounded className={stylesCarousel.arrow} />
          }
          indicators={false}
          interval={null}
          wrap={false}
          className={`${stylesCarousel.carouselCnt} ${extraStyle}`}
        >
          {array.map((item, key) => (
            <Carousel.Item className={stylesCarousel.carouselSec} key={key}>
              <img
                className={stylesCarousel.carouselImg}
                src={item}
                alt="First slide"
                onClick={onPressPicture}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <>
          <Carousel
            nextIcon={
              <KeyboardArrowRightRounded
                className={stylesCarousel.arrow}
                sx={{ fontSize: 26 }}
              />
            }
            prevIcon={
              <KeyboardArrowLeftRounded className={stylesCarousel.arrow} />
            }
            indicators={false}
            interval={null}
            wrap={false}
            className={`${stylesCarousel.carouselCnt} d-sm-flex d-md-none d-lg-none`}
          >
            {array.map((item, key) => (
              <Carousel.Item className={stylesCarousel.carouselSec} key={key}>
                <img
                  className={stylesCarousel.carouselImg}
                  src={item}
                  alt="First slide"
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <Container
            className={`${stylesCarousel.imgCnt} d-none d-md-flex d-lg-flex`}
            onClick={onPressPicture}
          >
            {array.length > 1 ? (
              <>
                <Container fluid className={stylesCarousel.imgLeft}>
                  <img
                    src={array[0]}
                    className={stylesCarousel.image}
                    alt="left-img"
                  />
                </Container>
                <Container className={stylesCarousel.imgRight}>
                  <Container className={stylesCarousel.imgTop}>
                    <img
                      src={array[1]}
                      className={stylesCarousel.image}
                      alt="left-img"
                    />
                  </Container>
                  <Container className={stylesCarousel.imgBottom}>
                    <ViewCarouselRounded
                      sx={{ fontSize: 46 }}
                      className={stylesCarousel.imgIcon}
                    />
                    <span className={stylesCarousel.imgText}>
                      Show all photos
                    </span>
                  </Container>
                </Container>
              </>
            ) : (
              <>
                <Container fluid className={stylesCarousel.imgLarge}>
                  <img
                    src={array[0]}
                    className={stylesCarousel.image}
                    alt="left-img"
                  />
                </Container>
                <Container fluid className={stylesCarousel.imgSmall}>
                  <ViewCarouselRounded
                    sx={{ fontSize: 46 }}
                    className={stylesCarousel.imgIcon}
                  />
                  <span className={stylesCarousel.imgText}>
                    Show all photos
                  </span>
                </Container>
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default CarouselItem;
