import React, { useState, useRef, useEffect } from "react";
import { stylesHost } from "../../css";
import { useNavigate } from "react-router-dom";
import { Carousel, Row, Col, Button, Image, Spinner } from "react-bootstrap";
import {
  CategoryItem,
  AgeItem,
  GenderItem,
  CustomButton,
  TextInput,
  MessageInput,
  GoogleLoation,
} from "../../components";
import { AGE_DATA, GENDER_DATA } from "../../utils/data";
import { URL as newURL } from "../../utils/ApisLink";
import {
  ChevronLeftRounded,
  FileUploadRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowLeftRounded,
} from "@mui/icons-material";
import { decryptData } from "../../layouts/encryption/cryption";
import { showErrorToast } from "../../components/tost/tost";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import Checked from "../../assets/images/checked.png";

const HostEvent = () => {
  const decrypt = decryptData();
  const useData = decrypt.user;
  const [coupon, setCoupon] = useState();
  const [next, setNext] = useState(false);
  const [show, setShow] = useState(null);
  const [confirmLocation, setConfirmLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [selectedAge, setSelectedAge] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [selectedPostalCode, setSelectedPostalCode] = useState("");
  const [selectedLat, setSelectedLat] = useState();
  const [selectedLng, setSelectedLng] = useState();
  const [events, setEvents] = useState([]);
  const [images, setImages] = useState([]);
  const [free, setFree] = useState(0);
  const [uploadImgs, setUploadImags] = useState([]);
  const [categories, setCategories] = useState([]);
  const inputRef = useRef(null);
  const navigation = useNavigate();
  const todaysDate = new Date();
  const todaysFormatedDate = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth(),
    todaysDate.getDate(),
    0,
    0
  );

  const locationValidationSchema = Yup.object().shape({
    address: Yup.string()
      .min(5, "Please enter the complete address.")
      .required("Please enter the address."),
    city: Yup.string()
      .min(5, "Please enter the complete city name.")
      .required("Please enter the city name."),
    state: Yup.string()
      .min(5, "Please enter the complete state name.")
      .required("Please enter the state name."),
    country: Yup.string()
      .min(2, "Please enter the complete country name.")
      .required("Please enter the country name."),
    postal_code: Yup.number()
      .min(2, "Please enter the postal code.")
      .required("Please enter the postal code."),
  });

  const freeValidationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1, "Please enter the event title.")
      .required("Please enter the event title."),
    organization: Yup.string()
      .min(1, "Please enter the organization name.")
      .required("Please enter the organization name."),
    description: Yup.string()
      .min(100, "Description must be inculuded 100 words at least.")
      .max(15000, "Description cannot be more than 1500 words.")
      .required("Please enter the event description."),
    start_date: Yup.date()
      .required("Please select the event starting date.")
      .min(
        todaysFormatedDate,
        "Starting date cannot be earlier than the current date."
      ),
    end_date: Yup.date()
      .required("Please select the event ending date.")
      .min(
        Yup.ref("start_date"),
        "Ending date cannot be earlier than the starting date."
      ),
    start_time: Yup.string()
      .matches(
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Please select the time in HH:MM format."
      )
      .required("Please select the event starting time."),
    end_time: Yup.string()
      .matches(
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Please select the time in HH:MM format."
      )
      .required("Please select the event ending time."),
    total_guests: Yup.number()
      .required("Please enter the audiance count.")
      .max(100, "Please enter the audiance count less then 100."),
    ticket: Yup.number()
      .required("Please enter the ticket price.")
      .min(10, "Please enter the ticket price greater then 10$."),
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1, "Please enter the event title.")
      .required("Please enter the event title."),
    organization: Yup.string()
      .min(1, "Please enter the organization name.")
      .required("Please enter the organization name."),
    description: Yup.string()
      .min(100, "Description must be inculuded 100 words at least.")
      .max(15000, "Description cannot be more than 1500 words.")
      .required("Please enter the event description."),
    start_date: Yup.date()
      .required("Please select the event starting date.")
      .min(
        todaysFormatedDate,
        "Starting date cannot be earlier than the current date."
      ),
    end_date: Yup.date()
      .required("Please select the event ending date.")
      .min(
        Yup.ref("start_date"),
        "Ending date cannot be earlier than the starting date."
      ),
    start_time: Yup.string()
      .matches(
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Please select the time in HH:MM format."
      )
      .required("Please select the event starting time."),
    end_time: Yup.string()
      .matches(
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Please select the time in HH:MM format."
      )
      .required("Please select the event ending time."),
    total_guests: Yup.number().required("Please enter the audiance count."),
    ticket: Yup.number()
      .required("Please enter the ticket price.")
      .min(
        free === 1 ? 0 : 5,
        free === 1
          ? "You can't select the ticket price for free tickets."
          : "Please enter the ticket price more then 5$."
      ),
  });

  const locationInitialValues = {
    city: selectedCity,
    state: selectedState,
    address: selectedAddress,
    country: selectedCountry,
    postal_code: selectedPostalCode,
  };

  const freeInitialValues = {
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    total_guests: "",
    title: "",
    organization: "",
    description: "",
    ticket: "",
  };

  const initialValues = {
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    total_guests: "",
    title: "",
    organization: "",
    description: "",
    ticket: free === 1 ? 0 : "",
  };

  useEffect(() => {
    if (useData.has_coupon === true && useData.coupon_type === false) {
      setCoupon("free");
      fetchEvents();
      fetchCategories();
    } else {
      setCoupon("discount");
      fetchEvents();
      fetchCategories();
    }
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch(newURL + "/get-events", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const result = await response.json();
        const data = result.data;
        const filteredEvents = data.filter(
          (item) => item.user_id._id === decrypt.user._id
        );
        if (filteredEvents.length > 0) {
          setEvents(filteredEvents);
          console.log(filteredEvents.length);
        }
      } else {
        showErrorToast({
          message: "Error fetching events:" + response.statusText,
        });
      }
    } catch (error) {
      showErrorToast({ message: error });
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(newURL + "/admin/categories", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCategories(data.data);
      } else {
        showErrorToast({
          message: "Error fetching categories:" + response.statusText,
        });
      }
    } catch (error) {
      showErrorToast({ message: error });
    }
  };

  const handleSubmit = (values) => {
    if (selectedCity && selectedState) {
      setNext(true);
      setEventDetails(values);
    } else {
      showErrorToast({ message: "Please select the city and state" });
    }
  };

  const handelInputClick = () => {
    inputRef.current.click();
  };

  const generateUniqueId = () => "_" + Math.random().toString(36).substr(2, 9);

  const handelInputChange = (e) => {
    const file = Array.from(e.target.files);
    setUploadImags(file);

    file.forEach((items) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        setImages((prevImages) => {
          const id = generateUniqueId();
          return [...(prevImages || []), { id, image: event.target.result }];
        });
      };

      reader.readAsDataURL(items);
    });
  };

  const handleUpload = async () => {
    try {
      debugger;
      setUploading(true);
      setShow("success");
      const formData = new FormData();
      uploadImgs.forEach((image) => {
        formData.append("files", image);
      });
      const response = await fetch(newURL + "/admin/upload-file", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: formData,
      });
      if (response.ok) {
        setLoading(true);
        const responseData = await response.json();
        const fileurls = responseData.data.fileUrls;

        const EventData = {
          category_id: selectedCategory,
          user_id: decrypt.user._id,
          ticket_price: eventDetails.ticket,
          is_ticket_free: free === 1 ? 1 : 0,
          address: selectedAddress,
          city: selectedCity,
          state: selectedState,
          postal_code: `${selectedPostalCode}`,
          country: selectedCountry,
          age: selectedAge,
          gender: selectedGender,
          total_guests: `${eventDetails.total_guests}`,
          start_date: eventDetails.start_date,
          end_date: eventDetails.end_date,
          is_membership: false,
          start_time: eventDetails.start_time,
          end_time: eventDetails.end_time,
          image: fileurls,
          title: eventDetails.title,
          organization: eventDetails.organization,
          description: eventDetails.description,
          map_location: selectedAddress,
          latitude: selectedLat,
          longitude: selectedLng,
        };
        UploadEvent(EventData);
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
        setLoading(false);
      }
    } catch (error) {
      showErrorToast({ message: "Error during image upload:" + error.message });
    }
  };

  const UploadEvent = async (values) => {
    try {
      debugger;
      const response = await fetch(newURL + "/create-event", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${decrypt.token}`,
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setLoading(false);
        setUploading(false);
      } else {
        const errorData = await response.json();
        showErrorToast({ message: errorData.message });
        setLoading(false);
      }
    } catch (error) {
      showErrorToast({ message: "Error during event upload:" + error.message });
      setLoading(false);
    }
  };

  const handlePlaceSelected = (place) => {
    let lat = "";
    let lng = "";

    if (place) {
      for (const component of place.address_components) {
        if (component.types.includes("locality")) {
          setSelectedCity(component.long_name);
        } else if (component.types.includes("administrative_area_level_1")) {
          setSelectedState(component.long_name);
        } else if (component.types.includes("postal_code")) {
          setSelectedPostalCode(component.long_name);
        } else if (component.types.includes("country")) {
          setSelectedCountry(component.long_name);
        } else if (place) {
          setSelectedAddress(place.formatted_address);
        }
      }
      if (place.geometry && place.geometry.location) {
        lat = place.geometry.location.lat();
        setSelectedLat(lat);
        lng = place.geometry.location.lng();
        setSelectedLng(lng);
      }
    }
  };

  const handleConfirmLocation = (values) => {
    if (values) {
      setSelectedCity(values.city);
      setSelectedState(values.state);
      setSelectedCountry(values.country);
      setSelectedAddress(values.address);
      setSelectedPostalCode(values.postalCode);
      setShow("event");
    }
  };

  const handleType = () => {
    if (events.length > 0) {
      if (decrypt.subscription || coupon === "free") {
        setShow("type");
      } else {
        showErrorToast({
          message:
            "Apologize, free users can only generate one event at a time.",
        });
      }
    } else {
      setShow("type");
    }
  };

  const handleBack = () => {
    {
      show === "type"
        ? setShow(null)
        : show === "category"
        ? setShow("type")
        : show === "location"
        ? confirmLocation
          ? setConfirmLocation(false)
          : setShow("category")
        : show === "event"
        ? next
          ? setNext(false)
          : setShow("location")
        : setShow("success");
    }
  };

  const WelcomeScreen = () => {
    return (
      <Row className={`${stylesHost.container} mb-5`}>
        <Col lg={8} md={8} sm={12} className={stylesHost.section}>
          <h2 className={stylesHost.title}>Do you want to Host an Event?</h2>
          <h2 className={stylesHost.subtitle}>
            To continue, click on the below button and select all the options to
            host an event.
          </h2>
          <Button
            variant="dark"
            className={stylesHost.button}
            onClick={handleType}
          >
            Let's Start
          </Button>
        </Col>
      </Row>
    );
  };

  const SelectType = () => {
    return (
      <Row className={stylesHost.cardSec}>
        {decrypt.subscription === null && coupon != "free" ? (
          <>
            <h2 className={stylesHost.cardMainTitle}>Select the event type.</h2>
            <Col lg={8} md={8} sm={8} className={stylesHost.card}>
              <div className={stylesHost.cardHeader}>
                <h2 className={stylesHost.cardTitle}>Free Event</h2>
                <CustomButton
                  title="Continue"
                  styles={stylesHost.cardBtn}
                  onPress={() => setShow("category")}
                />
              </div>
              <ul className={stylesHost.list}>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  One event creation limit.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Invite 100 audiace only.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Sell ticket minimum 10$.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  13% commission will apply on each ticket.
                </li>
              </ul>
            </Col>
            <Col lg={8} md={8} sm={12} className={stylesHost.card}>
              <div className={stylesHost.cardHeader}>
                <h2 className={stylesHost.cardTitle}>Premium Event</h2>
                <CustomButton
                  title="See Plans"
                  styles={stylesHost.cardBtn}
                  onPress={() => navigation("/subscriptions")}
                />
              </div>
              <ul className={stylesHost.list}>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Unlimited event creation.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Invite unlimited audiance.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Sell ticket minimum 5$ or free.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  5% commission will apply on paid tickets.
                </li>
              </ul>
            </Col>
          </>
        ) : (
          <>
            <h2 className={stylesHost.cardMainTitle}>
              Select the ticket type.
            </h2>
            <Col lg={8} md={8} sm={8} className={stylesHost.card}>
              <div className={stylesHost.cardHeader}>
                <h2 className={stylesHost.cardTitle}>Free Ticket</h2>
                <CustomButton
                  title="Continue"
                  styles={stylesHost.cardBtn}
                  onPress={() => {
                    setShow("category");
                    setFree(1);
                  }}
                />
              </div>
              <ul className={stylesHost.list}>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Unable to sell tickets.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Create event for promotion.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  No commission will apply.
                </li>
              </ul>
            </Col>
            <Col lg={8} md={8} sm={12} className={stylesHost.card}>
              <div className={stylesHost.cardHeader}>
                <h2 className={stylesHost.cardTitle}>Paid Ticket</h2>
                <CustomButton
                  title="See Plans"
                  styles={stylesHost.cardBtn}
                  onPress={() => {
                    setShow("category");
                    setFree(0);
                  }}
                />
              </div>
              <ul className={stylesHost.list}>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Sell ticket minimum 5$.
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  Invite Unlimeted Audiance
                </li>
                <li className={stylesHost.listText}>
                  {" "}
                  <img
                    src={Checked}
                    className={stylesHost.listIcon}
                    alt="check-img"
                  />
                  5% commission will apply on each ticket.
                </li>
              </ul>
            </Col>
          </>
        )}
      </Row>
    );
  };

  const SelectCategory = () => {
    return (
      <Row className={stylesHost.optCnt}>
        <Col lg={8} md={8} sm={12}>
          <h2 className={stylesHost.optTitle}>Select Category</h2>
          <div className={stylesHost.optSec}>
            {categories.map((item) => (
              <div className={stylesHost.gap}>
                <CategoryItem
                  id={item?._id}
                  Title={item?.name}
                  ImagePath={item?.image}
                  CheckId={selectedCategory}
                  OnPress={() => {
                    setSelectedCategory(item._id);
                  }}
                />
              </div>
            ))}
          </div>
        </Col>
        <Col lg={8} md={8} sm={12}>
          <h2 className={stylesHost.optTitle}>Select Gender</h2>
          <div className={stylesHost.optSec}>
            {GENDER_DATA.map((item) => (
              <div className={stylesHost.equalGap}>
                <GenderItem
                  id={item?.id}
                  title={item?.gender}
                  pressed={selectedGender}
                  onPress={() => {
                    setSelectedGender(item?.gender);
                  }}
                />
              </div>
            ))}
          </div>
        </Col>
        <Col lg={8} md={8} sm={12}>
          <h2 className={stylesHost.optTitle}>Select Age</h2>
          <div className={stylesHost.optSec}>
            {AGE_DATA.map((item) => (
              <div className={stylesHost.equalGap}>
                <AgeItem
                  id={item?.id}
                  title={item?.age}
                  pressed={selectedAge}
                  onPress={() => {
                    setSelectedAge(item?.age);
                  }}
                />
              </div>
            ))}
          </div>
        </Col>
        <Col lg={8} md={8} sm={12} className={stylesHost.optBtnSec}>
          <CustomButton
            title="Continue"
            onPress={() => setShow("location")}
            disabled={
              !selectedCategory || !selectedAge || !selectedGender
                ? true
                : false
            }
            styles={stylesHost.optBtn}
          />
        </Col>
      </Row>
    );
  };

  const EventCarousel = () => {
    return (
      <Carousel
        fade
        nextIcon={<KeyboardArrowRightRounded className={stylesHost.arrow} />}
        prevIcon={<KeyboardArrowLeftRounded className={stylesHost.arrow} />}
        indicators={false}
        interval={null}
        className={stylesHost.carouselCnt}
      >
        {images.map((item) => (
          <Carousel.Item className={stylesHost.carouselSec} key={item.id}>
            <Image
              className={stylesHost.carouselImg}
              src={item.image}
              alt={`Image ${item.id}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const LocationDetails = () => {
    return (
      <Row className="d-flex align-items-center justify-content-center w-100 ps-3 pe-3">
        <Col lg={8} md={8} sm={12} className={stylesHost.eventCard}>
          <h2 className={stylesHost.eventCardTitle}>
            {!confirmLocation ? "Google Location" : "Confirm Location"}
          </h2>
          {!confirmLocation ? (
            <>
              <GoogleLoation
                label="Location"
                value={selectedAddress}
                onChange={handlePlaceSelected}
              />
              <CustomButton
                title="Continue"
                styles={stylesHost.eventCardBtn}
                onPress={() => setConfirmLocation(true)}
              />
            </>
          ) : (
            <Formik
              initialValues={locationInitialValues}
              validationSchema={locationValidationSchema}
              onSubmit={handleConfirmLocation}
              className="d-flex align-items-center justify-content-center w-100"
            >
              <FormikForm className="d-flex align-items-center justify-content-center w-100">
                <Row className="d-flex align-items-start justify-content-center w-100">
                  <Col lg={6} md={12} sm={12}>
                    <div className="w-100">
                      <TextInput
                        target="address"
                        type="text"
                        label="Address"
                        placeholder="Address"
                      />
                      <TextInput
                        target="city"
                        type="text"
                        label="City"
                        placeholder="City"
                      />
                      <TextInput
                        target="state"
                        type="text"
                        label="State"
                        placeholder="State"
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={12} sm={12}>
                    <div className="w-100">
                      <TextInput
                        target="country"
                        type="text"
                        label="Country"
                        placeholder="Country"
                        disabled={true}
                      />
                      <TextInput
                        target="postal_code"
                        type="text"
                        label="Postal Code"
                        placeholder="Postal Code"
                      />
                    </div>
                  </Col>
                  <CustomButton
                    type="submit"
                    title="Continue"
                    styles={stylesHost.eventCardBtn}
                  />
                </Row>
              </FormikForm>
            </Formik>
          )}
        </Col>
      </Row>
    );
  };

  const EventDetails = () => {
    return (
      <Formik
        initialValues={
          decrypt.subscription || coupon === "free"
            ? initialValues
            : freeInitialValues
        }
        validationSchema={
          decrypt.subscription || coupon === "free"
            ? validationSchema
            : freeValidationSchema
        }
        onSubmit={handleSubmit}
        className="w-100"
      >
        <FormikForm className="d-flex align-items-center justify-content-center w-100">
          <Row className="d-flex align-items-center justify-content-center w-100">
            <Col lg={8} md={8} sm={12} className={stylesHost.eventCard}>
              <Row className="d-flex align-items-start justify-content-center w-100">
                {!next ? (
                  <>
                    <Col lg={6} md={12} sm={12}>
                      <div className="w-100">
                        <TextInput
                          target="start_date"
                          label="Starting Date"
                          type="date"
                        />
                        <TextInput
                          target="start_time"
                          label="Sarting Time"
                          type="time"
                        />
                        <TextInput
                          target="end_date"
                          label="Ending Date"
                          type="date"
                        />
                        <TextInput
                          target="end_time"
                          label="Ending Time"
                          type="time"
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <div className="w-100">
                        <TextInput target="title" label="Title" type="text" />
                        <TextInput
                          target="organization"
                          label="Organization Name"
                          type="text"
                          placeholder="Organization Name"
                        />
                        <TextInput
                          target="total_guests"
                          label="Audiance Count"
                          type="number"
                          placeholder="Audiance Count"
                        />
                        <TextInput
                          target="ticket"
                          label="Ticket Price"
                          type="number"
                          disabled={free === 1 ? true : false}
                          placeholder="Ticket Price"
                        />
                      </div>
                    </Col>
                    <MessageInput
                      target="description"
                      type="textarea"
                      label="Description"
                      placeholder="Event Description"
                    />
                    <CustomButton
                      type="submit"
                      title="Continue"
                      styles={stylesHost.eventBtn}
                    />
                  </>
                ) : (
                  <>
                    <Col lg={12} md={12} sm={12}>
                      {images.length === 0 ? (
                        <div className={stylesHost.imageSec}>
                          <FileUploadRounded sx={{ fontSize: 38 }} />
                          <span className={stylesHost.imageSecTitle}>
                            Click below to upload pictures.
                          </span>
                        </div>
                      ) : (
                        <EventCarousel />
                      )}
                      <input
                        type="file"
                        onChange={handelInputChange}
                        ref={inputRef}
                        style={{ display: "none" }}
                        multiple={true}
                      />
                    </Col>
                    <Row className={stylesHost.btnCnt}>
                      <Col lg={6} md={12} sm={12}>
                        <CustomButton
                          title="Upload Images"
                          styles={stylesHost.eventBtn}
                          onPress={handelInputClick}
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <CustomButton
                          title="Host Event"
                          styles={stylesHost.eventBtn}
                          onPress={handleUpload}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </FormikForm>
      </Formik>
    );
  };

  const FinishScreen = () => {
    return (
      <Row className={stylesHost.container}>
        {uploading ? (
          <Spinner animation="border" variant="dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Col lg={8} md={8} sm={12} className={stylesHost.section}>
            <h2 className={stylesHost.title}>Thank You</h2>
            <h2 className={stylesHost.subtitle}>
              The event has been created and has been added to your event list.
            </h2>
            <Button
              variant="dark"
              className={stylesHost.button}
              onClick={() => navigation("/my/events")}
            >
              Let's Finish
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <>
      {show === "success" ? (
        <FinishScreen />
      ) : show === "event" ? (
        <EventDetails />
      ) : show === "location" ? (
        <LocationDetails />
      ) : show === "category" ? (
        <SelectCategory />
      ) : show === "type" ? (
        <SelectType />
      ) : (
        <WelcomeScreen />
      )}
      <Button
        variant="dark"
        className={
          show === null || show === "success" ? "d-none" : stylesHost.backBtn
        }
        onClick={handleBack}
      >
        <ChevronLeftRounded
          fontSize={"large"}
          className={stylesHost.backIcon}
        />
      </Button>
    </>
  );
};

export default HostEvent;
