import React from "react";
import { stylesGender } from "../../css";
import { Button } from "react-bootstrap";
import {
  MaleRounded,
  FemaleRounded,
  TransgenderRounded,
} from "@mui/icons-material";

const GenderItem = ({ id, title, pressed, onPress, styles }) => {
  return (
    <Button
      key={id}
      variant="dark"
      onClick={onPress}
      className={
        pressed === title
          ? `${stylesGender.activeBtn} ${styles}`
          : `${stylesGender.btn} ${styles}`
      }
    >
      <h2
        className={
          pressed === title
            ? `${stylesGender.activeLabel} ${styles}`
            : `${stylesGender.label} ${styles}`
        }
      >
        {title}
      </h2>
    </Button>
  );
};

export default GenderItem;
