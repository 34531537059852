import React, { useState, useEffect } from "react";
import { stylesReservation } from "../../css";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeForm } from "../../components";
import { decryptId } from "../../layouts/encryption/cryption";
import { URL } from "../../utils/ApisLink";
import { showErrorToast } from "../../components/tost/tost";

const Reservation = () => {
  const [price, setPrice] = useState();
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get("event_id");
  const event_id = decryptId(searchId);
  const navigation = useNavigate();
  const stripePromise = loadStripe(
    "pk_live_51OeHtZIh9h2LOO2KgWo958jZeDHR0qwjHuJnp50bEzNdRnlkWJ0EhxrmonvFYlELvg5OGECK8KaCERDB06xXwOAy00lQRdb1QO"
  );

  useEffect(() => {
    fetchEvents(event_id);
  }, []);

  const fetchEvents = async (value) => {
    try {
      await fetch(URL + "/get-events").then((result) => {
        result.json().then((resp) => {
          const data = resp.data;
          data.map((event) => {
            if (event._id === value) {
              if (event.ticket_price == 0) {
                setPrice(event.ticket_price);
              } else {
                if (event.user_id.has_coupon && event.user_id.coupon_type) {
                  setPrice(
                    event.is_membership
                      ? (event.ticket_price += event.ticket_price * 0.05)
                      : (event.ticket_price += event.ticket_price * 0.13)
                  );
                } else if (
                  event.user_id.has_coupon &&
                  !event.user_id.coupon_type
                ) {
                  setPrice(
                    event.is_membership
                      ? (event.ticket_price += event.ticket_price * 0.05)
                      : (event.ticket_price += event.ticket_price * 0.05)
                  );
                }
              }
            }
          });
        });
      });
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  return (
    <Row className={stylesReservation.row}>
      <Col lg={4} md={6} sm={12} className={stylesReservation.col}>
        <Elements stripe={stripePromise} className="w-100">
          <StripeForm
            heading="Reservation"
            type="reservation"
            eventId={event_id}
            ticketPrice={price}
            onCancel={() => navigation(-1)}
          />
        </Elements>
      </Col>
    </Row>
  );
};

export default Reservation;
