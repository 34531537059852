import React from "react";
import { stylesAbout } from "../../css";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { CustomButton } from "../../components";
import { decryptData } from "../../layouts/encryption/cryption";

const AboutUs = () => {
  const navigation = useNavigate();
  const decrypt = decryptData();

  return (
    <Row className="d-flex align-items-center justify-content-center w-100 mb-5 px-3">
      <Col
        lg={8}
        md={10}
        sm={12}
        className="d-flex flex-column align-items-start justify-content-center"
      >
        <div className={stylesAbout.header}>
          <div className={stylesAbout.headerImage}>
          <h2 className={stylesAbout.mainHeading}>About Us</h2>
          <h2 className={stylesAbout.subHeading}>
            Get affordable event promotion through ProYourEv!
          </h2>
          </div>
        </div>
        <p className={stylesAbout.paragraph}>
          New York city is full of hidden gems that are easy to miss. But you
          might miss the best events or places to visit around you simply
          because they don’t have the budget to advertise. Many excellent
          family-owned restaurants and entertainment venues struggle to fill
          tables during special events. That makes those special events risky
          and expensive for these venues to host.
        </p>
        <p className={stylesAbout.paragraph}>
          ProYourEV aims to solve this problem for the city’s thriving
          independent restaurants and small businesses. Instead of spending a
          fortune to promote your event or relying on word-of-mouth to bring
          people in, you can post your event on our site. That way, you can get
          the word out about your next event and the fantastic food and cozy
          environment you offer without breaking the bank.
        </p>
        <h2 className={stylesAbout.heading}>Vision</h2>
        <p className={stylesAbout.paragraph}>
          We want ProYourEV to serve as the city’s central hub for businesses
          announcing special events. By giving small businesses in New York City
          a main platform to promote their events, we hope to make them more
          successful and profitable overall. That way, business owners, from
          small shops to the best restaurants around you, feel more confident in
          announcing the following people event that makes our city the greatest
          on earth.
        </p>
        <h2 className={stylesAbout.heading}>Mission</h2>
        <p className={stylesAbout.paragraph}>
          At ProYourEV, it’s our mission to keep your next event from falling
          through the cracks. We aim to offer a low-cost platform that saves you
          money on promotion while helping you move tickets. Anyone who is age
          21 or older can register as a host and will be able to upload pictures
          of their restaurant or business. Hosts can also put the address and
          days for deals for people to come.
        </p>
        <h2 className={stylesAbout.heading}>Values</h2>
        <p className={stylesAbout.paragraph}>
          Supporting New York City’s small, independent restaurants and
          businesses and all they must enrich our city is our central value. We
          want to help open this business up to a broader audience by assisting
          them in making their special events successful. We believe in placing
          the latest technologies at the disposal of businesses that can use
          them to create unique experiences!
        </p>
        <h2 className={stylesAbout.heading}>Objectives</h2>
        <p className={stylesAbout.paragraph}>
          At ProYourEv, we want every restaurant and small business in New York
          City to feel confident about putting on their special events. With our
          uncomplicated and simple-to-use platform, they’ll know they’ll always
          have a place to post their tickets. We want to take the risk out of
          hosting these events so businesses feel confident enough to throw
          more, making our city so much more wonderful and genuinely
          one-of-a-kind in the process.
        </p>
        {!decrypt ? (
          <CustomButton
            styles={stylesAbout.btn}
            title={"Signup"}
            onPress={() => navigation("/signup")}
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default AboutUs;
