import React from "react";
import { stylesInput } from "../../css";
import { Field, ErrorMessage } from "formik";

const TextInput = ({ target, type, label, placeholder, disabled }) => {
  return (
    <div className={stylesInput.section}>
      <label htmlFor={target} className={stylesInput.title}>
        {label}
      </label>
      <Field
        type={type}
        id={target}
        name={target}
        placeholder={placeholder}
        disabled={disabled}
        className={stylesInput.input}
      />
      <ErrorMessage
        name={target}
        component="div"
        className={stylesInput.errMsg}
      />
    </div>
  );
};

export default TextInput;
