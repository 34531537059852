import React, { useState } from "react";
import { stylesUserNavbar } from "../../css";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  CloseRounded,
  LocalActivityRounded,
  ArticleRounded,
  ExitToAppRounded,
  HomeRounded,
  SortRounded,
  Groups3Rounded,
  AttachEmailRounded,
  WebStoriesRounded,
  FaceRounded,
  CardGiftcardRounded,
  GppMaybeRounded,
  EventRounded,
} from "@mui/icons-material";
import { PremiumCard, PremiumCouponCard } from "..";
import { decryptData } from "../../layouts/encryption/cryption";
import Logo from "../../assets/images/logo.png";

const UserNavbar = () => {
  const [show, setShow] = useState(false);
  const navigation = useNavigate();
  const decrypt = decryptData().user.username;
  const userData = decryptData().user;

  const handleHide = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handlePremium = () => {
    navigation("/subscriptions");
    setShow(false);
  };

  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={stylesUserNavbar.navbar}
        >
          <Container fluid>
            <Image
              src={Logo}
              className={stylesUserNavbar.brandImage}
              onClick={() => navigation("/events")}
            />
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className={stylesUserNavbar.toggleButton}
              onClick={handleShow}
            >
              <SortRounded
                className={stylesUserNavbar.toggleIcon}
                sx={{ fontSize: 28 }}
              />
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              scroll={true}
              backdrop={true}
              show={show}
              onHide={handleHide}
              className={stylesUserNavbar.navbarMenu}
            >
              <Offcanvas.Header className={stylesUserNavbar.menuHead}>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className={stylesUserNavbar.userName}
                >
                  {decrypt}
                </Offcanvas.Title>
                <CloseRounded
                  sx={{ fontSize: 32 }}
                  className={stylesUserNavbar.closeBtn}
                  onClick={handleHide}
                />
              </Offcanvas.Header>
              <Offcanvas.Body
                className={`w-100 ${stylesUserNavbar.navScrollbar}`}
              >
                <Nav className="justify-content-center flex-grow-1 w-100">
                  {userData.has_coupon ? <PremiumCouponCard /> : null}
                  <PremiumCard onPress={handlePremium} />
                  <Nav.Link href="/events" className={stylesUserNavbar.nav}>
                    Home
                    <HomeRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/host-event" className={stylesUserNavbar.nav}>
                    Host Event
                    <EventRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/my-tickets" className={stylesUserNavbar.nav}>
                    My Tickets
                    <LocalActivityRounded
                      className={stylesUserNavbar.navIcon}
                    />
                  </Nav.Link>
                  <Nav.Link href="/my-events" className={stylesUserNavbar.nav}>
                    My Events
                    <WebStoriesRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/profile" className={stylesUserNavbar.nav}>
                    Manage Profile
                    <FaceRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link
                    href="/avail-coupon"
                    className={stylesUserNavbar.nav}
                  >
                    Coupon
                    <CardGiftcardRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <div className={stylesUserNavbar.divider}></div>
                  <Nav.Link href="/about-us" className={stylesUserNavbar.nav}>
                    About Us
                    <Groups3Rounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link href="/contact-us" className={stylesUserNavbar.nav}>
                    Contact Us
                    <AttachEmailRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link
                    href="/terms-condition"
                    className={stylesUserNavbar.nav}
                  >
                    Terms of Services
                    <ArticleRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <Nav.Link
                    href="/privacy-policy"
                    className={stylesUserNavbar.nav}
                  >
                    Privacy Policy
                    <GppMaybeRounded className={stylesUserNavbar.navIcon} />
                  </Nav.Link>
                  <div className={stylesUserNavbar.divider}></div>
                  <Nav.Link
                    href="/logout"
                    className={stylesUserNavbar.logoutBtn}
                  >
                    Logout
                    <ExitToAppRounded className={stylesUserNavbar.logoutIcon} />
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default UserNavbar;
