import React, { useState, useEffect } from "react";
import { stylesDetails } from "../../css";
import { useNavigate } from "react-router";
import { useSearchParams, useSubmit } from "react-router-dom";
import {
  Container,
  Image,
  Col,
  Row,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import copy from "copy-to-clipboard";
import { TicketItem, ImageModal, CarouselItem } from "../../components";
import { LinkRounded, BusinessRounded } from "@mui/icons-material";
import { showErrorToast, showSuccessToast } from "../../components/tost/tost";
import { URL } from "../../utils/ApisLink";
import {
  decryptId,
  convertTime,
  convertDate,
  decryptData,
} from "../../layouts/encryption/cryption";
import ProfileImg from "../../assets/images/profile.jpg";

const EventDeatils = () => {
  const [categoryDetail, setCategoryDetail] = useState();
  const [eventDetails, setEventDetails] = useState();
  const [showModal, setShowMOdal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [formatedST, setFormatedST] = useState();
  const [formatedET, setFormatedET] = useState();
  const [formatedSD, setFormatedSD] = useState();
  const [formatedED, setFormatedED] = useState();
  const [coupon, setCoupon] = useState();
  const [count, setCount] = useState(1);
  const [user_id, setUserId] = useState();
  const navigation = useNavigate();
  const decrypt = decryptData();
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get("event_id");
  const event_id = decryptId(searchId);

  useEffect(() => {
    if (!decrypt) {
      fetchEvents(event_id);
    } else {
      const userId = decrypt.user._id;
      setUserId(userId);
      fetchEvents(event_id);
    }
  }, []);

  const fetchEvents = async (value) => {
    try {
      await fetch(URL + "/get-events").then((result) => {
        result.json().then((resp) => {
          const data = resp.data;
          data.map((event) => {
            if (event._id === value) {
              console.log(event);
              setEventDetails(event);
              setImages(event.image);
              getValues(event);
              fetchCategories(event.category_id);
              if(event.user_id.has_coupon && event.user_id.coupon_type){
                setCoupon('discount')
              } else if(event.user_id.has_coupon && !event.user_id.coupon_type){
                setCoupon('free')
              }
            }
          });
        });
      });
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const fetchCategories = async (value) => {
    try {
      const response = await fetch(URL + "/admin/categories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category_id: value }),
      });
      if (response.ok) {
        const data = await response.json();
        setCategoryDetail(data.data);
        setLoading(true);
      } else {
        console.error("Error fetching categories:", response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleReservation = async (value) => {
  //   // debugger
  //   if (!decrypt) {
  //     navigation("/login");
  //   } else if (value === 0) {
  //     const ticketData = {
  //       event_id: event_id,
  //       user_id: user_id,
  //       quantity: count,
  //     };
  //     console.log(ticketData);
  //     try {
  //       const response = await fetch(URL + "/create-ticket", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${decrypt.token}`,
  //         },
  //         body: JSON.stringify(ticketData),
  //       });
  //       if (response.ok) {
  //         navigation("/confirmation");
  //       } else {
  //         const errorData = await response.json();
  //         showErrorToast({ message: errorData.message });
  //       }
  //     } catch (err) {
  //       showErrorToast({ message: "Error: " + err });
  //     }
  //   } else {
  //     navigation({
  //       pathname: "/reservation",
  //       search: `?event_id=${searchId}`,
  //     });
  //   }
  // };

  const handleReservation = () => {
    if (!decrypt) {
      navigation("/login");
    } else {
      navigation({
        pathname: "/reservation",
        search: `?event_id=${searchId}`,
      });
    }
  };

  const getValues = (value) => {
    const FST = convertTime(value.start_time);
    setFormatedST(FST);
    const FET = convertTime(value.end_time);
    setFormatedET(FET);
    const FSD = convertDate(value.start_date);
    setFormatedSD(FSD);
    const FED = convertDate(value.end_date);
    setFormatedED(FED);
  };

  const handleCopyURL = () => {
    const url = window.location.href;
    const copyURL = copy(url);
    if (copyURL) {
      showSuccessToast({ message: "URL copied successfully!" });
    }
  };

  const handleClose = (data) => {
    setShowMOdal(data);
  };

  const decCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incCount = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };

  return (
    <Container className={stylesDetails.container}>
      {loading ? (
        <>
          <Row className={stylesDetails.header}>
            <Col lg={4} md={8} sm={12}>
              <h2 className={stylesDetails.title}>{eventDetails.title}</h2>
            </Col>
            <Col lg={2} md={3} sm={12} className={stylesDetails.optionSec}>
              <Button
                variant="dark"
                className={stylesDetails.optionBtn}
                onClick={handleCopyURL}
              >
                <LinkRounded
                  className={stylesDetails.optionIcon}
                  sx={{ fontSize: 26 }}
                />
              </Button>
            </Col>
          </Row>
          <CarouselItem
            type="multiple"
            array={images}
            onPressPicture={() => setShowMOdal(true)}
          />
          <Row className={stylesDetails.mainBody}>
            <Col lg={!eventDetails.ticket_price === 0 ? 12 : 8} md={12} sm={12}>
              {/* <Col lg={8} md={12} sm={12}> */}
              <div className={stylesDetails.textSec}>
                <div className={stylesDetails.profileSec}>
                  <div className={stylesDetails.profileIcon}>
                    <BusinessRounded
                      className={stylesDetails.proIcon}
                      sx={{ fontSize: 28 }}
                    />
                  </div>
                  <div className={stylesDetails.profileTextSec}>
                    <h2 className={stylesDetails.heading}>
                      {eventDetails.organization}
                    </h2>
                    {/* <h2 className={stylesDetails.lightText}>
                      {eventDetails.user_id.email}
                    </h2> */}
                  </div>
                </div>
              </div>
              <div className={stylesDetails.textSec}>
                <h2 className={stylesDetails.heading}>Where's the location</h2>
                <div className={stylesDetails.outerSec}>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>Location:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {eventDetails.address}
                    </h2>
                  </div>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>City:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {eventDetails.city}
                    </h2>
                  </div>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>State:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {eventDetails.state}
                    </h2>
                  </div>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>Country:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {eventDetails.country}
                    </h2>
                  </div>
                </div>
              </div>
              <div className={stylesDetails.textSec}>
                <h2 className={stylesDetails.heading}>When you can join</h2>
                <div className={stylesDetails.outerSec}>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>Check In:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {formatedSD}, {formatedST}
                    </h2>
                  </div>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>Check Out:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {formatedED}, {formatedET}
                    </h2>
                  </div>
                </div>
              </div>
              <div className={stylesDetails.textSec}>
                <h2 className={stylesDetails.heading}>
                  Event type and limitations
                </h2>
                <div className={stylesDetails.outerSec}>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>Category:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {categoryDetail.name}
                    </h2>
                  </div>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>Gender:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {eventDetails.gender === "All"
                        ? "No limitation"
                        : "Only" + " " + eventDetails.gender}
                    </h2>
                  </div>
                  <div className={stylesDetails.innerSec}>
                    <h2 className={stylesDetails.boldText}>Age:</h2>
                    <h2 className={stylesDetails.lightText}>
                      {eventDetails.age === "All"
                        ? "No limitation"
                        : "Only" + " " + eventDetails.age + " " + "years"}
                    </h2>
                  </div>
                </div>
              </div>
              <div className={stylesDetails.divider}></div>
            </Col>
            {eventDetails.ticket_price === 0 ? null : (
              <Col lg={4} md={6} sm={12} className={stylesDetails.rightCol}>
                <div className={stylesDetails.ticketSec}>
                  <TicketItem
                    startingDate={formatedSD}
                    startingTime={formatedST}
                    endingDate={formatedED}
                    endingTime={formatedET}
                    guests={eventDetails.total_guests}
                    ticketFee={eventDetails.ticket_price}
                    membership={eventDetails.is_membership || coupon === 'free'? true : false}
                    onReserve={handleReservation}
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row className={stylesDetails.aboutRow}>
            <Col lg={8} md={12} sm={12} className={stylesDetails.aboutLeft}>
              <h2 className={stylesDetails.heading}>Event Description</h2>
              <p
                className={`${stylesDetails.lightText} ${stylesDetails.descriSec}`}
              >
                {eventDetails.description}
              </p>
            </Col>
            <Col lg={4} md={12} sm={12} className={stylesDetails.aboutRight}>
              <h2 className={stylesDetails.heading}>Event Stats</h2>
              <div
                className={`${stylesDetails.innerSec} ${stylesDetails.fullWidth}`}
              >
                <h2 className={stylesDetails.boldText}>Total Guests:</h2>
                <h2 className={stylesDetails.lightText}>
                  {eventDetails.total_guests}
                </h2>
              </div>
              <div
                className={`${stylesDetails.innerSec} ${stylesDetails.fullWidth}`}
              >
                <h2 className={stylesDetails.boldText}>Ticket Price:</h2>
                <h2 className={stylesDetails.lightText}>
                  {eventDetails.ticket_price}
                </h2>
              </div>
              <div
                className={`${stylesDetails.BlackSec} ${stylesDetails.fullWidth}`}
              >
                {eventDetails.ticket_price === 0 ? "Free Event" : "Paid Event"}
              </div>
            </Col>
          </Row>
          <Modal
            size="lg"
            show={showModal}
            onHide={() => {
              setShowMOdal(false);
            }}
            fullscreen={true}
            centered
            contentClassName={stylesDetails.modalBackdrop}
          >
            <ImageModal array={images} modalClose={handleClose} />
          </Modal>
        </>
      ) : (
        <Spinner animation="border" variant="dark" />
      )}
    </Container>
  );
};

export default EventDeatils;
