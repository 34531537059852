import React, { useState, useEffect } from "react";
import { stylesHome } from "../../css";
import { Container, Col, Row, Modal, Dropdown, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CloseRounded } from "@mui/icons-material";
import { URL } from "../../utils/ApisLink";
import {
  AGE_DATA,
  GENDER_DATA,
  STATES_DATA,
  CITIES_DATA,
} from "../../utils/data";
import { showErrorToast } from "../../components/tost/tost";
import {
  CategoryItem,
  GenderItem,
  AgeItem,
  EventItem,
  CustomButton,
} from "../../components";
import NotFoundImg from "../../assets/images/folder.png";
import { encryptId, decryptData } from "../../layouts/encryption/cryption";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [clear, setClear] = useState(true);
  const [search, setSearch] = useState(false);
  const [selectedGender, setSelectedGender] = useState();
  const [selectedAge, setSelectedAge] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [filterEvents, setFilterEvents] = useState([]);
  const navigation = useNavigate();
  const decrypt = decryptData();

  useEffect(() => {
    fetchEvents();
    fetchCategories();
  }, []);

  const fetchEvents = async () => {
    try {
      await fetch(URL + "/get-events").then((result) => {
        result.json().then((resp) => {
          const data = resp.data;
          if (data) {
            data.forEach((item) => {
              const id = encryptId(item._id);
              item._id = id;
            });
            setEvents(data);
            setFilterEvents(data);
            setLoading(false);
          }
        });
      });
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(URL + "/admin/categories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCategories(data.data);
      } else {
        const error = await response.json();
        showErrorToast({ message: error.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error:" + err });
    }
  };

  const handleClear = () => {
    if (!clear) {
      setSelectedState();
      setSelectedCity();
      setSelectedGender();
      setSelectedCategory();
      setClear(true);
      setSearch(false);
      setLoading(true);
      fetchEvents();
    }
  };

  const handleFilter = async () => {
    try {
      const response = await fetch(URL + "/search-events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          age: selectedAge,
          gender: selectedGender,
          state: selectedState,
          city: selectedCity,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setEvents(data.data);
        setFilterEvents(data.data);
        setSelectedCategory();
        setSearch(false);
      } else {
        const error = await response.json();
        showErrorToast({ message: error.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error:" + err });
    }
  };

  const handleSubmit = (value) => {
    if (!decrypt) {
      navigation({
        pathname: "/details",
        search: `?event_id=${value}`,
      });
    } else if (decrypt.user.user_type === "user") {
      navigation({
        pathname: "/event-details",
        search: `?event_id=${value}`,
      });
    }
  };

  const handleCategory = (value) => {
    const data = filterEvents.filter((event) => event.category_id === value);
    if (data) {
      setEvents(data);
      setSelectedCategory(value);
    }
  };

  const HiddenSearch = () => {
    return (
      <Container fluid className={stylesHome.searchSec}>
        <Container fluid className={stylesHome.header}>
          <CloseRounded
            htmlColor="#ffffff"
            onClick={() => setSearch(!search)}
          />
          <h2 className={stylesHome.headerTitle}>Search</h2>
          <div className={stylesHome.hiddenSec}></div>
        </Container>
        <Container fluid className={stylesHome.searchBody}>
          <div className={stylesHome.dropdownSec}>
            <Dropdown className={stylesHome.dropdown}>
              <Dropdown.Toggle
                className={stylesHome.dropdownBtn}
                id="dropdown-autoclose-true"
              >
                {selectedState ? selectedState : "Select State"}
              </Dropdown.Toggle>
              <Dropdown.Menu className={stylesHome.dropdownMenu}>
                {STATES_DATA.map((state, index) => (
                  <Dropdown.Item
                    className={stylesHome.dropdownItem}
                    key={index}
                    onClick={() => {
                      setSelectedState(state);
                      setClear(false);
                    }}
                  >
                    {state}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className={stylesHome.dropdown}>
              <Dropdown.Toggle
                className={stylesHome.dropdownBtn}
                id="dropdown-autoclose-true"
              >
                {selectedCity ? selectedCity : "Select City"}
              </Dropdown.Toggle>
              <Dropdown.Menu className={stylesHome.dropdownMenu}>
                {CITIES_DATA.map((city, index) => (
                  <Dropdown.Item
                    className={stylesHome.dropdownItem}
                    key={index}
                    onClick={() => {
                      setSelectedCity(city);
                      setClear(false);
                    }}
                  >
                    {city}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <h2 className={stylesHome.optionTitle}>Select Gender</h2>
          <div className={stylesHome.optionSec}>
            {GENDER_DATA.map((item) => (
              <GenderItem
                key={item?.id}
                title={item?.gender}
                pressed={selectedGender}
                onPress={() => {
                  setSelectedGender(item?.gender);
                  setClear(false);
                }}
              />
            ))}
          </div>
          <h2 className={stylesHome.optionTitle}>Select Age</h2>
          <div className={stylesHome.optionSec}>
            {AGE_DATA.map((item) => (
              <AgeItem
                key={item?.id}
                title={item?.age}
                pressed={selectedAge}
                onPress={() => {
                  setSelectedAge(item?.age);
                  setClear(false);
                }}
              />
            ))}
          </div>
          <div className={stylesHome.submitBtnSec}>
            <CustomButton
              disabled={clear}
              styles={stylesHome.margin}
              title="Clear"
              onPress={handleClear}
            />
            <CustomButton
              styles={stylesHome.margin}
              title="Search"
              onPress={handleFilter}
            />
          </div>
        </Container>
      </Container>
    );
  };

  return (
    <Container fluid id="goto-top" className={stylesHome.container}>
      {!loading ? (
        <>
          <Row className={`px-lg-3 ${stylesHome.tabBar}`}>
            <Col
              lg={12}
              md={12}
              sm={12}
              className={`justify-content-sm-start justify-content-md-start justify-content-lg-center ${stylesHome.categorySec}`}
            >
              {categories.map((category) => (
                <CategoryItem
                  key={category?._id}
                  id={category?._id}
                  ImagePath={category?.image}
                  Title={category?.name}
                  OnPress={() => {
                    handleCategory(category?._id);
                    setClear(false);
                  }}
                  CheckId={selectedCategory}
                />
              ))}
            </Col>
            <Col lg={3} md={4} sm={12} className={stylesHome.buttonSec}>
              <CustomButton
                title="Apply Filters"
                onPress={() => setSearch(true)}
                pressed={search}
                styles={stylesHome.searchBtn}
              />
            </Col>
          </Row>
          <Row className={stylesHome.eventSec}>
            {events.length === 0 ? (
              <div className={stylesHome.oopsCnt}>
                <img className={stylesHome.oopsImg} src={NotFoundImg} alt />
                <h2 className={stylesHome.oopsText}>No Hosted Events</h2>
              </div>
            ) : (
              events.map((item) => (
                <Col key={item?._id} xl={3} lg={4} md={6} sm={12} xs={12}>
                  <EventItem
                    id={item?._id}
                    media={item?.image}
                    city={item?.city}
                    state={item?.state}
                    location={item?.address}
                    startingDate={item?.start_date}
                    startingTime={item?.start_time}
                    price={item?.ticket_price}
                    onPress={() => handleSubmit(item?._id)}
                  />
                </Col>
              ))
            )}
          </Row>
          <Modal
            size="lg"
            show={search}
            onHide={() => {
              setSearch(false);
            }}
            centered
          >
            <HiddenSearch />
          </Modal>
        </>
      ) : (
        <Spinner animation="border" variant="dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </Container>
  );
};

export default Home;
