import React, { useState, useEffect } from "react";
import { stylesMembership } from "../../css";
import { Container, Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { StripeForm } from "../../components";
import { showErrorToast } from "../../components/tost/tost";
import { loadStripe } from "@stripe/stripe-js";
import { URL } from "../../utils/ApisLink";
import { decryptData } from "../../layouts/encryption/cryption";
import Checked from "../../assets/images/checked.png";

const Membership = () => {
  const [select, setSelected] = useState();
  const [selectId, setSelectedId] = useState();
  const [coupon, setCoupon] = useState();
  const [show, setShow] = useState(false);
  const [plans, setPlans] = useState([]);
  const [price, setPrice] = useState();
  const decrypt = decryptData();
  const userData = decrypt.user;
  const stripePromise = loadStripe(
    "pk_live_51OeHtZIh9h2LOO2KgWo958jZeDHR0qwjHuJnp50bEzNdRnlkWJ0EhxrmonvFYlELvg5OGECK8KaCERDB06xXwOAy00lQRdb1QO"
  );

  useEffect(() => {
    console.log(userData);
    if (userData.has_coupon && userData.coupon_type) {
      setCoupon("discount");
      fetchPlans();
    } else if (userData.has_coupon && !userData.coupon_type) {
      setCoupon("free");
      fetchPlans();
    } else {
      setCoupon("none");
      fetchPlans();
    }
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await fetch(URL + "/get-plans", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${decrypt.token}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setPlans(result.data);
      } else {
        const error = await response.json();
        showErrorToast({ message: error.message });
      }
    } catch (err) {
      showErrorToast({ message: "Error: " + err });
    }
  };

  const handleSubmit = (values) => {
    setSelected(values);
    setSelectedId(values);
    setShow(true);
  };

  return (
    <Container fluid className={stylesMembership.container}>
      {show ? (
        <>
          <Row className="w-100 d-flex align-items-center justify-content-center">
            <Col
              lg={4}
              md={6}
              sm={12}
              className="d-flex align-items-center justify-content-center px-4"
            >
              <Elements stripe={stripePromise}>
                <StripeForm
                  heading="Card details"
                  type="subscription"
                  planId={selectId}
                  planPrice={price}
                  onCancel={() => setShow(false)}
                />
              </Elements>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <h2 className={stylesMembership.title}>
            Select the best plan to become a host
          </h2>
          <h2 className={stylesMembership.subtitle}>
            This is for the user who wants to host the events with more
            features.
          </h2>
          {coupon === "discount" ? (
            <h2 className={stylesMembership.discountHeading}>
              Congratulations you avail a 20% discount coupon.
            </h2>
          ) : coupon === "free" ? (
            <h2 className={stylesMembership.discountHeading}>
              Congratulations you avail a free membership coupon.
            </h2>
          ) : null}
          <Row className={stylesMembership.row}>
            {/* <Col lg={3} md={6} sm={12}>
            <div
              className={
                select === "free"
                  ? stylesMembership.activeCard
                  : stylesMembership.card
              }
              onClick={handleFree}
            >
              <h2 className={stylesMembership.price}>0$</h2>
              <h2 className={stylesMembership.cardTitle}>Free</h2>
              <ul className={stylesMembership.list}>
                <li className={stylesMembership.listText}>
                  <img src={Checked} className={stylesMembership.listIcon} alt="check-img"/>
                  Unlimited Event Creation
                </li>
                <li className={stylesMembership.listText}>
                  <img src={Checked} className={stylesMembership.listIcon} alt="check-img"/>
                  Can't Invite Audiance
                </li>
                <li className={stylesMembership.listText}>
                  <img src={Checked} className={stylesMembership.listIcon} alt="check-img"/>
                  Can't Generate Ticket
                </li>
                <li className={stylesMembership.listText}>
                  <img src={Checked} className={stylesMembership.listIcon} alt="check-img"/>
                  Limited Tools Access
                </li>
              </ul>
            </div>
          </Col> */}
            {plans.map((plan) => (
              <Col key={plan._id} lg={3} md={6} sm={12}>
                <div
                  className={
                    select === plan._id
                      ? stylesMembership.activeCard
                      : stylesMembership.card
                  }
                  onClick={() => {
                    if (coupon === "discount") {
                      handleSubmit(plan._id);
                      setPrice(plan.amount * (1 - 0.2));
                    } else {
                      handleSubmit(plan._id);
                      setPrice(plan.amount);
                    }
                  }}
                >
                  {coupon === "discount" ? (
                    <>
                      <h2 className={stylesMembership.price}>
                        {plan.amount * (1 - 0.2) + "$"}
                      </h2>
                      <h2 className={stylesMembership.discountedPrice}>
                        {plan.amount + "$"}
                      </h2>
                    </>
                  ) : (
                    <h2 className={stylesMembership.price}>
                      {plan.amount + "$"}
                    </h2>
                  )}
                  <h2 className={stylesMembership.cardTitle}>{plan.name}</h2>
                  <ul className={stylesMembership.list}>
                    <li className={stylesMembership.listText}>
                      <img
                        src={Checked}
                        className={stylesMembership.listIcon}
                        alt="check-img"
                      />
                      Unlimited Event Creation
                    </li>
                    <li className={stylesMembership.listText}>
                      <img
                        src={Checked}
                        className={stylesMembership.listIcon}
                        alt="check-img"
                      />
                      Unlimited Audiance Invitation
                    </li>
                    <li className={stylesMembership.listText}>
                      <img
                        src={Checked}
                        className={stylesMembership.listIcon}
                        alt="check-img"
                      />
                      Free & 5$ Minimum Ticket Price
                    </li>
                    <li className={stylesMembership.listText}>
                      <img
                        src={Checked}
                        className={stylesMembership.listIcon}
                        alt="check-img"
                      />
                      All Tools Access
                    </li>
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  );
};

export default Membership;
