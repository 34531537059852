import React from "react";
import { stylesInput } from "../../css";
import Autocomplete from "react-google-autocomplete";

const GoogleLoation = ({ value, onChange, label }) => {
  return (
    <div className={stylesInput.section}>
      <label className={stylesInput.title}>{label}</label>
      <Autocomplete
        className={stylesInput.input}
        apiKey={"AIzaSyB2vv10Lr_6M5RiAfWVDNMNnWUzU-IcFX4"}
        onPlaceSelected={onChange}
        typeof={["geocode"]}
        options={{
          types: ["geocode"],
          libraries: ["places"],
          componentRestrictions: { country: "usa" },
        }}
        placeholder="Search Google Location"
        defaultValue={value}
      />
    </div>
  );
};

export default GoogleLoation;
